import { SET_CLEAR_CONSOLE_CALLBACK, SET_PRINT_TO_CONSOLE_CALLBACK } from "./types";
export var setClearConsoleCallback = function setClearConsoleCallback(callback) {
    return {
        type: SET_CLEAR_CONSOLE_CALLBACK,
        callback: callback
    };
};
export var setPrintToConsoleCallback = function setPrintToConsoleCallback(callback) {
    return {
        type: SET_PRINT_TO_CONSOLE_CALLBACK,
        callback: callback
    };
};
