import React from "react";
import Moment from "react-moment";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { selectActiveProject } from "@comp/projects/selectors";
import { selectProjectLastModified } from "@comp/project-last-modified/selectors";
import { selectUserProfile, selectUserImageURL, selectProfileProjectsCount } from "@comp/profile/selectors";
import ProjectAvatar from "@elem/project-avatar";
import { AccountBox } from "@mui/icons-material";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import * as SS from "./styles";
import { isEmpty, prop, propOr } from "ramda";
import { jsx as ___EmotionJSX } from "@emotion/react";
var ProjectProfileMeta = function ProjectProfileMeta() {
    var project = useSelector(selectActiveProject);
    var projectName = propOr("unnamed", "name", project || {});
    var projectDescription = propOr("", "description", project || {});
    var projectLastModified = useSelector(selectProjectLastModified(prop("projectUid", project || {})));
    var projectLastModifiedDate = typeof (projectLastModified === null || projectLastModified === void 0 ? void 0 : projectLastModified.timestamp) === "number" ? new Date(projectLastModified === null || projectLastModified === void 0 ? void 0 : projectLastModified.timestamp) : undefined;
    var projectOwnerUid = propOr("", "userUid", project || {});
    var profile = useSelector(selectUserProfile(projectOwnerUid));
    var profileUserName = propOr("", "username", profile || {});
    var profileDisplayName = propOr("", "displayName", profile || {});
    var profileImage = useSelector(selectUserImageURL(projectOwnerUid));
    var profileProjectsCount = useSelector(selectProfileProjectsCount(projectOwnerUid));
    var authorTooltip = ___EmotionJSX("div", {
        css: SS.projectProfileTooltipContainer
    }, ___EmotionJSX("div", {
        css: SS.projectProfileImgContainer
    }, isEmpty(profileImage) ? ___EmotionJSX(AccountBox, null) : ___EmotionJSX(Avatar, {
        src: profileImage
    })), ___EmotionJSX("div", {
        css: SS.projectProfileTooltip
    }, ___EmotionJSX("h2", null, profileDisplayName), ___EmotionJSX("p", null, "".concat(profileProjectsCount.public || 0, " projects"))));
    return project ? ___EmotionJSX("div", {
        css: SS.projectProfileMetaContainer
    }, ___EmotionJSX("div", {
        css: SS.projectProfileMetaTextContainer
    }, ___EmotionJSX(Tooltip, {
        placement: "right",
        title: ___EmotionJSX("div", null, ___EmotionJSX("div", {
            css: SS.projectProfileTooltipTitleContainer
        }, ___EmotionJSX("div", {
            css: SS.projectIcon
        }, ___EmotionJSX(ProjectAvatar, {
            project: project
        })), ___EmotionJSX("div", {
            style: {
                marginLeft: 24
            }
        }, ___EmotionJSX("h1", {
            css: SS.projectProfileMetaH1
        }, projectName), ___EmotionJSX("p", {
            css: SS.projectProfileDescription
        }, projectDescription), ___EmotionJSX("div", null, projectLastModifiedDate && ___EmotionJSX("em", {
            css: SS.projectProfileDescription
        }, "Modified", ___EmotionJSX(Moment, {
            style: {
                marginLeft: 3
            },
            date: projectLastModifiedDate,
            fromNow: true
        }))))))
    }, ___EmotionJSX("h1", {
        css: SS.projectProfileMetaH1
    }, projectName)), ___EmotionJSX("p", {
        css: SS.projectProfileMetaP
    }, ___EmotionJSX("span", {
        css: SS.projectProfileBySpan
    }, "By "), ___EmotionJSX(Tooltip, {
        title: authorTooltip,
        placement: "right"
    }, ___EmotionJSX(Link, {
        to: "/profile/".concat(profileUserName),
        css: SS.projectProfileLink
    }, profileDisplayName))))) : ___EmotionJSX(React.Fragment, null);
};
export default ProjectProfileMeta;
