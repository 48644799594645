import { assoc, concat, isEmpty, mergeAll, pipe, when } from "ramda";
import { ADD_USER_PROFILES, SEARCH_PROJECTS_REQUEST, SEARCH_PROJECTS_SUCCESS, ADD_POPULAR_PROJECTS, ADD_RANDOM_PROJECTS, SET_POPULAR_PROJECTS_OFFSET, SET_RANDOM_PROJECTS_LOADING } from "./types";
var INITIAL_STATE = {
    popularProjects: [],
    popularProjectsOffset: -1,
    popularProjectsTotalRecords: -1,
    profiles: {},
    searchProjectsRequest: false,
    searchResult: [],
    searchResultTotalRecords: -1,
    searchPaginationOffset: -1,
    searchQuery: "",
    randomProjects: [],
    randomProjectsLoading: true
};
var HomeReducer = function HomeReducer() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
    var action = arguments.length > 1 ? arguments[1] : undefined;
    switch (action.type) {
        case SEARCH_PROJECTS_REQUEST:
            {
                return pipe(assoc("searchProjectsRequest", !isEmpty(action.query)), assoc("searchQuery", action.query), assoc("searchPaginationOffset", isEmpty(action.query) ? -1 : action.offset), when(function () {
                    return isEmpty(action.query);
                }, assoc("searchResultTotalRecords", -1)))(state);
            }
        case SEARCH_PROJECTS_SUCCESS:
            {
                return pipe(assoc("searchResult", action.result || []), assoc("searchProjectsRequest", false), assoc("searchResultTotalRecords", action.totalRecords))(state);
            }
        case ADD_USER_PROFILES:
            {
                return assoc("profiles", mergeAll([action.payload, state.profiles]), state);
            }
        case ADD_POPULAR_PROJECTS:
            {
                return pipe(assoc("popularProjects", concat(state.popularProjects, action.payload)), assoc("popularProjectsTotalRecords", action.totalRecords))(state);
            }
        case SET_POPULAR_PROJECTS_OFFSET:
            {
                return assoc("popularProjectsOffset", action.newOffset, state);
            }
        case ADD_RANDOM_PROJECTS:
            {
                return assoc("randomProjects", action.payload || [])(state);
            }
        case SET_RANDOM_PROJECTS_LOADING:
            {
                return assoc("randomProjectsLoading", action.isLoading)(state);
            }
        default:
            {
                return state;
            }
    }
};
export default HomeReducer;
