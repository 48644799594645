import { notEmpty } from "@root/utils";
import { always, cond, equals, match, T } from "ramda";
export var selectCurrentRoute = function selectCurrentRoute(_ref) {
    var router = _ref.router;
    return cond([[equals("/"), always("home")], [function (x) {
                return notEmpty(match(/^\/editor\/+/g, x));
            }, always("editor")], [function (x) {
                return notEmpty(match(/^\/profile/g, x));
            }, always("profile")], [T, always("404")]])(router.location.pathname);
};
export var selectCurrentProfileRoute = function selectCurrentProfileRoute(_ref2) {
    var router = _ref2.router;
    if (notEmpty(match(/^\/profile\//g, router.location.pathname))) {
        var woPrefix = router.location.pathname.replace(/^\/profile\//g, "");
        var woPostfix = woPrefix.replace(/\/.*/g, "");
        if (notEmpty(match(/^\/profile\/.*\/.*/g, router.location.pathname))) {
            var nestedPath = woPrefix.split("/");
            return [woPostfix, nestedPath[1]];
        }
        else {
            return [woPostfix, undefined];
        }
    }
    else {
        return [undefined, undefined];
    }
};
