import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "@root/store";
import { selectIsOwner } from "./selectors";
import { DnDProvider } from "@comp/file-tree/context";
import { Tabs, DragTabList, DragTab, PanelList, Panel } from "@root/tabtab/index.js";
import { arrayMoveImmutable as simpleSwitch } from "array-move";
import { subscribeToProjectLastModified } from "@comp/project-last-modified/subscribers";
import { subscribeToProfile, subscribeToProjectsCount } from "@comp/profile/subscribers";
import tabStyles, { tabListStyle } from "./tab-styles";
import { isAudioFile } from "../projects/utils";
import { Beforeunload } from "react-beforeunload";
import SplitPane_ from "react-split-pane";
import Editor from "../editor/editor";
import { AudioEditor } from "../audio-editor/audio-editor";
import { subscribeToProjectChanges } from "@comp/projects/subscribers";
import CsoundManualWindow from "./csound-manual";
import FileTree from "../file-tree";
import { storeEditorKeyboardCallbacks, storeProjectEditorKeyboardCallbacks } from "@comp/hot-keys/actions";
import { append, reduce, pathOr, propOr } from "ramda";
import { find, isEmpty } from "lodash";
import { rearrangeTabs, tabClose, tabSwitch, setManualPanelOpen } from "./actions";
import { mapIndexed, isMobile } from "@root/utils";
import * as SS from "./styles";
import BottomTabs from "@comp/bottom-tabs/component";
import MobileTabs from "@comp/bottom-tabs/mobile-tabs";
import { selectBottomTabIndex, selectOpenBottomTabs } from "../bottom-tabs/selectors";
import { jsx as ___EmotionJSX } from "@emotion/react";
var TabStyles = tabStyles(false);
var SplitPane = SplitPane_;
var MySplit = function MySplit(_ref) {
    var primary = _ref.primary, split = _ref.split, minSize = _ref.minSize, maxSize = _ref.maxSize, defaultSize = _ref.defaultSize, onDragStarted = _ref.onDragStarted, onDragFinished = _ref.onDragFinished, children = _ref.children;
    /* eslint-disable-next-line  unicorn/prefer-native-coercion-functions */
    var filteredChildren = children.filter(Boolean);
    return filteredChildren.length === 1 ? filteredChildren[0] : ___EmotionJSX(SplitPane, {
        primary: primary,
        split: split,
        minSize: minSize,
        maxSize: maxSize,
        defaultSize: defaultSize,
        onDragStarted: onDragStarted,
        onDragFinished: onDragFinished
    }, filteredChildren);
};
export function EditorForDocument(_ref2) {
    var uid = _ref2.uid, projectUid = _ref2.projectUid, doc = _ref2.doc;
    if (doc.type === "txt") {
        return ___EmotionJSX(Editor, {
            documentUid: doc.documentUid,
            projectUid: projectUid
        });
    }
    else if (doc.type === "bin" && isAudioFile(doc.filename)) {
        var path = "".concat(uid, "/").concat(projectUid, "/").concat(doc.documentUid);
        return ___EmotionJSX(AudioEditor, {
            audioFileUrl: path
        });
    }
    else if (doc.isNonCloudDocument && doc.nonCloudFileAudioUrl) {
        return ___EmotionJSX(AudioEditor, {
            audioFileUrl: doc.nonCloudFileAudioUrl
        });
    }
    return ___EmotionJSX("div", null, ___EmotionJSX("p", null, "Unknown document type"));
}
var MainSection = function MainSection(_ref3) {
    var tabDock = _ref3.tabDock, setIsDragging = _ref3.setIsDragging;
    var openTabs = useSelector(function (store) {
        return selectOpenBottomTabs(store);
    });
    var bottomTabIndex = useSelector(function (store) {
        return selectBottomTabIndex(store);
    });
    var showBottomTabs = !isEmpty(openTabs) && bottomTabIndex > -1;
    return ___EmotionJSX("div", null, ___EmotionJSX(SplitPane, {
        split: "horizontal",
        primary: "first",
        minSize: showBottomTabs ? "25%" : "100%",
        defaultSize: "75%",
        className: "main-tab-panels",
        onDragStarted: function onDragStarted() {
            return setIsDragging && setIsDragging(true);
        },
        onDragFinished: function onDragFinished() {
            return setIsDragging && setIsDragging(false);
        }
    }, [tabDock, ___EmotionJSX(BottomTabs, {
            key: "2"
        })]));
};
MainSection.displayName = "MainSection";
var ProjectEditor = function ProjectEditor(_ref4) {
    var activeProject = _ref4.activeProject;
    var dispatch = useDispatch();
    // The manual is an iframe, which doesn't detect
    // mouse positions, so we add an invidible layer then
    // resizing the manual panel.
    var _useState = useState(false), _useState2 = _slicedToArray(_useState, 2), isDragging = _useState2[0], setIsDragging = _useState2[1];
    var projectUid = propOr("", "projectUid", activeProject);
    var projectOwnerUid = propOr("", "userUid", activeProject);
    var projectName = propOr("Undefined Project", "name", activeProject);
    var isOwner = useSelector(selectIsOwner(projectUid));
    // eslint-disable-next-line unicorn/no-useless-undefined
    var csound = undefined;
    useEffect(function () {
        if (document.title !== projectName) {
            document.title = projectName;
        }
    }, [projectName]);
    useEffect(function () {
        // start at top on init
        // in this case, prevent
        // jumpy behavior
        window.scrollTo(0, 0);
        var rootElement = document.querySelector("#root");
        rootElement && rootElement.scrollTo(0, 0);
    }, []);
    useEffect(function () {
        var unsubscribeProjectChanges = subscribeToProjectChanges(projectUid, dispatch, csound);
        var unsubscribeToProjectLastModified = subscribeToProjectLastModified(projectUid);
        // get some metadata from other people's projects
        var unsubscribeToProfile = !isOwner && subscribeToProfile(projectOwnerUid, dispatch);
        var unsubscribeToProjectsCount = !isOwner && subscribeToProjectsCount(projectOwnerUid, dispatch);
        return function () {
            unsubscribeProjectChanges();
            unsubscribeToProjectLastModified.then(function (unsub) {
                return unsub();
            });
            unsubscribeToProfile && unsubscribeToProfile();
            unsubscribeToProjectsCount && unsubscribeToProjectsCount();
        };
    }, [dispatch, isOwner, projectOwnerUid, projectUid, csound]);
    var tabDockDocuments = useSelector(pathOr([], ["ProjectEditorReducer", "tabDock", "openDocuments"]));
    var tabIndex = useSelector(pathOr(-1, ["ProjectEditorReducer", "tabDock", "tabIndex"]));
    var openDocuments = reduce(function (accumulator, tabDocument) {
        var maybeDocument = pathOr({}, ["documents", propOr("", "uid", tabDocument)], activeProject);
        var isNonCloudFile = tabDocument.isNonCloudDocument || false;
        return isNonCloudFile ? append(tabDocument, accumulator) : maybeDocument && Object.keys(maybeDocument).length > 0 ? append(maybeDocument, accumulator) : accumulator;
    }, [], tabDockDocuments);
    var closeTab = function closeTab(documentUid, isModified) {
        dispatch(tabClose(projectUid, documentUid, isModified));
    };
    var openTabList = mapIndexed(function (document, index) {
        var isModified = document.isModifiedLocally;
        return ___EmotionJSX(DragTab, {
            closable: true,
            key: index,
            closeCallback: function closeCallback() {
                return closeTab(document.documentUid || document.uid, isModified);
            },
            currentIndex: tabIndex,
            thisIndex: index
        }, ___EmotionJSX("p", {
            style: {
                margin: 0
            }
        }, document ? (document.filename || document.name || document.uid) + (isOwner && isModified ? "*" : "") : ""));
    }, openDocuments);
    var openTabPanels = mapIndexed(function (document_, index) {
        return ___EmotionJSX(Panel, {
            key: index
        }, ___EmotionJSX(EditorForDocument, {
            uid: activeProject.userUid,
            projectUid: projectUid,
            isOwner: isOwner,
            doc: document_
        }));
    }, openDocuments);
    var switchTab = function switchTab(index) {
        localStorage.setItem(projectUid + ":tabIndex", "".concat(index));
        dispatch(tabSwitch(index));
    };
    var someUnsavedData = isOwner ? !!find(openDocuments, function (document_) {
        return document_.isModifiedLocally === true;
    }) : false;
    var unsavedDataExitText = "You still have unsaved changes, are you sure you want to quit?";
    var unsavedDataExitPrompt = someUnsavedData && ___EmotionJSX(React.Fragment, null, ___EmotionJSX(Beforeunload, {
        onBeforeunload: function onBeforeunload() {
            return unsavedDataExitText;
        }
    }));
    var tabDock = isEmpty(openDocuments) ? ___EmotionJSX("div", {
        key: "0",
        style: {
            position: "relative"
        }
    }) : ___EmotionJSX("div", {
        key: "1",
        css: tabListStyle
    }, ___EmotionJSX(Tabs, {
        defaultIndex: Math.min(tabIndex, tabDockDocuments.length - 1),
        activeIndex: Math.min(tabIndex, tabDockDocuments.length - 1),
        onTabChange: switchTab,
        customStyle: TabStyles,
        showModalButton: false,
        showArrowButton: "auto",
        onTabSequenceChange: function onTabSequenceChange(_ref5) {
            var oldIndex = _ref5.oldIndex, newIndex = _ref5.newIndex;
            dispatch(rearrangeTabs(projectUid, simpleSwitch(tabDockDocuments, oldIndex, newIndex), newIndex));
        }
    }, ___EmotionJSX(DragTabList, {
        id: "drag-tab-list"
    }, openTabList), ___EmotionJSX(PanelList, null, openTabPanels)));
    var isManualVisible = useSelector(function (store) {
        return store.ProjectEditorReducer.manualVisible;
    });
    var isFileTreeVisible = useSelector(function (store) {
        return store.ProjectEditorReducer.fileTreeVisible;
    });
    useEffect(function () {
        var lastIsManualVisible = sessionStorage.getItem(projectUid + ":manualVisible");
        if (!isEmpty(lastIsManualVisible)) {
            if (lastIsManualVisible === "true") {
                dispatch(setManualPanelOpen(true));
            }
            sessionStorage.removeItem(projectUid + ":manualVisible");
        }
    }, [dispatch, projectUid]);
    useEffect(function () {
        if (projectUid) {
            storeProjectEditorKeyboardCallbacks(projectUid);
            storeEditorKeyboardCallbacks(projectUid);
        }
    }, [dispatch, projectUid]);
    return isMobile() ? ___EmotionJSX(MobileTabs, {
        activeProject: activeProject,
        projectUid: projectUid,
        currentDocument: openDocuments[tabIndex]
    }) : ___EmotionJSX(React.Fragment, null, unsavedDataExitPrompt, ___EmotionJSX(DnDProvider, {
        project: activeProject
    }, ___EmotionJSX("div", {
        css: SS.splitterRoot
    }, ___EmotionJSX(MySplit, {
        primary: "second",
        split: "vertical",
        minSize: "80%",
        maxSize: "0",
        defaultSize: "80%",
        onDragStarted: function onDragStarted() {
            return setIsDragging(true);
        },
        onDragFinished: function onDragFinished() {
            return setIsDragging(false);
        }
    }, isFileTreeVisible && ___EmotionJSX(FileTree, null), ___EmotionJSX(MySplit, {
        primary: "first",
        split: "vertical",
        minSize: "80%",
        maxSize: "0",
        defaultSize: "60%",
        onDragStarted: function onDragStarted() {
            return setIsDragging(true);
        },
        onDragFinished: function onDragFinished() {
            return setIsDragging(false);
        }
    }, ___EmotionJSX(MainSection, {
        tabDock: tabDock,
        setIsDragging: setIsDragging
    }), isManualVisible && ___EmotionJSX(CsoundManualWindow, {
        projectUid: projectUid,
        isDragging: isDragging
    }))))));
};
export default ProjectEditor;
