export var STORE_USER_PROFILE = "PROFILE.STORE_USER_PROFILE";
export var STORE_PROFILE_PROJECTS_COUNT = "PROFILE.STORE_PROFILE_PROJECTS_COUNT";
export var STORE_PROFILE_STARS = "PROFILE.STORE_PROFILE_STARS";
export var ADD_USER_PROJECT = "PROFILE.ADD_USER_PROJECT";
export var DELETE_USER_PROJECT = "PROFILE.DELETE_USER_PROJECT";
export var SET_CURRENT_TAG_TEXT = "PROFILE.SET_CURRENT_TAG_TEXT";
export var SET_TAGS_INPUT = "PROFILE.SET_TAGS_INPUT";
export var SET_USER_PROFILE = "PROFILE.SET_USER_PROFILE";
export var SET_FOLLOWING_FILTER_STRING = "PROFILE.SET_FOLLOWING_FILTER_STRING";
export var SET_PROJECT_FILTER_STRING = "PROFILE.SET_PROJECT_FILTER_STRING";
export var SET_CURRENTLY_PLAYING_PROJECT = "PROFILE.SET_CURRENTLY_PLAYING_PROJECT";
export var CLOSE_CURRENTLY_PLAYING_PROJECT = "PROFILE.CLOSE_CURRENTLY_PLAYING_PROJECT";
export var SET_LIST_PLAY_STATE = "PROFILE.SET_LIST_PLAY_STATE";
export var GET_ALL_TAGS = "PROFILE.GET_ALL_TAGS";
export var REFRESH_USER_PROFILE = "PROFILE.REFRESH_USER_PROFILE";
export var UPDATE_LOGGED_IN_FOLLOWING = "PROFILE.UPDATE_LOGGED_IN_FOLLOWING";
export var UPDATE_PROFILE_FOLLOWING = "PROFILE.UPDATE_PROFILE_FOLLOWING";
export var UPDATE_PROFILE_FOLLOWERS = "PROFILE.UPDATE_PROFILE_FOLLOWERS";
export var GET_USER_PROFILES_FOR_FOLLOWING = "PROFILE.GET_USER_PROFILES_FOR_FOLLOWING";
