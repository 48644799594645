// import firebase from "firebase/app";
import { OPEN_SNACKBAR, CLOSE_SNACKBAR } from "./types";
export var openSnackbar = function openSnackbar(text, type) {
    var timeout = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 6000;
    var payload = {
        text: text,
        type: type,
        timeout: timeout
    };
    return {
        type: OPEN_SNACKBAR,
        payload: payload
    };
};
export var closeSnackbar = function closeSnackbar() {
    return {
        type: CLOSE_SNACKBAR
    };
};
