import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import React, { useMemo } from "react";
import { configure, GlobalHotKeys } from "react-hotkeys";
import { selectUpdateCounter, selectKeyBindings } from "./selectors";
import { useSelector } from "react-redux";
import { assoc, reduce } from "ramda";
import { keyboardCallbacks } from "./index";
import { jsx as ___EmotionJSX } from "@emotion/react";
configure({
    // logLevel: "verbose",
    defaultKeyEvent: "keydown",
    stopEventPropagationAfterHandling: true,
    stopEventPropagationAfterIgnoring: true,
    allowCombinationSubmatches: false,
    ignoreEventsCondition: function ignoreEventsCondition() {
        return false;
    },
    ignoreTags: []
});
// type HotKeyHandler = (keyEvent?: KeyboardEvent) => void;
var HotKeys = function HotKeys(_ref) {
    var children = _ref.children;
    // prevent leak into the manual iframe
    var insideIframe = !!window.frameElement;
    var bindings = useSelector(selectKeyBindings);
    var updateCounter = useSelector(selectUpdateCounter);
    // all callbacks that aren't bound must be noop callbacks
    var safeCallbacks = useMemo(function () {
        return reduce(function (accumulator, k) {
            return assoc(k, keyboardCallbacks.get(k), accumulator);
        }, {}, _toConsumableArray(keyboardCallbacks.keys()));
    }, /* eslint-disable-next-line react-hooks/exhaustive-deps */ [updateCounter]);
    return ___EmotionJSX(React.Fragment, null, insideIframe ? ___EmotionJSX(React.Fragment, null, children) : ___EmotionJSX(GlobalHotKeys, {
        keyMap: bindings,
        handlers: safeCallbacks,
        allowChanges: true
    }, children));
};
export default HotKeys;
