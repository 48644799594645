import { UPDATE_USER_PROFILE } from "@comp/login/types";
import { SET_CSOUND_PLAY_STATE } from "@comp/csound/types";
import { SET_CURRENTLY_PLAYING_PROJECT, CLOSE_CURRENTLY_PLAYING_PROJECT, SET_FOLLOWING_FILTER_STRING, SET_PROJECT_FILTER_STRING, STORE_USER_PROFILE, STORE_PROFILE_PROJECTS_COUNT, STORE_PROFILE_STARS, GET_ALL_TAGS, UPDATE_PROFILE_FOLLOWING, UPDATE_PROFILE_FOLLOWERS } from "./types";
import { assoc, assocPath, dissoc, hasPath, keys, mergeAll, pick, pipe, reduce, sort } from "ramda";
var INITIAL_STATE = {
    profiles: {},
    currentTagText: "",
    tagsInput: [],
    currentlyPlayingProject: undefined,
    projectFilterString: "",
    followingFilterString: ""
};
var profileKeys = ["bio", "displayName", "link1", "link2", "link3", "backgroundIndex", "photoUrl", "userUid", "username"];
var ProfileReducer = function ProfileReducer() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
    var action = arguments.length > 1 ? arguments[1] : undefined;
    switch (action.type) {
        case SET_FOLLOWING_FILTER_STRING:
            {
                return assoc("followingFilterString", action.payload, state);
            }
        case SET_PROJECT_FILTER_STRING:
            {
                return assoc("projectFilterString", action.payload, state);
            }
        case UPDATE_USER_PROFILE:
            {
                return assocPath(["profiles", action.userUid], mergeAll([state.profiles[action.userUid] || {}, pick(profileKeys, action.profile || {})]), state);
            }
        case STORE_USER_PROFILE:
            {
                return hasPath(["profiles", action.profileUid], state) ? state : assocPath(["profiles", action.profileUid], action.profile, state);
            }
        case GET_ALL_TAGS:
            {
                return assocPath(["profiles", action.loggedInUserUid, "allTags"], action.allTags, state);
            }
        case STORE_PROFILE_PROJECTS_COUNT:
            {
                return assocPath(["profiles", action.profileUid, "projectsCount"], action.projectsCount, state);
            }
        case STORE_PROFILE_STARS:
            {
                var sortedStars = sort(function (x) {
                    return x.timestamp;
                }, keys(action.stars).map(function (p) {
                    return {
                        timestamp: action.stars[p].toDate,
                        projectUid: p
                    };
                }));
                return assocPath(["profiles", action.profileUid, "stars"], sortedStars.map(function (x) {
                    return x.projectUid;
                }), state);
            }
        case UPDATE_PROFILE_FOLLOWING:
            {
                return pipe(assoc("profiles", reduce(function (accumulator, item) {
                    return assoc(item.userUid, item, accumulator);
                }, state.profiles, action.userProfiles)), assocPath(["profiles", action.profileUid, "following"], action.userProfileUids))(state);
            }
        case UPDATE_PROFILE_FOLLOWERS:
            {
                return pipe(assoc("profiles", reduce(function (accumulator, item) {
                    return assoc(item.userUid, item, accumulator);
                }, state.profiles, action.userProfiles)), assocPath(["profiles", action.profileUid, "followers"], action.userProfileUids))(state);
            }
        case SET_CURRENTLY_PLAYING_PROJECT:
            {
                return assoc("currentlyPlayingProject", action.projectUid, state);
            }
        case CLOSE_CURRENTLY_PLAYING_PROJECT:
            {
                return dissoc("currentlyPlayingProject", state);
            }
        case SET_CSOUND_PLAY_STATE:
            {
                if (state.currentlyPlayingProject && action.status === "stopped") {
                    return dissoc("currentlyPlayingProject", state);
                }
                return state;
            }
        default:
            {
                return state;
            }
    }
};
export default ProfileReducer;
