import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { useEffect, useState } from "react";
import { useDispatch } from "@root/store";
import { isMobile } from "@root/utils";
import Router from "@comp/router/router";
import ThemeProvider from "@styles/theme-provider";
import Modal from "@comp/modal";
import IosWarning from "./ios-warning";
import Snackbar from "@comp/snackbar/snackbar";
import { subscribeToLoggedInUserProfile } from "@comp/login/subscribers";
import { setRequestingStatus, thirdPartyAuthSuccess } from "@comp/login/actions";
import { getAuth } from "firebase/auth";
import HotKeys from "@comp/hot-keys/hot-keys";
import { jsx as ___EmotionJSX } from "@emotion/react";
var Main = function Main() {
    var dispatch = useDispatch();
    var _useState = useState(false), _useState2 = _slicedToArray(_useState, 2), autoLoginTimeout = _useState2[0], setAutoLoginTimeout = _useState2[1];
    useEffect(function () {
        var unsubscribeLoggedInUserProfile;
        // the observer doesn't know if the login state
        // change is a result of manual login or autologin
        // we determine this from a timeout
        !autoLoginTimeout && setTimeout(function () {
            return setAutoLoginTimeout(true);
        }, 1000);
        var unsubscribeAuthObserver = getAuth().onAuthStateChanged(function (user) {
            if (user) {
                unsubscribeLoggedInUserProfile = subscribeToLoggedInUserProfile(user.uid, dispatch);
                var tsIsSometimesStupidUser = {
                    uid: user.uid,
                    displayName: user.displayName || undefined
                };
                dispatch(thirdPartyAuthSuccess(tsIsSometimesStupidUser, !autoLoginTimeout));
            }
            else {
                dispatch(setRequestingStatus(false));
            }
        });
        // if (!isMobile()) {
        //     (window as any).ps_body = new PerfectScrollbar("#root");
        // }
        return function () {
            unsubscribeAuthObserver();
            unsubscribeLoggedInUserProfile && unsubscribeLoggedInUserProfile();
            !isMobile() && window.ps_body && window.ps_body.destroy();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return ___EmotionJSX(ThemeProvider, null, ___EmotionJSX(HotKeys, null, ___EmotionJSX(React.Fragment, null, ___EmotionJSX(Modal, null), ___EmotionJSX(IosWarning, null), ___EmotionJSX(Snackbar, null), ___EmotionJSX(Router, null))));
};
export default Main;
