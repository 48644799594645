function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) {
    if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") {
        if (it)
            o = it;
        var i = 0;
        var F = function F() { };
        return { s: F, n: function n() { if (i >= o.length)
                return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F };
    }
    throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
} var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try {
        if (!normalCompletion && it.return != null)
            it.return();
    }
    finally {
        if (didErr)
            throw err;
    } } }; }
function _unsupportedIterableToArray(o, minLen) { if (!o)
    return; if (typeof o === "string")
    return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor)
    n = o.constructor.name; if (n === "Map" || n === "Set")
    return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n))
    return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length)
    len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++)
    arr2[i] = arr[i]; return arr2; }
import { store } from "@root/store";
import { ADD_NON_CLOUD_FILE, CLEANUP_NON_CLOUD_FILES, DELETE_NON_CLOUD_FILE } from "./types";
import { TAB_CLOSE } from "@comp/project-editor/types";
export var nonCloudFiles = new Map();
export var addNonCloudFile = function addNonCloudFile(file) {
    return {
        type: ADD_NON_CLOUD_FILE,
        file: {
            name: file.name,
            createdAt: Number(file.createdAt)
        }
    };
};
export var deleteNonCloudFiles = function deleteNonCloudFiles(filename) {
    return {
        type: DELETE_NON_CLOUD_FILE,
        filename: filename
    };
};
export var cleanupNonCloudFiles = function cleanupNonCloudFiles(_ref) {
    var projectUid = _ref.projectUid;
    var _iterator = _createForOfIteratorHelper(nonCloudFiles.keys()), _step;
    try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var openNcf = _step.value;
            store.dispatch({
                type: TAB_CLOSE,
                projectUid: projectUid,
                documentUid: openNcf
            });
        }
    }
    catch (err) {
        _iterator.e(err);
    }
    finally {
        _iterator.f();
    }
    nonCloudFiles.clear();
    return {
        type: CLEANUP_NON_CLOUD_FILES
    };
};
