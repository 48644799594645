import { useDispatch, useSelector } from "@root/store";
import Tooltip from "@mui/material/Tooltip";
import { pathOr } from "ramda";
import { stopCsound } from "@comp/csound/actions";
import { IconButton } from "@mui/material";
import StopIcon from "@mui/icons-material/Stop";
import * as SS from "./styles";
import { jsx as ___EmotionJSX } from "@emotion/react";
var StopButton = function StopButton() {
    var csoundPlayState = useSelector(function (store) {
        return pathOr("stopped", ["csound", "status"], store);
    });
    var dispatch = useDispatch();
    var tooltipText = csoundPlayState === "rendering" ? "stop render" : "stop playback";
    return ___EmotionJSX(Tooltip, {
        title: tooltipText
    }, ___EmotionJSX("div", {
        css: SS.buttonContainer
    }, ___EmotionJSX(IconButton, {
        css: SS.iconButton,
        size: "medium",
        onClick: function onClick() {
            switch (csoundPlayState) {
                case "playing":
                case "rendering":
                case "paused":
                    {
                        dispatch(stopCsound());
                        break;
                    }
            }
        },
        disabled: csoundPlayState === "stopped" || csoundPlayState === "error"
    }, ___EmotionJSX(StopIcon, {
        css: SS.stopIcon,
        fontSize: "large"
    }))));
};
export default StopButton;
