import _typeof from "@babel/runtime/helpers/typeof";
import { STORE_PROJECT_STARS } from "@comp/projects/types";
import { stars } from "@config/firestore";
import { doc, onSnapshot } from "firebase/firestore";
export var subscribeToProjectStars = function subscribeToProjectStars(projectUid, dispatch) {
    var unsubscribe = onSnapshot(doc(stars, projectUid), function (stars) {
        var starsData = stars.data();
        var starsDataSerializeable = {};
        for (var sdk in starsData) {
            var sdkd = starsData[sdk];
            if (_typeof(sdkd) === "object") {
                starsDataSerializeable[sdk] = sdkd.toMillis();
            }
        }
        dispatch({
            type: STORE_PROJECT_STARS,
            projectUid: projectUid,
            stars: starsDataSerializeable
        });
    }, function (error) {
        return console.error(error);
    });
    return unsubscribe;
};
