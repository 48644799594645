import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { useState } from "react";
import FileTree from "@comp/file-tree";
import Console from "@comp/console/console";
import MobileNavigation from "@comp/project-editor/mobile-navigation";
import CsoundManualWindow from "@comp/project-editor/csound-manual";
import { EditorForDocument } from "@comp/project-editor/project-editor";
import { DnDProvider } from "@comp/file-tree/context";
import * as SS from "./styles";
import { jsx as ___EmotionJSX } from "@emotion/react";
var MobileTabs = function MobileTabs(_ref) {
    var activeProject = _ref.activeProject, projectUid = _ref.projectUid, currentDocument = _ref.currentDocument;
    var _useState = useState(0), _useState2 = _slicedToArray(_useState, 2), mobileTabIndex = _useState2[0], setMobileTabIndex = _useState2[1];
    var MobileFileTree = ___EmotionJSX("div", {
        css: SS.mobileFileTree
    }, ___EmotionJSX(FileTree, null));
    var MobileConsole = ___EmotionJSX("div", {
        css: SS.mobileConsole,
        style: {
            display: mobileTabIndex === 2 ? "inherit" : "none"
        }
    }, ___EmotionJSX(Console, null));
    var MobileManual = ___EmotionJSX("div", {
        css: SS.mobileManual
    }, ___EmotionJSX(CsoundManualWindow, {
        projectUid: projectUid
    }));
    return ___EmotionJSX(DnDProvider, {
        project: activeProject
    }, ___EmotionJSX(React.Fragment, null, ___EmotionJSX("style", null, "body {overflow: hidden!important;}" + "#drag-tab-list {display: none;}\n                         .cm-editor {  zoom: 120%; }\n                         .cm-theme {\n                           overflow-y: scroll;\n                           top: 68px;\n                           height: calc(100vh - 68px - 56px)!important;\n                           position: absolute;\n                           left: 0;\n                           right: 0;\n                          bottom: 56px;}"), MobileConsole, mobileTabIndex === 0 ? currentDocument && ___EmotionJSX(EditorForDocument, {
        uid: projectUid,
        projectUid: projectUid,
        doc: currentDocument,
        isOwner: false
    }) : mobileTabIndex === 1 ? MobileFileTree : mobileTabIndex === 3 ? MobileManual : undefined, ___EmotionJSX(MobileNavigation, {
        mobileTabIndex: mobileTabIndex,
        setMobileTabIndex: setMobileTabIndex
    })));
};
export default MobileTabs;
