import React from "react";
import { useSelector } from "@root/store";
import { isEmpty, path, range } from "ramda";
import LeftIcon from "@mui/icons-material/ArrowBack";
import RightIcon from "@mui/icons-material/ArrowForward";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@emotion/react";
import ProjectCard, { ProjectCardSkeleton } from "./project-card";
import * as SS from "./styles";
import { jsx as ___EmotionJSX } from "@emotion/react";
var PopularProjects = function PopularProjects(_ref) {
    var projects = _ref.projects, handlePopularProjectsNextPage = _ref.handlePopularProjectsNextPage, handlePopularProjectsPreviousPage = _ref.handlePopularProjectsPreviousPage, hasNext = _ref.hasNext, hasPrevious = _ref.hasPrevious;
    var theme = useTheme();
    var profiles = useSelector(function (store) {
        return path(["HomeReducer", "profiles"], store);
    });
    var isLoading = useSelector(function (store) {
        var totalRecords = path(["HomeReducer", "popularProjectsTotalRecords"], store);
        return totalRecords < 0 || isEmpty(projects) && totalRecords > 0;
    });
    return ___EmotionJSX(React.Fragment, null, ___EmotionJSX("div", {
        css: SS.homeHeading
    }, ___EmotionJSX(IconButton, {
        "aria-label": "left",
        "data-tip": "Previous projects",
        css: SS.paginationButton(!isLoading && hasPrevious),
        style: {
            right: 48
        },
        onClick: handlePopularProjectsPreviousPage,
        disabled: isLoading || !hasPrevious
    }, ___EmotionJSX(LeftIcon, null)), ___EmotionJSX(IconButton, {
        "aria-label": "right",
        "data-tip": "Next projects",
        css: SS.paginationButton(!isLoading && hasNext),
        onClick: handlePopularProjectsNextPage,
        disabled: isLoading || !hasNext
    }, ___EmotionJSX(RightIcon, null)), ___EmotionJSX("h1", {
        css: SS.homePageHeading
    }, "Popular Projects"), ___EmotionJSX("hr", {
        css: SS.homePageHeadingBreak
    })), ___EmotionJSX("div", {
        css: SS.doubleGridContainer
    }, isLoading ? range(0, 8).map(function (index) {
        return ___EmotionJSX(ProjectCardSkeleton, {
            theme: theme,
            key: index
        });
    }) : projects.map(function (project, index) {
        return profiles[project.userUid] ? ___EmotionJSX(ProjectCard, {
            key: "p".concat(index),
            projectIndex: index,
            project: project,
            profile: profiles[project.userUid]
        }) : ___EmotionJSX(ProjectCardSkeleton, {
            theme: theme,
            key: index
        });
    })));
};
export default PopularProjects;
