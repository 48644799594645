import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols);
} return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); });
} return target; }
import { OPEN_SNACKBAR, CLOSE_SNACKBAR, SnackbarType } from "./types";
var INITIAL_STATE = {
    text: "",
    type: SnackbarType.Info,
    open: false,
    timeout: 6000
};
var SnackbarReducer = function SnackbarReducer() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
    var action = arguments.length > 1 ? arguments[1] : undefined;
    switch (action.type) {
        case OPEN_SNACKBAR:
            {
                return _objectSpread(_objectSpread(_objectSpread({}, state), action.payload), {}, {
                    open: true
                });
            }
        case CLOSE_SNACKBAR:
            {
                return _objectSpread(_objectSpread({}, state), {}, {
                    open: false
                });
            }
        default:
            {
                return state;
            }
    }
};
export default SnackbarReducer;
