import AutoStoriesRoundedIcon from "@mui/icons-material/AutoStoriesRounded";
import ListAltRoundedIcon from "@mui/icons-material/ListAltRounded";
import AccountTree from "@mui/icons-material/AccountTree";
import FormatTextdirectionLToR from "@mui/icons-material/FormatTextdirectionLToR";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import * as SS from "./styles";
import { jsx as ___EmotionJSX } from "@emotion/react";
var MobileNavigation = function MobileNavigation(_ref) {
    var mobileTabIndex = _ref.mobileTabIndex, setMobileTabIndex = _ref.setMobileTabIndex;
    return ___EmotionJSX(BottomNavigation, {
        value: mobileTabIndex,
        css: SS.mobileNavigationContainer,
        showLabels: true
    }, ___EmotionJSX(BottomNavigationAction, {
        value: 0,
        onClick: function onClick() {
            return setMobileTabIndex(0);
        },
        css: SS.mobileNavigationButton,
        label: "Edit",
        icon: ___EmotionJSX(FormatTextdirectionLToR, {
            fontSize: "large"
        })
    }), ___EmotionJSX(BottomNavigationAction, {
        value: 1,
        onClick: function onClick() {
            return setMobileTabIndex(1);
        },
        css: SS.mobileNavigationButton,
        label: "File Tree",
        icon: ___EmotionJSX(AccountTree, {
            fontSize: "large"
        })
    }), ___EmotionJSX(BottomNavigationAction, {
        value: 2,
        onClick: function onClick() {
            return setMobileTabIndex(2);
        },
        css: SS.mobileNavigationButton,
        label: "Console",
        icon: ___EmotionJSX(ListAltRoundedIcon, {
            fontSize: "large"
        })
    }), ___EmotionJSX(BottomNavigationAction, {
        value: 3,
        onClick: function onClick() {
            return setMobileTabIndex(3);
        },
        css: SS.mobileNavigationButton,
        label: "Manual",
        icon: ___EmotionJSX(AutoStoriesRoundedIcon, {
            fontSize: "large"
        })
    }));
};
export default MobileNavigation;
