// import { createSelector } from "reselect";
export var selectSnackbarOpen = function selectSnackbarOpen(store) {
    var state = store.SnackbarReducer;
    return state.open;
};
export var selectSnackbarType = function selectSnackbarType(store) {
    var state = store.SnackbarReducer;
    return state.type;
};
export var selectSnackbarText = function selectSnackbarText(store) {
    var state = store.SnackbarReducer;
    return state.text;
};
export var selectSnackbarTimeout = function selectSnackbarTimeout(store) {
    var state = store.SnackbarReducer;
    return state.timeout;
};
