import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React from "react";
import { useDispatch, useSelector } from "@root/store";
import IconButton from "@mui/material/IconButton";
import ShuffleIcon from "@mui/icons-material/Shuffle";
import { path, range } from "ramda";
import { fetchRandomProjects } from "./actions";
import { useTheme } from "@emotion/react";
import ProjectCard, { ProjectCardSkeleton } from "./project-card";
import * as SS from "./styles";
import { jsx as ___EmotionJSX } from "@emotion/react";
var RandomProjects = function RandomProjects() {
    var dispatch = useDispatch();
    var _React$useState = React.useState(false), _React$useState2 = _slicedToArray(_React$useState, 2), isMounted = _React$useState2[0], setIsMounted = _React$useState2[1];
    React.useEffect(function () {
        if (!isMounted) {
            setIsMounted(true);
            var _randomProjects;
            try {
                _randomProjects = fetchRandomProjects();
            }
            catch (error) {
                console.error(error);
            }
            _randomProjects && dispatch(_randomProjects);
        }
    }, [dispatch, isMounted, setIsMounted]);
    var theme = useTheme();
    var profiles = useSelector(function (store) {
        return path(["HomeReducer", "profiles"], store);
    });
    var randomProjects = useSelector(function (store) {
        return path(["HomeReducer", "randomProjects"], store);
    });
    var randomProjectsLoading = useSelector(function (store) {
        return path(["HomeReducer", "randomProjectsLoading"], store);
    });
    return ___EmotionJSX(React.Fragment, null, ___EmotionJSX("div", {
        css: SS.homeHeading,
        style: {
            marginTop: 12
        }
    }, ___EmotionJSX(IconButton, {
        "aria-label": "shuffle",
        "data-tip": "Shuffle for more random projects",
        css: SS.shuffleButton,
        disabled: randomProjectsLoading,
        onClick: function onClick() {
            return dispatch(fetchRandomProjects());
        }
    }, ___EmotionJSX(ShuffleIcon, null)), ___EmotionJSX("h1", {
        css: SS.homePageHeading
    }, "Random Projects"), ___EmotionJSX("hr", {
        css: SS.homePageHeadingBreak
    })), ___EmotionJSX("div", {
        css: SS.doubleGridContainer
    }, randomProjectsLoading ? range(0, 8).map(function (index) {
        return ___EmotionJSX(ProjectCardSkeleton, {
            theme: theme,
            key: index
        });
    }) : randomProjects.map(function (project, index) {
        return profiles[project.userUid] ? ___EmotionJSX(ProjectCard, {
            key: "p".concat(index),
            projectIndex: index,
            project: project,
            profile: profiles[project.userUid]
        }) : ___EmotionJSX(ProjectCardSkeleton, {
            theme: theme,
            key: index
        });
    })));
};
export default RandomProjects;
