function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
export var rootStyle = process.env.NODE_ENV === "production" ? {
    name: "ntklbk",
    styles: "background-color:#e8e8e8;font-family:'Space Mono', monospace;width:100%;height:auto;bottom:0px;top:0px;left:0;position:absolute"
} : {
    name: "1ds27fy-rootStyle",
    styles: "background-color:#e8e8e8;font-family:'Space Mono', monospace;width:100%;height:auto;bottom:0px;top:0px;left:0;position:absolute;label:rootStyle;",
    map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9ob21lL3J1bm5lci93b3JrL3dlYi1pZGUvd2ViLWlkZS9zcmMvY29tcG9uZW50cy9wYWdlLTQwNC9zdHlsZXMudHMiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBRXlCIiwiZmlsZSI6Ii9ob21lL3J1bm5lci93b3JrL3dlYi1pZGUvd2ViLWlkZS9zcmMvY29tcG9uZW50cy9wYWdlLTQwNC9zdHlsZXMudHMiLCJzb3VyY2VzQ29udGVudCI6WyJpbXBvcnQgeyBjc3MgfSBmcm9tIFwiQGVtb3Rpb24vcmVhY3RcIjtcblxuZXhwb3J0IGNvbnN0IHJvb3RTdHlsZSA9IGNzcyh7XG4gICAgYmFja2dyb3VuZENvbG9yOiBcIiNlOGU4ZThcIixcbiAgICBmb250RmFtaWx5OiBcIidTcGFjZSBNb25vJywgbW9ub3NwYWNlXCIsXG4gICAgd2lkdGg6IFwiMTAwJVwiLFxuICAgIGhlaWdodDogXCJhdXRvXCIsXG4gICAgYm90dG9tOiBcIjBweFwiLFxuICAgIHRvcDogXCIwcHhcIixcbiAgICBsZWZ0OiAwLFxuICAgIHBvc2l0aW9uOiBcImFic29sdXRlXCJcbn0pO1xuXG5leHBvcnQgY29uc3QgY2VudGVyQm94U3R5bGUgPSBjc3Moe1xuICAgIHBvc2l0aW9uOiBcImFic29sdXRlXCIsXG4gICAgd2lkdGg6IFwiNjAwcHhcIixcbiAgICBoZWlnaHQ6IFwiNTBweFwiLFxuICAgIHRvcDogXCIxMjBweFwiLFxuICAgIGxlZnQ6IFwiNTAlXCIsXG4gICAgbWFyZ2luVG9wOiBcIi0yNXB4XCIsXG4gICAgbWFyZ2luTGVmdDogXCItNTBweFwiXG59KTtcblxuZXhwb3J0IGNvbnN0IHN0YXJ0Q29kaW5nQnV0dG9uU3R5bGUgPSBjc3Moe1xuICAgIGZvbnRTaXplOiBcIjIycHhcIixcbiAgICBib3JkZXI6IFwiNHB4IHNvbGlkICM1MThDODJcIixcbiAgICBib3JkZXJSYWRpdXM6IFwiODAlXCIsXG4gICAgZGlzcGxheTogXCJmbGV4XCIsXG4gICAgYWxpZ25JdGVtczogXCJjZW50ZXJcIixcbiAgICBqdXN0aWZ5Q29udGVudDogXCJjZW50ZXJcIixcbiAgICB3aWR0aDogXCIyMjBweFwiLFxuICAgIGhlaWdodDogXCIyMjBweFwiLFxuICAgIHRleHREZWNvcmF0aW9uOiBcIm5vbmVcIixcbiAgICBiYWNrZ3JvdW5kOiBcIiMwMERGQ0JcIlxufSk7XG4iXX0= */",
    toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
export var centerBoxStyle = process.env.NODE_ENV === "production" ? {
    name: "1ypeha2",
    styles: "position:absolute;width:600px;height:50px;top:120px;left:50%;margin-top:-25px;margin-left:-50px"
} : {
    name: "sd8qw0-centerBoxStyle",
    styles: "position:absolute;width:600px;height:50px;top:120px;left:50%;margin-top:-25px;margin-left:-50px;label:centerBoxStyle;",
    map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9ob21lL3J1bm5lci93b3JrL3dlYi1pZGUvd2ViLWlkZS9zcmMvY29tcG9uZW50cy9wYWdlLTQwNC9zdHlsZXMudHMiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBYThCIiwiZmlsZSI6Ii9ob21lL3J1bm5lci93b3JrL3dlYi1pZGUvd2ViLWlkZS9zcmMvY29tcG9uZW50cy9wYWdlLTQwNC9zdHlsZXMudHMiLCJzb3VyY2VzQ29udGVudCI6WyJpbXBvcnQgeyBjc3MgfSBmcm9tIFwiQGVtb3Rpb24vcmVhY3RcIjtcblxuZXhwb3J0IGNvbnN0IHJvb3RTdHlsZSA9IGNzcyh7XG4gICAgYmFja2dyb3VuZENvbG9yOiBcIiNlOGU4ZThcIixcbiAgICBmb250RmFtaWx5OiBcIidTcGFjZSBNb25vJywgbW9ub3NwYWNlXCIsXG4gICAgd2lkdGg6IFwiMTAwJVwiLFxuICAgIGhlaWdodDogXCJhdXRvXCIsXG4gICAgYm90dG9tOiBcIjBweFwiLFxuICAgIHRvcDogXCIwcHhcIixcbiAgICBsZWZ0OiAwLFxuICAgIHBvc2l0aW9uOiBcImFic29sdXRlXCJcbn0pO1xuXG5leHBvcnQgY29uc3QgY2VudGVyQm94U3R5bGUgPSBjc3Moe1xuICAgIHBvc2l0aW9uOiBcImFic29sdXRlXCIsXG4gICAgd2lkdGg6IFwiNjAwcHhcIixcbiAgICBoZWlnaHQ6IFwiNTBweFwiLFxuICAgIHRvcDogXCIxMjBweFwiLFxuICAgIGxlZnQ6IFwiNTAlXCIsXG4gICAgbWFyZ2luVG9wOiBcIi0yNXB4XCIsXG4gICAgbWFyZ2luTGVmdDogXCItNTBweFwiXG59KTtcblxuZXhwb3J0IGNvbnN0IHN0YXJ0Q29kaW5nQnV0dG9uU3R5bGUgPSBjc3Moe1xuICAgIGZvbnRTaXplOiBcIjIycHhcIixcbiAgICBib3JkZXI6IFwiNHB4IHNvbGlkICM1MThDODJcIixcbiAgICBib3JkZXJSYWRpdXM6IFwiODAlXCIsXG4gICAgZGlzcGxheTogXCJmbGV4XCIsXG4gICAgYWxpZ25JdGVtczogXCJjZW50ZXJcIixcbiAgICBqdXN0aWZ5Q29udGVudDogXCJjZW50ZXJcIixcbiAgICB3aWR0aDogXCIyMjBweFwiLFxuICAgIGhlaWdodDogXCIyMjBweFwiLFxuICAgIHRleHREZWNvcmF0aW9uOiBcIm5vbmVcIixcbiAgICBiYWNrZ3JvdW5kOiBcIiMwMERGQ0JcIlxufSk7XG4iXX0= */",
    toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
export var startCodingButtonStyle = process.env.NODE_ENV === "production" ? {
    name: "10lfszy",
    styles: "font-size:22px;border:4px solid #518C82;border-radius:80%;display:flex;align-items:center;justify-content:center;width:220px;height:220px;text-decoration:none;background:#00DFCB"
} : {
    name: "15hvn0c-startCodingButtonStyle",
    styles: "font-size:22px;border:4px solid #518C82;border-radius:80%;display:flex;align-items:center;justify-content:center;width:220px;height:220px;text-decoration:none;background:#00DFCB;label:startCodingButtonStyle;",
    map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9ob21lL3J1bm5lci93b3JrL3dlYi1pZGUvd2ViLWlkZS9zcmMvY29tcG9uZW50cy9wYWdlLTQwNC9zdHlsZXMudHMiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBdUJzQyIsImZpbGUiOiIvaG9tZS9ydW5uZXIvd29yay93ZWItaWRlL3dlYi1pZGUvc3JjL2NvbXBvbmVudHMvcGFnZS00MDQvc3R5bGVzLnRzIiwic291cmNlc0NvbnRlbnQiOlsiaW1wb3J0IHsgY3NzIH0gZnJvbSBcIkBlbW90aW9uL3JlYWN0XCI7XG5cbmV4cG9ydCBjb25zdCByb290U3R5bGUgPSBjc3Moe1xuICAgIGJhY2tncm91bmRDb2xvcjogXCIjZThlOGU4XCIsXG4gICAgZm9udEZhbWlseTogXCInU3BhY2UgTW9ubycsIG1vbm9zcGFjZVwiLFxuICAgIHdpZHRoOiBcIjEwMCVcIixcbiAgICBoZWlnaHQ6IFwiYXV0b1wiLFxuICAgIGJvdHRvbTogXCIwcHhcIixcbiAgICB0b3A6IFwiMHB4XCIsXG4gICAgbGVmdDogMCxcbiAgICBwb3NpdGlvbjogXCJhYnNvbHV0ZVwiXG59KTtcblxuZXhwb3J0IGNvbnN0IGNlbnRlckJveFN0eWxlID0gY3NzKHtcbiAgICBwb3NpdGlvbjogXCJhYnNvbHV0ZVwiLFxuICAgIHdpZHRoOiBcIjYwMHB4XCIsXG4gICAgaGVpZ2h0OiBcIjUwcHhcIixcbiAgICB0b3A6IFwiMTIwcHhcIixcbiAgICBsZWZ0OiBcIjUwJVwiLFxuICAgIG1hcmdpblRvcDogXCItMjVweFwiLFxuICAgIG1hcmdpbkxlZnQ6IFwiLTUwcHhcIlxufSk7XG5cbmV4cG9ydCBjb25zdCBzdGFydENvZGluZ0J1dHRvblN0eWxlID0gY3NzKHtcbiAgICBmb250U2l6ZTogXCIyMnB4XCIsXG4gICAgYm9yZGVyOiBcIjRweCBzb2xpZCAjNTE4QzgyXCIsXG4gICAgYm9yZGVyUmFkaXVzOiBcIjgwJVwiLFxuICAgIGRpc3BsYXk6IFwiZmxleFwiLFxuICAgIGFsaWduSXRlbXM6IFwiY2VudGVyXCIsXG4gICAganVzdGlmeUNvbnRlbnQ6IFwiY2VudGVyXCIsXG4gICAgd2lkdGg6IFwiMjIwcHhcIixcbiAgICBoZWlnaHQ6IFwiMjIwcHhcIixcbiAgICB0ZXh0RGVjb3JhdGlvbjogXCJub25lXCIsXG4gICAgYmFja2dyb3VuZDogXCIjMDBERkNCXCJcbn0pO1xuIl19 */",
    toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
