import "firebase/auth";
import { store } from "@root/store";
import { Transaction } from "@codemirror/state";
import { pathOr } from "ramda";
import { newDocument, saveAllAndClose, saveAllFiles, saveFile, addDocument } from "@comp/projects/actions";
import { selectIsOwner, selectCurrentTab } from "@comp/project-editor/selectors";
import { showTargetsConfigDialog } from "@comp/target-controls/actions";
import { getPlayActionFromProject, getPlayActionFromTarget } from "@comp/target-controls/utils";
import { csoundInstance, pauseCsound, stopCsound } from "@comp/csound/actions";
import { filenameToCsoundType } from "@comp/csound/utils";
import { openEditors } from "@comp/editor";
import { editorEvalCode } from "@comp/editor/utils";
import * as EditorActions from "@comp/editor/actions";
import { keyboardCallbacks } from "./index";
import { UPDATE_COUNTER } from "./types";
var withPreventDefault = function withPreventDefault(callback) {
    return function (event) {
        event && event.preventDefault();
        callback();
    };
};
export var storeProjectEditorKeyboardCallbacks = function storeProjectEditorKeyboardCallbacks(projectUid) {
    keyboardCallbacks.set("add_file", withPreventDefault(function () {
        return store.dispatch(addDocument(projectUid));
    }));
    keyboardCallbacks.set("new_document", withPreventDefault(function () {
        return store.dispatch(newDocument(projectUid, ""));
    }));
    keyboardCallbacks.set("open_target_config_dialog", withPreventDefault(function () {
        return store.dispatch(showTargetsConfigDialog());
    }));
    keyboardCallbacks.set("pause_playback", withPreventDefault(function () {
        return store.dispatch(pauseCsound());
    }));
    keyboardCallbacks.set("run_project", withPreventDefault(function () {
        var playActionDefault = getPlayActionFromTarget(projectUid)(store.getState());
        var playActionFallback = getPlayActionFromProject(projectUid, store.getState());
        var playAction = playActionDefault || playActionFallback;
        if (playAction) {
            var isOwner = projectUid ? selectIsOwner(projectUid)(store.getState()) : false;
            if (isOwner) {
                store.dispatch(saveAllFiles());
            }
            store.dispatch(playAction);
        }
    }));
    keyboardCallbacks.set("save_all_documents", withPreventDefault(function () {
        return store.dispatch(saveAllFiles());
    }));
    keyboardCallbacks.set("save_and_close", withPreventDefault(function () {
        return saveAllAndClose(store.dispatch, "/");
    }));
    keyboardCallbacks.set("save_document", withPreventDefault(function () {
        return store.dispatch(saveFile());
    }));
    keyboardCallbacks.set("stop_playback", withPreventDefault(function () {
        return store.dispatch(stopCsound());
    }));
    store.dispatch({
        type: UPDATE_COUNTER
    });
};
var selectCurrentEditor = function selectCurrentEditor(store) {
    var currentTab = selectCurrentTab(store);
    var currentDocId = currentTab && currentTab.uid;
    if (currentDocId && openEditors.has(currentDocId)) {
        return openEditors.get(currentDocId);
    }
};
var selectDocumentName = function selectDocumentName(store, projectUid) {
    var currentTab = selectCurrentTab(store);
    if (currentTab) {
        var documentUid = currentTab.uid;
        if (documentUid) {
            var document = store.ProjectsReducer.projects[projectUid].documents[documentUid];
            return document && document.filename;
        }
    }
};
export var storeEditorKeyboardCallbacks = function storeEditorKeyboardCallbacks(projectUid) {
    keyboardCallbacks.set("doc_at_point", withPreventDefault(function () {
        var editor = selectCurrentEditor(store.getState());
        editor && store.dispatch(EditorActions.manualEntryAtPoint(editor));
    }));
    keyboardCallbacks.set("undo", withPreventDefault(function () {
        var editor = selectCurrentEditor(store.getState());
        editor && editor.dispatch({
            annotations: Transaction.userEvent.of("undo")
        });
    }));
    keyboardCallbacks.set("redo", withPreventDefault(function () {
        var editor = selectCurrentEditor(store.getState());
        editor && editor.dispatch({
            annotations: Transaction.userEvent.of("redo")
        });
    }));
    // keyboardCallbacks.set(
    //     "toggle_comment",
    //     withPreventDefault(() => {
    //         const editor = selectCurrentEditor(store.getState());
    //         if (editor && typeof editor.toggleComment === "function") {
    //             editor.toggleComment();
    //         }
    //     })
    // );
    keyboardCallbacks.set("eval_block", withPreventDefault(function () {
        var storeState = store.getState();
        var editor = selectCurrentEditor(storeState);
        var csound = csoundInstance;
        var csoundStatus = pathOr("stopped", ["csound", "status"], storeState);
        var documentName = selectDocumentName(storeState, projectUid);
        var documentType = documentName && filenameToCsoundType(documentName);
        if (editor && csound && documentName && documentType) {
            editorEvalCode(csound, csoundStatus, documentType, editor, true);
        }
    }));
    keyboardCallbacks.set("eval", withPreventDefault(function () {
        var storeState = store.getState();
        var editor = selectCurrentEditor(storeState);
        var csound = csoundInstance;
        var csoundStatus = pathOr("stopped", ["csound", "status"], storeState);
        var documentName = selectDocumentName(storeState, projectUid);
        var documentType = documentName && filenameToCsoundType(documentName);
        if (editor && csound && documentName && documentType) {
            editorEvalCode(csound, csoundStatus, documentType, editor, false);
        }
    }));
};
export var invokeHotKeyCallback = function invokeHotKeyCallback(hotKey) {
    if (keyboardCallbacks.has(hotKey)) {
        var callback = keyboardCallbacks.get(hotKey);
        callback();
    }
};
