import { useCallback } from "react";
import Button from "@mui/material/Button";
import { useDispatch } from "@root/store";
import { closeModal } from "@comp/modal/actions";
import { resetDocumentValue } from "@comp/projects/actions";
import { TAB_CLOSE } from "./types";
import { jsx as ___EmotionJSX } from "@emotion/react";
export function CloseUnsavedFilePrompt(_ref) {
    var projectUid = _ref.projectUid, documentUid = _ref.documentUid;
    var dispatch = useDispatch();
    var cancelCallback = useCallback(function () {
        return dispatch(closeModal());
    }, [dispatch]);
    var closeWithoutSavingCallback = useCallback(function () {
        dispatch(closeModal());
        dispatch({
            type: TAB_CLOSE,
            documentUid: documentUid
        });
        dispatch(resetDocumentValue(projectUid, documentUid));
    }, [dispatch, documentUid, projectUid]);
    return ___EmotionJSX("div", {
        style: {
            display: "flex",
            flexDirection: "column"
        }
    }, ___EmotionJSX(Button, {
        variant: "outlined",
        color: "primary",
        onClick: function onClick() {
            return cancelCallback();
        },
        style: {
            marginTop: 12
        }
    }, "Cancel"), ___EmotionJSX(Button, {
        variant: "outlined",
        color: "primary",
        onClick: function onClick() {
            return closeWithoutSavingCallback();
        },
        style: {
            marginTop: 12
        }
    }, "Close without saveing"));
}
