import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "@root/store";
import { TargetControlsConfigDialog } from "@comp/target-controls/config-dialog";
import ShareDialog from "@comp/share-dialog";
import { KeyboardShortcuts } from "@comp/site-documents/keyboard-shortcuts";
import { AddDocumentPrompt, DeleteDocumentPrompt, NewDocumentPrompt, NewFolderPrompt } from "@comp/projects/modals";
import { CloseUnsavedFilePrompt } from "@comp/project-editor/modals";
import { ProjectModal } from "@comp/profile/project-modal";
import { ProfileModal } from "@comp/profile/profile-modal";
import { DeleteProjectModal } from "@comp/profile/delete-project-modal";
import { ProfileFinalize } from "@comp/login/actions";
import { always } from "ramda";
import * as SS from "./styles";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import ResizeObserver from "resize-observer-polyfill";
import { closeModal } from "./actions";
import { jsx as ___EmotionJSX } from "@emotion/react";
function getModalStyle(width, height) {
    if (!width || !height) {
        return {};
    }
    var topOffset = window.innerHeight / 2 - height / 2;
    var leftOffset = window.innerWidth / 2 - width / 2;
    return {
        top: "".concat(topOffset, "px"),
        left: "".concat(leftOffset, "px")
    };
}
export default function GlobalModal() {
    var modalReference = useRef();
    var _useState = useState([0, 0]), _useState2 = _slicedToArray(_useState, 2), _useState2$ = _slicedToArray(_useState2[0], 2), width = _useState2$[0], height = _useState2$[1], setDimensions = _useState2[1];
    var dispatch = useDispatch();
    var isOpen = useSelector(function (store) {
        return store.ModalReducer.isOpen;
    });
    var modalComponentName = useSelector(function (store) {
        return store.ModalReducer.modalComponentName;
    });
    var modalProperties = useSelector(function (store) {
        return store.ModalReducer.properties;
    }) || {};
    var onClose = function onClose() {
        return dispatch(closeModal());
    };
    var updateDimensions = function updateDimensions(focus) {
        var element = document.querySelector("#modal-window");
        if (element) {
            setDimensions([element.clientWidth, element.clientHeight]);
            if (focus) {
                var maybeInput = element.querySelectorAll("input");
                if (maybeInput && maybeInput.length > 0) {
                    setTimeout(function () {
                        return maybeInput[0] && maybeInput[0].focus();
                    }, 1);
                }
            }
        }
    };
    useEffect(function () {
        isOpen && setTimeout(function () {
            return updateDimensions(true);
        }, 0);
        function handleResize() {
            if (isOpen) {
                updateDimensions(false);
            }
        }
        var resizeObserver = new ResizeObserver(handleResize);
        var copiedReference;
        setTimeout(function () {
            if (modalReference.current) {
                resizeObserver.observe(modalReference.current);
                copiedReference = modalReference.current;
            }
        }, 100);
        window.addEventListener("resize", handleResize);
        return function () {
            window.removeEventListener("resize", handleResize);
            copiedReference && resizeObserver.unobserve(copiedReference);
        };
    }, [modalReference, isOpen]);
    return ___EmotionJSX(Modal, {
        closeAfterTransition: true,
        open: isOpen,
        onClose: ["profile-edit-dialog", "add-document-prompt", "new-folder-prompt"].includes(modalComponentName) ? onClose : modalProperties.disableOnClose ? always : modalProperties.onClose || onClose,
        style: {
            zIndex: 3
        }
    }, ___EmotionJSX(Fade, {
        in: isOpen
    }, ___EmotionJSX("div", {
        id: "modal-window",
        css: SS.content,
        ref: modalReference,
        style: getModalStyle(width, height)
    }, modalComponentName === "target-controls" && ___EmotionJSX(TargetControlsConfigDialog, modalProperties), modalComponentName === "share-dialog" && ___EmotionJSX(ShareDialog, modalProperties), modalComponentName === "keyboard-shortcuts" && ___EmotionJSX(KeyboardShortcuts, modalProperties), modalComponentName === "new-document-prompt" && ___EmotionJSX(NewDocumentPrompt, modalProperties), modalComponentName === "delete-document-prompt" && ___EmotionJSX(DeleteDocumentPrompt, modalProperties), modalComponentName === "add-document-prompt" && ___EmotionJSX(AddDocumentPrompt, modalProperties), modalComponentName === "new-folder-prompt" && ___EmotionJSX(NewFolderPrompt, modalProperties), modalComponentName === "close-unsaved-prompt" && ___EmotionJSX(CloseUnsavedFilePrompt, modalProperties), modalComponentName === "new-project-prompt" && ___EmotionJSX(ProjectModal, modalProperties), modalComponentName === "profile-edit-dialog" && ___EmotionJSX(ProfileModal, modalProperties), modalComponentName === "delete-project-prompt" && ___EmotionJSX(DeleteProjectModal, modalProperties), modalComponentName === "project-finalize" && ___EmotionJSX(ProfileFinalize, modalProperties))));
}
