import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "@root/store";
import { selectCurrentRoute } from "@comp/router/selectors";
import { selectIsOwner } from "@comp/project-editor/selectors";
import { selectUserImageURL, selectUserName } from "@comp/profile/selectors";
import { selectLoggedInUid } from "@comp/login/selectors";
import AppBar from "@mui/material/AppBar";
import Login from "@comp/login/login";
import * as loginActions from "@comp/login/actions";
import CSLogo from "@comp/cs-logo/cs-logo";
import { Link } from "react-router-dom";
import { Toolbar, IconButton, MenuItem, Menu, Drawer, List, ListItem, ListItemIcon, ListItemText, Divider } from "@mui/material";
import { AccountBox } from "@mui/icons-material";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import MenuIcon from "@mui/icons-material/Menu";
import HelpIcon from "@mui/icons-material/Help";
import GitHubIcon from "@mui/icons-material/GitHub";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import * as SS from "./styles";
// import { tooltipClasses } from "@comp/styles";
import { isEmpty } from "ramda";
import MenuBar from "@comp/menu-bar/menu-bar";
import ProjectProfileMeta from "./project-profile-meta";
import TargetControls from "@comp/target-controls";
import SocialControls from "@comp/social-controls/social-controls";
import { jsx as ___EmotionJSX } from "@emotion/react";
var Header = function Header() {
    var dispatch = useDispatch();
    var authenticated = useSelector(function (store) {
        return store.LoginReducer.authenticated;
    });
    var activeProjectUid = useSelector(function (store) {
        return store.ProjectsReducer.activeProjectUid;
    });
    var currentRoute = useSelector(selectCurrentRoute);
    var routeIsHome = currentRoute === "home";
    var routeIsEditor = currentRoute === "editor";
    var routeIsProfile = currentRoute === "profile";
    var isOwner = useSelector(selectIsOwner(activeProjectUid || ""));
    var loggedInUid = useSelector(selectLoggedInUid);
    var loggedInUserName = useSelector(selectUserName(loggedInUid));
    var avatarUrl = useSelector(selectUserImageURL(loggedInUid || ""));
    var isLoginDialogOpen = useSelector(function (store) {
        return store.LoginReducer.isLoginDialogOpen;
    });
    var anchorElement = useRef();
    var _useState = useState(false), _useState2 = _slicedToArray(_useState, 2), isProfileMenuOpen = _useState2[0], setIsProfileMenuOpen = _useState2[1];
    var _useState3 = useState(false), _useState4 = _slicedToArray(_useState3, 2), isDrawerOpen = _useState4[0], setIsDrawerOpen = _useState4[1];
    var handleProfileMenuOpen = function handleProfileMenuOpen() {
        setIsProfileMenuOpen(true);
    };
    var handleProfileMenuClose = function handleProfileMenuClose() {
        setIsProfileMenuOpen(false);
    };
    var logout = function logout() {
        return dispatch(loginActions.logOut());
    };
    var openLoginDialog = function openLoginDialog() {
        return dispatch(loginActions.openLoginDialog());
    };
    var avatar = isEmpty(avatarUrl) ? ___EmotionJSX(AccountBox, null) : ___EmotionJSX(Avatar, {
        src: avatarUrl || "",
        css: SS.avatar
    });
    var userMenu = function userMenu() {
        return ___EmotionJSX("div", {
            css: SS.userMenu
        }, ___EmotionJSX(IconButton, {
            "aria-owns": isProfileMenuOpen ? "menu-appbar" : undefined,
            "data-tip": "UserMenu",
            "aria-haspopup": "true",
            color: "inherit",
            onClick: handleProfileMenuOpen,
            ref: anchorElement,
            component: "button"
        }, avatar), ___EmotionJSX(Menu, {
            css: SS.menuPaper,
            anchorEl: anchorElement.current,
            anchorOrigin: {
                vertical: "top",
                horizontal: "right"
            },
            transformOrigin: {
                vertical: "top",
                horizontal: "right"
            },
            open: isProfileMenuOpen,
            onClose: handleProfileMenuClose
        }, ___EmotionJSX(Link, {
            to: "/profile/".concat(loggedInUserName),
            css: SS.menuItemLink
        }, ___EmotionJSX(MenuItem, {
            onClick: function onClick() {
                handleProfileMenuClose();
            }
        }, "View Profile")), ___EmotionJSX(MenuItem, {
            onClick: function onClick() {
                handleProfileMenuClose();
                logout();
            }
        }, "Logout")));
    };
    var loginButton = function loginButton() {
        return ___EmotionJSX(Button, {
            css: SS.loginButton,
            color: "inherit",
            onClick: function onClick() {
                setIsProfileMenuOpen(false);
                openLoginDialog();
            }
        }, "Login");
    };
    var burgerMenu = routeIsHome || routeIsProfile ? ___EmotionJSX(IconButton, {
        color: "inherit",
        "aria-label": "open drawer",
        "data-tip": "Open drawer",
        onClick: function onClick() {
            return setIsDrawerOpen(true);
        },
        edge: "start",
        css: SS.menuButton
    }, ___EmotionJSX(MenuIcon, null)) : ___EmotionJSX("div", {
        css: SS.spacer
    });
    return ___EmotionJSX(React.Fragment, null, isLoginDialogOpen && ___EmotionJSX(Login, null), ___EmotionJSX(AppBar, {
        position: "fixed",
        css: SS.headerRoot
    }, ___EmotionJSX(Toolbar, {
        disableGutters: true,
        css: SS.toolbar
    }, burgerMenu, ___EmotionJSX(CSLogo, {
        size: 38,
        interactive: true
    }), routeIsEditor && activeProjectUid && isOwner && ___EmotionJSX(MenuBar, null), routeIsEditor && !isOwner && ___EmotionJSX(ProjectProfileMeta, null), ___EmotionJSX("div", {
        style: {
            flexGrow: 1
        }
    }), ___EmotionJSX("div", {
        css: SS.headerRightSideGroup
    }, routeIsEditor && ___EmotionJSX(TargetControls, null), routeIsEditor && ___EmotionJSX(SocialControls, null)), authenticated ? userMenu() : loginButton())), ___EmotionJSX(Drawer, {
        open: isDrawerOpen,
        onClose: function onClose() {
            return setIsDrawerOpen(false);
        }
    }, ___EmotionJSX("div", {
        css: SS.drawer
    }, ___EmotionJSX("div", {
        css: SS.drawerHeader
    }, ___EmotionJSX("h2", null, "Csound Web-IDE")), ___EmotionJSX(Divider, null), ___EmotionJSX(List, null, ___EmotionJSX(ListItem, {
        button: true,
        onClick: function onClick() {
            return window.open("/documentation", "_blank");
        }
    }, ___EmotionJSX(ListItemIcon, null, ___EmotionJSX(HelpIcon, {
        css: SS.drawerIcon
    })), ___EmotionJSX(ListItemText, {
        primary: "Site Documentation"
    })), ___EmotionJSX(ListItem, {
        button: true,
        onClick: function onClick() {
            return window.open("https://github.com/csound/web-ide/issues", "_blank");
        }
    }, ___EmotionJSX(ListItemIcon, null, ___EmotionJSX(ReportProblemIcon, {
        css: SS.drawerIcon
    })), ___EmotionJSX(ListItemText, {
        primary: "Report an Issue"
    }))), ___EmotionJSX(Divider, null), ___EmotionJSX(List, null, ___EmotionJSX(ListItem, {
        button: true,
        onClick: function onClick() {
            return window.open("https://github.com/csound/web-ide", "_blank");
        }
    }, ___EmotionJSX(ListItemIcon, null, ___EmotionJSX(GitHubIcon, {
        css: SS.drawerIcon
    })), ___EmotionJSX(ListItemText, {
        primary: "Github Project"
    }))))));
};
export default Header;
