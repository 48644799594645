import _typeof from "@babel/runtime/helpers/typeof";
import { addIndex, append, assoc, concat, compose, isEmpty, isNil, keys, mergeWith, not, uniq, map, pipe, reduce } from "ramda";
import { debounce } from "throttle-debounce";
// {a: 1, b: 2} => [{key: "a", val: 1}, {key: "b", val: 2}]
export var listifyObject = function listifyObject(object) {
    return reduce(function (accumulator, k) {
        return append(pipe(assoc("key", k), assoc("val", object[k]))({}), accumulator);
    }, [], keys(object));
};
// https://stackoverflow.com/a/16016476/3714556
export function validateEmail(emailAddress) {
    var sQtext = "[^\\x0d\\x22\\x5c\\x80-\\xff]";
    var sDtext = "[^\\x0d\\x5b-\\x5d\\x80-\\xff]";
    var sAtom = "[^\\x00-\\x20\\x22\\x28\\x29\\x2c\\x2e\\x3a-\\x3c\\x3e\\x40\\x5b-\\x5d\\x7f-\\xff]+";
    var sQuotedPair = "\\x5c[\\x00-\\x7f]";
    var sDomainLiteral = "\\x5b(" + sDtext + "|" + sQuotedPair + ")*\\x5d";
    var sQuotedString = "\\x22(" + sQtext + "|" + sQuotedPair + ")*\\x22";
    var sDomainReference = sAtom;
    var sSubDomain = "(" + sDomainReference + "|" + sDomainLiteral + ")";
    var sWord = "(" + sAtom + "|" + sQuotedString + ")";
    var sDomain = sSubDomain + "(\\x2e" + sSubDomain + ")*";
    var sLocalPart = sWord + "(\\x2e" + sWord + ")*";
    var sAddrSpec = sLocalPart + "\\x40" + sDomain; // complete RFC822 email address spec
    var sValidEmail = "^" + sAddrSpec + "$"; // as whole string
    var reValidEmail = new RegExp(sValidEmail);
    return reValidEmail.test(emailAddress);
}
var dirtyWindow = window;
export var isElectron = dirtyWindow.process !== undefined && dirtyWindow.process.versions !== undefined && dirtyWindow.process.versions["electron"] !== undefined;
export function filterUndef(ts) {
    return ts.filter(function (t) {
        return !!t;
    });
}
export var mapIndexed = addIndex(map);
export var notEmpty = compose(not, isEmpty);
export function isMacintosh() {
    return navigator.platform.includes("Mac");
}
export var isMac = isMacintosh();
export var formatFileSize = function formatFileSize(filesize) {
    var megabyte = Math.pow(10, 6);
    var kilobyte = Math.pow(10, 3);
    if (filesize > megabyte) {
        return (filesize / megabyte).toFixed(2) + " MB";
    }
    else if (filesize > kilobyte) {
        return (filesize / kilobyte).toFixed(2) + " KB";
    }
    return filesize + " B";
};
export var deepMerge = function deepMerge(v1, v2) {
    if (Array.isArray(v1) && Array.isArray(v2)) {
        return uniq(concat(v1, v2));
    }
    else if (_typeof(v1) === "object" && _typeof(v2) === "object" && !isNil(v1) && !isNil(v2)) {
        return mergeWith(deepMerge, v1, v2);
    }
    else {
        return v2;
    }
};
export var updateBodyScroller = function updateBodyScroller(debounceTime) {
    return debounce(debounceTime, function () {
        var maybeElement = window.ps_body;
        if (maybeElement !== undefined && typeof maybeElement.update === "function") {
            maybeElement.update();
        }
    });
};
export var isMobile = function isMobile() {
    return /android|webos|iphone|ipad|ipod|opera mini/i.test(navigator.userAgent);
};
export var isIOS = function isIOS() {
    return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
};
