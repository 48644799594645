export var closeModal = function closeModal() {
    return {
        type: "MODAL_CLOSE"
    };
};
export var openSimpleModal = function openSimpleModal(modalComponentName, properties) {
    return {
        type: "MODAL_OPEN_SIMPLE",
        modalComponentName: modalComponentName,
        properties: properties
    };
};
