function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
export var starItemContainer = process.env.NODE_ENV === "production" ? {
    name: "e0mbj1",
    styles: "&>div{min-height:unset;display:flex;}"
} : {
    name: "h3lanh-starItemContainer",
    styles: "&>div{min-height:unset;display:flex;};label:starItemContainer;",
    map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9ob21lL3J1bm5lci93b3JrL3dlYi1pZGUvd2ViLWlkZS9zcmMvY29tcG9uZW50cy9wcm9maWxlL3RhYnMvc3R5bGVzLnRzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUVvQyIsImZpbGUiOiIvaG9tZS9ydW5uZXIvd29yay93ZWItaWRlL3dlYi1pZGUvc3JjL2NvbXBvbmVudHMvcHJvZmlsZS90YWJzL3N0eWxlcy50cyIsInNvdXJjZXNDb250ZW50IjpbImltcG9ydCB7IGNzcyB9IGZyb20gXCJAZW1vdGlvbi9yZWFjdFwiO1xuXG5leHBvcnQgY29uc3Qgc3Rhckl0ZW1Db250YWluZXIgPSBjc3NgXG4gICAgJiA+IGRpdiB7XG4gICAgICAgIG1pbi1oZWlnaHQ6IHVuc2V0O1xuICAgICAgICBkaXNwbGF5OiBmbGV4O1xuICAgIH1cbmA7XG5cbmV4cG9ydCBjb25zdCBzdGFySXRlbUljb24gPSBjc3NgXG4gICAgd2lkdGg6IDUycHg7XG4gICAgZGlzcGxheTogZmxleDtcbiAgICBhbGlnbi1pdGVtczogY2VudGVyO1xuICAgIGFsaWduLXNlbGY6IGNlbnRlcjtcbiAgICBtYXJnaW4tcmlnaHQ6IDI0cHg7XG4gICAgJiA+IGRpdiB7XG4gICAgICAgIGJveC1zaXppbmc6IGNvbnRlbnQtYm94O1xuICAgICAgICBoZWlnaHQ6IDM0cHg7XG4gICAgICAgIHdpZHRoOiAzNHB4O1xuICAgICAgICBwYWRkaW5nOiA1cHg7XG4gICAgICAgIG1hcmdpbjogMCA5cHg7XG4gICAgICAgIGN1cnNvcjogZGVmYXVsdDtcbiAgICAgICAgYm9yZGVyLXJhZGl1czogNTAlO1xuICAgICAgICAmIHN2ZyB7XG4gICAgICAgICAgICBoZWlnaHQ6IDIwcHggIWltcG9ydGFudDtcbiAgICAgICAgICAgIHdpZHRoOiAyMHB4ICFpbXBvcnRhbnQ7XG4gICAgICAgICAgICBtYXJnaW4tdG9wOiAxcHg7XG4gICAgICAgICAgICBtYXJnaW4tbGVmdDogMXB4O1xuICAgICAgICB9XG4gICAgfVxuYDtcbiJdfQ== */",
    toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
export var starItemIcon = process.env.NODE_ENV === "production" ? {
    name: "1l8lk3",
    styles: "width:52px;display:flex;align-items:center;align-self:center;margin-right:24px;&>div{box-sizing:content-box;height:34px;width:34px;padding:5px;margin:0 9px;cursor:default;border-radius:50%;& svg{height:20px!important;width:20px!important;margin-top:1px;margin-left:1px;}}"
} : {
    name: "1m5c0w9-starItemIcon",
    styles: "width:52px;display:flex;align-items:center;align-self:center;margin-right:24px;&>div{box-sizing:content-box;height:34px;width:34px;padding:5px;margin:0 9px;cursor:default;border-radius:50%;& svg{height:20px!important;width:20px!important;margin-top:1px;margin-left:1px;}};label:starItemIcon;",
    map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9ob21lL3J1bm5lci93b3JrL3dlYi1pZGUvd2ViLWlkZS9zcmMvY29tcG9uZW50cy9wcm9maWxlL3RhYnMvc3R5bGVzLnRzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQVMrQiIsImZpbGUiOiIvaG9tZS9ydW5uZXIvd29yay93ZWItaWRlL3dlYi1pZGUvc3JjL2NvbXBvbmVudHMvcHJvZmlsZS90YWJzL3N0eWxlcy50cyIsInNvdXJjZXNDb250ZW50IjpbImltcG9ydCB7IGNzcyB9IGZyb20gXCJAZW1vdGlvbi9yZWFjdFwiO1xuXG5leHBvcnQgY29uc3Qgc3Rhckl0ZW1Db250YWluZXIgPSBjc3NgXG4gICAgJiA+IGRpdiB7XG4gICAgICAgIG1pbi1oZWlnaHQ6IHVuc2V0O1xuICAgICAgICBkaXNwbGF5OiBmbGV4O1xuICAgIH1cbmA7XG5cbmV4cG9ydCBjb25zdCBzdGFySXRlbUljb24gPSBjc3NgXG4gICAgd2lkdGg6IDUycHg7XG4gICAgZGlzcGxheTogZmxleDtcbiAgICBhbGlnbi1pdGVtczogY2VudGVyO1xuICAgIGFsaWduLXNlbGY6IGNlbnRlcjtcbiAgICBtYXJnaW4tcmlnaHQ6IDI0cHg7XG4gICAgJiA+IGRpdiB7XG4gICAgICAgIGJveC1zaXppbmc6IGNvbnRlbnQtYm94O1xuICAgICAgICBoZWlnaHQ6IDM0cHg7XG4gICAgICAgIHdpZHRoOiAzNHB4O1xuICAgICAgICBwYWRkaW5nOiA1cHg7XG4gICAgICAgIG1hcmdpbjogMCA5cHg7XG4gICAgICAgIGN1cnNvcjogZGVmYXVsdDtcbiAgICAgICAgYm9yZGVyLXJhZGl1czogNTAlO1xuICAgICAgICAmIHN2ZyB7XG4gICAgICAgICAgICBoZWlnaHQ6IDIwcHggIWltcG9ydGFudDtcbiAgICAgICAgICAgIHdpZHRoOiAyMHB4ICFpbXBvcnRhbnQ7XG4gICAgICAgICAgICBtYXJnaW4tdG9wOiAxcHg7XG4gICAgICAgICAgICBtYXJnaW4tbGVmdDogMXB4O1xuICAgICAgICB9XG4gICAgfVxuYDtcbiJdfQ== */",
    toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
