import { curry, find, path, propEq } from "ramda";
export var selectProjectTargets = curry(function (activeProjectUid, store) {
    if (activeProjectUid) {
        return path(["TargetControlsReducer", activeProjectUid, "targets"], store);
    }
});
export var selectSelectedTarget = curry(function (activeProjectUid, store) {
    return activeProjectUid && path(["TargetControlsReducer", activeProjectUid, "selectedTarget"], store);
});
// with fallback to project.csd
export var getSelectedTargetDocumentUid = curry(function (activeProjectUid, store) {
    if (activeProjectUid) {
        var targetName = path(["TargetControlsReducer", activeProjectUid, "selectedTarget"], store);
        var documentUid = targetName && path(["TargetControlsReducer", activeProjectUid, "targets", targetName, "targetDocumentUid"], store);
        if (documentUid) {
            return documentUid;
        }
        else {
            var allDocuments = path(["ProjectsReducer", "projects", activeProjectUid, "documents"], store);
            var maybeProjectCsd = find(propEq("filename", "default.csd"), Object.values(allDocuments));
            if (maybeProjectCsd) {
                return maybeProjectCsd.documentUid;
            }
        }
    }
});
export var selectProjectDocuments = curry(function (activeProjectUid, store) {
    return activeProjectUid && path(["ProjectsReducer", "projects", activeProjectUid, "documents"], store);
});
export var selectDefaultTargetName = curry(function (activeProjectUid, store) {
    return activeProjectUid && path(["TargetControlsReducer", activeProjectUid, "defaultTarget"], store);
});
export var selectTarget = curry(function (activeProjectUid, targetName, store) {
    return activeProjectUid && path(["TargetControlsReducer", activeProjectUid, "targets", targetName], store);
});
