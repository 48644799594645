import Select from "react-select";
import { ascend, either, filter, equals, map, pipe, prop, sort } from "ramda";
import { useTheme } from "@emotion/react";
import DeleteIcon from "@mui/icons-material/DeleteTwoTone";
import Radio from "@mui/material/Radio";
import Checkbox from "@mui/material/Checkbox";
import Fab from "@mui/material/Fab";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import { filenameToCsoundType } from "@comp/csound/utils";
import { validateTargetName } from "./utils";
import { reactSelectDropdownStyle } from "../styles";
import * as SS from "./styles";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var TargetControlsConfigDialogSingleTarget = function TargetControlsConfigDialogSingleTarget(_ref) {
    var targetIndex = _ref.targetIndex, targetName = _ref.targetName, oldTargetName = _ref.oldTargetName, targetDocumentUid = _ref.targetDocumentUid, isDefaultTarget = _ref.isDefaultTarget, useCsound7 = _ref.useCsound7, handleSelectTargetDocument = _ref.handleSelectTargetDocument, handleTargetNameChange = _ref.handleTargetNameChange, handleTargetDelete = _ref.handleTargetDelete, handleMarkAsDefaultTarget = _ref.handleMarkAsDefaultTarget, handleEnableCsound7 = _ref.handleEnableCsound7, newTargets = _ref.newTargets, allDocuments = _ref.allDocuments;
    var theme = useTheme();
    var targetNameIsValid = validateTargetName({
        targetName: targetName,
        oldTargetName: oldTargetName,
        newTargets: newTargets
    });
    var targetDocument = allDocuments.find(function (doc) {
        return doc.documentUid === targetDocumentUid;
    });
    return ___EmotionJSX("div", {
        css: SS.targetsDialogMain,
        style: {
            borderColor: targetNameIsValid ? "inherit" : theme.errorText
        }
    }, ___EmotionJSX(FormGroup, {
        style: {
            marginBottom: "24px"
        },
        row: true
    }, ___EmotionJSX(Tooltip, {
        title: "Default target is what will be picked when a guest plays your project",
        placement: "right-end"
    }, ___EmotionJSX(FormControlLabel, {
        control: ___EmotionJSX(Radio, {
            color: "primary",
            checked: isDefaultTarget,
            onChange: function onChange() {
                return !isDefaultTarget && handleMarkAsDefaultTarget(targetName);
            }
        }),
        label: "Mark as default target"
    })), ___EmotionJSX(Tooltip, {
        title: "delete target",
        placement: "right-end"
    }, ___EmotionJSX("span", null, ___EmotionJSX(Fab, {
        onClick: function onClick() {
            return handleTargetDelete(targetName);
        },
        css: SS.closeIcon,
        color: "secondary",
        variant: "circular",
        size: "small",
        disabled: newTargets.length === 1
    }, ___EmotionJSX(DeleteIcon, {
        style: newTargets.length > 1 ? {
            fill: "white"
        } : {}
    }))))), ___EmotionJSX(FormGroup, {
        row: true
    }, ___EmotionJSX(TextField, {
        label: "target name",
        value: targetName,
        error: !targetNameIsValid,
        onChange: function onChange(event) {
            return handleTargetNameChange({
                oldTargetName: oldTargetName,
                nextValue: event.target.value,
                targetIndex: targetIndex
            });
        }
    })), ___EmotionJSX(FormGroup, {
        style: {
            marginTop: 24
        },
        row: true
    }, ___EmotionJSX("div", null, ___EmotionJSX("p", {
        css: SS.targetLabel
    }, "selected csound document to play"), ___EmotionJSX(Select, {
        value: targetDocumentUid || "",
        onChange: function onChange(_ref2) {
            var value = _ref2.value;
            return typeof value === "string" && handleSelectTargetDocument({
                targetIndex: targetIndex,
                nextTargetDocumentUid: value
            });
        },
        options: pipe(filter(either(function (_ref3) {
            var filename = _ref3.filename;
            return equals("csd", filenameToCsoundType(filename));
        }, function (_ref4) {
            var filename = _ref4.filename;
            return equals("orc", filenameToCsoundType(filename));
        })), sort(ascend(prop("filename"))), map(function (document_) {
            return {
                label: document_.filename,
                value: document_.documentUid
            };
        }))(allDocuments),
        isSearchable: false,
        closeMenuOnSelect: true,
        placeholder: targetDocument ? targetDocument.filename : "Select main document",
        styles: reactSelectDropdownStyle(theme)
    }))), ___EmotionJSX(FormGroup, {
        row: true
    }, ___EmotionJSX(FormControlLabel, {
        control: ___EmotionJSX(Checkbox, {
            color: "primary",
            checked: useCsound7 || false,
            onChange: function onChange(event) {
                return handleEnableCsound7({
                    targetIndex: targetIndex,
                    enableCsound7: event.target.checked
                });
            }
        }),
        label: "Run with csound7-alpha"
    })));
};
