import defaultBindings from "./default-bindings";
import { assoc } from "ramda";
import { UPDATE_COUNTER } from "./types";
var INITIAL_STATE = {
    updateCounter: 0,
    bindings: defaultBindings
};
var HotKeysReducer = function HotKeysReducer(state, action) {
    switch (action.type) {
        case UPDATE_COUNTER:
            {
                return assoc("updateCounter", state.updateCounter + 1, state);
            }
        default:
            {
                return state || INITIAL_STATE;
            }
    }
};
export default HotKeysReducer;
