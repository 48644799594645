// import { IStore } from "@store/types";
import { findIndex } from "lodash";
import { MANUAL_LOOKUP_STRING, TAB_DOCK_INIT, TAB_DOCK_REARRANGE_TABS, TAB_DOCK_SWITCH_TAB, TAB_DOCK_OPEN_NON_CLOUD_FILE, TAB_DOCK_OPEN_TAB_BY_DOCUMENT_UID, TAB_DOCK_CLOSE, TAB_CLOSE, TOGGLE_MANUAL_PANEL, SET_MANUAL_PANEL_OPEN, SET_FILE_TREE_PANEL_OPEN } from "./types";
import { assoc, assocPath, append, curry, filter, lensPath, map, over, pathOr, pipe, prop } from "ramda";
import { nonCloudFiles } from "../file-tree/actions";
var initialLayoutState = function initialLayoutState() {
    return {
        tabDock: {
            tabIndex: -1,
            openDocuments: []
        },
        fileTreeVisible: true,
        manualVisible: false,
        manualLookupString: ""
    };
};
var addTabToOpenDocuments = curry(function (tab, state) {
    return over(lensPath(["tabDock", "openDocuments"]), append(tab), state);
});
var storeTabDockState = function storeTabDockState(projectUid, openDocuments, tabIndex) {
    try {
        var tabOrder = map(prop("uid"), openDocuments);
        var tabOrderString = JSON.stringify(tabOrder);
        localStorage.setItem("".concat(projectUid, ":tabOrder"), tabOrderString);
        tabIndex && localStorage.setItem("".concat(projectUid, ":tabIndex"), "".concat(tabIndex));
    }
    catch (error) {
        console.error(error);
    }
};
var ProjectEditorReducer = function ProjectEditorReducer(state, action) {
    switch (action.type) {
        case MANUAL_LOOKUP_STRING:
            {
                return pipe(assoc("manualLookupString", action.manualLookupString), assoc("manualVisible", true))(state);
            }
        case TAB_DOCK_CLOSE:
            {
                return initialLayoutState();
            }
        case TAB_DOCK_INIT:
            {
                return pipe(assocPath(["tabDock", "tabIndex"], action.initialIndex), assocPath(["tabDock", "openDocuments"], action.initialOpenDocuments))(state);
            }
        case TAB_DOCK_SWITCH_TAB:
            {
                return assocPath(["tabDock", "tabIndex"], action.tabIndex, state);
            }
        case TAB_DOCK_REARRANGE_TABS:
            {
                return pipe(assocPath(["tabDock", "tabIndex"], action.newActiveIndex), assocPath(["tabDock", "openDocuments"], action.modifiedDock))(state);
            }
        case TAB_DOCK_OPEN_NON_CLOUD_FILE:
            {
                if (action.init) {
                    return state;
                }
                var currentOpenDocuments = pathOr([], ["tabDock", "openDocuments"], state);
                var documentAlreadyOpenIndex = findIndex(currentOpenDocuments, function (od) {
                    return od.isNonCloudDocument ? od.uid === (action === null || action === void 0 ? void 0 : action.filename) : false;
                });
                var file = nonCloudFiles.get(action === null || action === void 0 ? void 0 : action.filename);
                if (file && documentAlreadyOpenIndex < 0) {
                    var nonCloudFileAudioUrl = undefined;
                    var nonCloudFileData = undefined;
                    if (action.mimeType.startsWith("audio")) {
                        var blob = new Blob([file.buffer], {
                            type: action.mimeType
                        });
                        nonCloudFileAudioUrl = URL.createObjectURL(blob);
                    }
                    else {
                        var utf8decoder = new TextDecoder();
                        nonCloudFileData = utf8decoder.decode(file.buffer);
                    }
                    var newAppendedState = addTabToOpenDocuments({
                        uid: file.name,
                        isNonCloudDocument: true,
                        nonCloudFileAudioUrl: nonCloudFileAudioUrl,
                        nonCloudFileData: nonCloudFileData,
                        editorInstance: undefined
                    }, state);
                    // Focus on open action (can be made configureable)
                    var newState = assocPath(["tabDock", "tabIndex"], newAppendedState.tabDock.openDocuments.length - 1, newAppendedState);
                    storeTabDockState(action.projectUid, newState.tabDock.openDocuments, newState.tabDock.tabIndex);
                    return newState;
                }
                else {
                    return assocPath(["tabDock", "tabIndex"], documentAlreadyOpenIndex, state);
                }
            }
        case TAB_DOCK_OPEN_TAB_BY_DOCUMENT_UID:
            {
                var _currentOpenDocuments = pathOr([], ["tabDock", "openDocuments"], state);
                var _documentAlreadyOpenIndex = findIndex(_currentOpenDocuments, function (od) {
                    return od.uid === action.documentUid;
                });
                if (_documentAlreadyOpenIndex < 0 || action.init) {
                    var _newAppendedState = addTabToOpenDocuments({
                        uid: action.documentUid,
                        editorInstance: undefined
                    }, state);
                    // Focus on open action (can be made configureable)
                    var _newState = assocPath(["tabDock", "tabIndex"], _newAppendedState.tabDock.openDocuments.length - 1, _newAppendedState);
                    return _newState;
                }
                else {
                    return assocPath(["tabDock", "tabIndex"], _documentAlreadyOpenIndex, state);
                }
            }
        case TAB_CLOSE:
            {
                if (!state.tabDock.openDocuments.some(function (od) {
                    return od.uid === action.documentUid;
                })) {
                    // dont attempt to close a tab that isn't open
                    return state;
                }
                var currentTabIndex = state.tabDock.tabIndex;
                var _newState2 = pipe(assocPath(["tabDock", "tabIndex"], Math.min(currentTabIndex, pathOr(0, ["tabDock", "openDocuments", "length"], state) - 2)), assocPath(["tabDock", "openDocuments"], filter(function (od) {
                    return od.uid !== action.documentUid;
                }, pathOr([], ["tabDock", "openDocuments"], state))))(state);
                storeTabDockState(action.projectUid, _newState2.tabDock.openDocuments, _newState2.tabDock.tabIndex);
                return _newState2;
            }
        case TOGGLE_MANUAL_PANEL:
            {
                return pipe(assoc("manualLookupString", ""), assoc("manualVisible", !state.manualVisible))(state);
            }
        case SET_MANUAL_PANEL_OPEN:
            {
                return pipe(assoc("manualLookupString", ""), assoc("manualVisible", action.open))(state);
            }
        case SET_FILE_TREE_PANEL_OPEN:
            {
                return assoc("fileTreeVisible", action.open, state);
            }
        default:
            {
                return state || initialLayoutState();
            }
    }
};
export default ProjectEditorReducer;
