import React from "react";
import { StyledListItemTopRowText, StyledUserListItemContainer } from "../profile-ui";
import ProjectAvatar from "@elem/project-avatar";
import { selectProfileStars } from "../selectors";
import { ListItem, ListItemText } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import { isEmpty, prop } from "ramda";
import * as SS from "./styles";
import { jsx as ___EmotionJSX } from "@emotion/react";
var StarsList = function StarsList(_ref) {
    var profileUid = _ref.profileUid;
    var dispatch = useDispatch();
    var profileStars = useSelector(selectProfileStars(profileUid));
    var cachedProjects = useSelector(function (store) {
        return store.ProjectsReducer.projects;
    });
    return isEmpty(profileStars) ? ___EmotionJSX(React.Fragment, null) : profileStars.map(function (projectUid, index) {
        var project = prop(projectUid, cachedProjects);
        return ___EmotionJSX(ListItem, {
            button: true,
            alignItems: "flex-start",
            css: SS.starItemContainer,
            key: index,
            onClick: function onClick() {
                dispatch(push("/editor/".concat(projectUid)));
            }
        }, ___EmotionJSX(StyledUserListItemContainer, null, ___EmotionJSX("div", {
            css: SS.starItemIcon
        }, project && ___EmotionJSX(ProjectAvatar, {
            project: project
        })), ___EmotionJSX(StyledListItemTopRowText, null, ___EmotionJSX(ListItemText, {
            primary: project ? project.name : "",
            secondary: project ? project.description : ""
        }))));
    });
};
export default StarsList;
