/* eslint-disable unicorn/consistent-function-scoping */
import { curry, find, path, pathOr, propEq, values } from "ramda";
import { playORCFromString, playCsdFromFs } from "@comp/csound/actions";
import { filenameToCsoundType } from "@comp/csound/utils";
var getDefaultTargetName = function getDefaultTargetName(store, projectUid) {
    return path(["TargetControlsReducer", projectUid, "defaultTarget"], store);
};
export var getDefaultTargetDocument = curry(function (projectUid, store) {
    var maybeDefaultTarget = path(["TargetControlsReducer", projectUid, "targets", getDefaultTargetName(store, projectUid) || ""], store);
    var projectCsdFallback = find(propEq("filename", "project.csd"), values(pathOr({}, ["ProjectsReducer", "projects", projectUid, "documents"], store)));
    // ATT: fallback to project.csd is to prevserve fallback behaviour
    // This should be marked as a deprecated fallback, soonish
    var targetDocument = maybeDefaultTarget ? path(["ProjectsReducer", "projects", projectUid, "documents", maybeDefaultTarget.targetType === "main" ? maybeDefaultTarget && maybeDefaultTarget.targetDocumentUid : maybeDefaultTarget && pathOr("", ["playlistDocumentsUid", 0], maybeDefaultTarget)], store) : projectCsdFallback;
    return targetDocument;
});
export var getPlayActionFromProject = curry(function (projectUid, store) {
    var _target$useCsound;
    var targetDocument = getDefaultTargetDocument(projectUid, store);
    if (!targetDocument) {
        return;
    }
    var target = path(["TargetControlsReducer", projectUid, "targets", targetDocument.documentUid], store);
    var useCsound7 = (_target$useCsound = target === null || target === void 0 ? void 0 : target.useCsound7) !== null && _target$useCsound !== void 0 ? _target$useCsound : false;
    var csoundDocumentType = filenameToCsoundType(targetDocument.filename);
    switch (csoundDocumentType) {
        case "csd":
            {
                return playCsdFromFs({
                    projectUid: projectUid,
                    csdPath: targetDocument.filename,
                    useCsound7: useCsound7
                });
            }
        case "orc":
            {
                return playORCFromString({
                    projectUid: projectUid,
                    orc: targetDocument.savedValue,
                    useCsound7: useCsound7
                });
            }
    }
});
export var getPlayActionFromTarget = function getPlayActionFromTarget(projectUid) {
    return function (store) {
        var selectedTarget = path(["TargetControlsReducer", projectUid, "selectedTarget"], store);
        var selectedTargetPlaylistIndex = pathOr(-1, ["TargetControlsReducer", projectUid, "selectedTargetPlaylistIndex"], store);
        var target = path(["TargetControlsReducer", projectUid, "targets", selectedTarget], store);
        if (!target) {
            return;
        }
        var useCsound7 = target.useCsound7 || false;
        var targetDocument = target && path(["ProjectsReducer", "projects", projectUid, "documents", target && target.targetType === "main" ? target && target.targetDocumentUid : target && pathOr("", ["playlistDocumentsUid", selectedTargetPlaylistIndex], target)], store);
        if (targetDocument && targetDocument.filename) {
            var csoundDocumentType = filenameToCsoundType(targetDocument.filename);
            switch (csoundDocumentType) {
                case "csd":
                    {
                        return playCsdFromFs({
                            projectUid: projectUid,
                            csdPath: targetDocument.filename,
                            useCsound7: useCsound7
                        });
                    }
                case "orc":
                    {
                        return playORCFromString({
                            projectUid: projectUid,
                            orc: targetDocument.savedValue,
                            useCsound7: useCsound7
                        });
                    }
            }
        }
    };
};
