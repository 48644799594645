import { useCallback, useEffect, useRef } from "react";
import { useConsole } from "./context";
import * as SS from "./styles";
import { jsx as ___EmotionJSX } from "@emotion/react";
var Console = function Console() {
    var logs = useConsole();
    var consoleReference = useRef();
    var onMessage = useCallback(function () {
        if (consoleReference && consoleReference.current) {
            var _consoleReference$cur = consoleReference.current, _consoleReference$cur2 = _consoleReference$cur.clientHeight, clientHeight = _consoleReference$cur2 === void 0 ? 0 : _consoleReference$cur2, _consoleReference$cur3 = _consoleReference$cur.scrollHeight, scrollHeight = _consoleReference$cur3 === void 0 ? 0 : _consoleReference$cur3, _consoleReference$cur4 = _consoleReference$cur.scrollTop, scrollTop = _consoleReference$cur4 === void 0 ? 0 : _consoleReference$cur4;
            if (scrollTop + clientHeight < scrollHeight) {
                consoleReference.current.scrollTop = scrollHeight;
            }
        }
    }, [consoleReference]);
    useEffect(function () {
        onMessage();
    }, [logs, onMessage]);
    return ___EmotionJSX("div", {
        css: SS.ConsoleContainer,
        ref: consoleReference
    }, ___EmotionJSX("code", null, (logs || []).join("")));
};
export default Console;
