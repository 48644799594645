import _typeof from "@babel/runtime/helpers/typeof";
import { doc, onSnapshot } from "firebase/firestore";
import { UPDATE_USER_PROFILE } from "./types";
import { profiles } from "@config/firestore";
export var subscribeToLoggedInUserProfile = function subscribeToLoggedInUserProfile(userUid, dispatch) {
    var unsubscribe = onSnapshot(doc(profiles, userUid), function (profile) {
        var profileData = profile.data();
        if (_typeof(profileData.userJoinDate) === "object") {
            profileData.userJoinDate = profileData.userJoinDate.toMillis();
        }
        dispatch({
            type: UPDATE_USER_PROFILE,
            profile: profileData,
            userUid: userUid
        });
    }, function (error) {
        return console.error(error);
    });
    return unsubscribe;
};
