import { SET_SELECTED_TARGET, UPDATE_ALL_TARGETS_LOCALLY, UPDATE_DEFAULT_TARGET_LOCALLY, UPDATE_TARGET_LOCALLY } from "./types";
import { assocPath, pipe, prop, when } from "ramda";
var INITIAL_STATE = {};
var TargetControlsReducer = function TargetControlsReducer(state, action) {
    switch (action.type) {
        case SET_SELECTED_TARGET:
            {
                return assocPath([action.projectUid, "selectedTarget"], prop("selectedTarget", action), state);
            }
        case UPDATE_ALL_TARGETS_LOCALLY:
            {
                return pipe(assocPath([action.projectUid, "targets"], action.targets), assocPath([action.projectUid, "defaultTarget"], action.defaultTarget), when(function (s) {
                    return typeof action.defaultTarget === "string" && typeof s.selectedTarget !== "string";
                }, assocPath([action.projectUid, "selectedTarget"], action.defaultTarget)))(state);
            }
        case UPDATE_TARGET_LOCALLY:
            {
                return assocPath([action.projectUid, "targets", action.targetName], action.target, state);
            }
        case UPDATE_DEFAULT_TARGET_LOCALLY:
            {
                return assocPath([action.projectUid, "defaultTarget"], action.defaultTarget, state);
            }
        default:
            {
                return state || INITIAL_STATE;
            }
    }
};
export default TargetControlsReducer;
