import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "@root/store";
import Header from "@comp/header/header";
import Search from "./search";
import PopularProjects from "./popular-projects";
import RandomProjects from "./random-projects";
import { homeBackground } from "./background-style";
import { fetchPopularProjects } from "./actions";
import { selectPopularProjectsFetchOffset, selectPopularProjectsSlice } from "./selectors";
import { jsx as ___EmotionJSX } from "@emotion/react";
var Home = function Home() {
    var dispatch = useDispatch();
    var _useSelector = useSelector(selectPopularProjectsFetchOffset), _useSelector2 = _slicedToArray(_useSelector, 2), popularProjectsFetchOffset = _useSelector2[0], popularProjectsTotalRecords = _useSelector2[1];
    var _useState = useState(0), _useState2 = _slicedToArray(_useState, 2), currentPopularProjectsOffset = _useState2[0], setCurrentPopularProjectsOffset = _useState2[1];
    var currentPopularProjectsPagination = useSelector(selectPopularProjectsSlice(popularProjectsFetchOffset < 0 ? 0 : currentPopularProjectsOffset, popularProjectsFetchOffset < 0 ? -1 : currentPopularProjectsOffset + 8));
    var handlePopularProjectsNextPage = useCallback(function () {
        if (popularProjectsTotalRecords > 0 && currentPopularProjectsOffset < popularProjectsTotalRecords) {
            var popularProjects;
            try {
                popularProjects = fetchPopularProjects(currentPopularProjectsOffset);
            }
            catch (error) {
                console.error(error);
            }
            if (popularProjects) {
                dispatch(popularProjects);
                setCurrentPopularProjectsOffset(currentPopularProjectsOffset + 8);
            }
        }
    }, [dispatch, popularProjectsTotalRecords, currentPopularProjectsOffset]);
    var handlePopularProjectsPreviousPage = useCallback(function () {
        if (popularProjectsTotalRecords > 0 && currentPopularProjectsOffset > 0) {
            dispatch(fetchPopularProjects(currentPopularProjectsOffset));
            setCurrentPopularProjectsOffset(currentPopularProjectsOffset - 8);
        }
    }, [dispatch, popularProjectsTotalRecords, currentPopularProjectsOffset]);
    useEffect(function () {
        if (popularProjectsFetchOffset < 0) {
            dispatch(fetchPopularProjects());
        }
    }, [dispatch, popularProjectsFetchOffset]);
    useEffect(function () {
        // start at top on init
        window.scrollTo(0, 0);
        var rootElement = document.querySelector("#root");
        rootElement && rootElement.scrollTo(0, 0);
        document.title = "Csound Web-IDE";
    }, []);
    return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(Header, null), ___EmotionJSX("div", {
        css: homeBackground
    }, ___EmotionJSX(Search, null), ___EmotionJSX(PopularProjects, {
        projects: currentPopularProjectsPagination || [],
        handlePopularProjectsNextPage: handlePopularProjectsNextPage,
        handlePopularProjectsPreviousPage: handlePopularProjectsPreviousPage,
        hasNext: popularProjectsTotalRecords > 0 && currentPopularProjectsOffset < popularProjectsTotalRecords,
        hasPrevious: currentPopularProjectsOffset > 0
    }), ___EmotionJSX(RandomProjects, null)));
};
export default Home;
