import _typeof from "@babel/runtime/helpers/typeof";
import { getAuth } from "firebase/auth";
import { curry, equals, path, pathOr } from "ramda";
export var selectIsOwner = curry(function (projectUid, store) {
    var currentUser = getAuth().currentUser;
    if (_typeof(currentUser) !== "object") {
        return false;
    }
    var owner = pathOr("", ["ProjectsReducer", "projects", projectUid, "userUid"], store);
    return equals(owner, currentUser && currentUser.uid || -1);
});
export var selectTabDockIndex = function selectTabDockIndex(store) {
    return pathOr(-1, ["ProjectEditorReducer", "tabDock", "tabIndex"], store);
};
export var selectCurrentTab = function selectCurrentTab(store) {
    var tabIndex = selectTabDockIndex(store);
    if (tabIndex > -1) {
        return path(["ProjectEditorReducer", "tabDock", "openDocuments", tabIndex], store);
    }
};
export var selectCurrentTabDocumentUid = function selectCurrentTabDocumentUid(store) {
    var tabIndex = selectTabDockIndex(store);
    if (tabIndex > -1) {
        return path(["ProjectEditorReducer", "tabDock", "openDocuments", tabIndex, "uid"], store);
    }
};
