import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _typeof from "@babel/runtime/helpers/typeof";
import React, { useState } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "@root/store";
import { useTheme } from "@emotion/react";
import Tooltip from "@mui/material/Tooltip";
import { setSelectedTarget, showTargetsConfigDialog } from "./actions";
import { selectProjectDocuments, selectProjectTargets, selectSelectedTarget } from "./selectors";
import { selectIsOwner } from "@comp/project-editor/selectors";
import { append, allPass, concat, filter, isNil, has, values } from "ramda";
import { isEmpty, reduce } from "lodash";
import * as SS from "./styles";
import { jsx as ___EmotionJSX } from "@emotion/react";
var paranoidNotNullChecker = function paranoidNotNullChecker(item) {
    return allPass([function (item_) {
            return item_ !== undefined;
        }, function (item_) {
            return "".concat(item_) !== "undefined";
        }, function (item_) {
            return !isNil(item_);
        }, function (item_) {
            return item_ !== null;
        }])(item);
};
var titleTooltip = function titleTooltip(_ref) {
    var documents = _ref.documents, selectedTarget = _ref.selectedTarget;
    var mainDocument = _typeof(selectedTarget) === "object" && has("targetDocumentUid", selectedTarget) && documents[(selectedTarget || {}).targetDocumentUid];
    return mainDocument && selectedTarget.targetType === "main" ? "main: ".concat(mainDocument.filename) : "No document found for selected target: ".concat(selectedTarget.targetName);
};
var TargetDropdown = function TargetDropdown(_ref2) {
    var activeProjectUid = _ref2.activeProjectUid;
    var dispatch = useDispatch();
    var theme = useTheme();
    var targets = useSelector(selectProjectTargets(activeProjectUid));
    var documents = useSelector(selectProjectDocuments(activeProjectUid));
    var selectedTargetName = useSelector(selectSelectedTarget(activeProjectUid));
    var selectedTarget;
    if (targets && selectedTargetName && targets[selectedTargetName]) {
        selectedTarget = targets[selectedTargetName];
    }
    var isOwner = useSelector(selectIsOwner(activeProjectUid));
    var mainTargets = targets ? filter(has("targetDocumentUid"), values(targets)) : [];
    var playlistTargets = targets ? filter(has("playlistDocumentsUid"), values(targets)) : [];
    var mainDropdownOptions = isEmpty(mainTargets) ? [] : reduce(mainTargets, function (accumulator, target) {
        return append({
            value: target.targetName,
            label: target.targetName
        }, accumulator);
    }, []);
    var playlistDropdownOptions = isEmpty(playlistTargets) ? [] : reduce(playlistTargets, function (accumulator, target) {
        return append({
            value: target.targetName,
            label: target.targetName
        }, accumulator);
    }, []);
    var options = concat([{
            label: "Playlist",
            options: playlistDropdownOptions
        }, {
            label: "Targets",
            options: mainDropdownOptions
        }], isOwner ? [{
            label: "Configure",
            value: "___toggle-configure"
        }] : []);
    var _useState = useState(false), _useState2 = _slicedToArray(_useState, 2), menuIsOpen = _useState2[0], setMenuIsOpen = _useState2[1];
    var _useState3 = useState(false), _useState4 = _slicedToArray(_useState3, 2), tooltipIsOpen = _useState4[0], setTooltipIsOpen = _useState4[1];
    if (!activeProjectUid) {
        return ___EmotionJSX(React.Fragment, null);
    }
    var tooltipText = paranoidNotNullChecker(selectedTarget) ? titleTooltip({
        documents: documents,
        selectedTarget: selectedTarget
    }) : "No target selected";
    return ___EmotionJSX(Tooltip, {
        open: tooltipIsOpen,
        onOpen: function onOpen() {
            return !menuIsOpen && setTooltipIsOpen(true);
        },
        onClose: function onClose() {
            return setTooltipIsOpen(false);
        },
        title: tooltipText,
        placement: "bottom-end"
    }, ___EmotionJSX("div", null, ___EmotionJSX(Select, {
        value: selectedTargetName,
        closeMenuOnSelect: true,
        placeholder: values(targets || []).length > 0 ? selectedTargetName && selectedTargetName.length > 0 ? selectedTargetName : "Select target" : "No targets found"
        // menuIsOpen={true}
        ,
        onMenuOpen: function onMenuOpen() {
            setTooltipIsOpen(false);
            setMenuIsOpen(true);
        },
        onMenuClose: function onMenuClose() {
            return setMenuIsOpen(false);
        },
        onChange: function onChange(event) {
            event.value === "___toggle-configure" ? dispatch(showTargetsConfigDialog()) : dispatch(setSelectedTarget(activeProjectUid, event.value));
        },
        isSearchable: false,
        options: options,
        styles: {
            control: function control() {
                return SS.control;
            },
            container: function container() {
                return SS.dropdownContainer(theme);
            },
            valueContainer: function valueContainer() {
                return SS.valueContainer;
            },
            groupHeading: function groupHeading() {
                return SS.groupHeading(theme);
            },
            placeholder: function placeholder() {
                return SS.placeholder(theme);
            },
            menu: function menu() {
                return SS.menu(theme);
            },
            menuList: function menuList() {
                return SS.menuList(theme);
            },
            option: function option() {
                return SS.menuOption(theme);
            },
            indicatorsContainer: function indicatorsContainer() {
                return SS.indicatorContainer(theme);
            },
            indicatorSeparator: function indicatorSeparator() {
                return SS.indicatorSeparator;
            }
        }
    })));
};
export default TargetDropdown;
