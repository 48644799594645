import React from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "@root/store";
import { List, ListItem, ListItemText } from "@mui/material";
import FollowingList from "./tabs/following-list";
import FollowersList from "./tabs/followers-list";
import StarsList from "./tabs/stars-list";
import ListPlayButton from "./list-play-button";
import SettingsIcon from "@mui/icons-material/Settings";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Tooltip from "@mui/material/Tooltip";
import { StyledListItemContainer, StyledListItemTopRowText, StyledListItemChipsRow, StyledChip, StyledListPlayButtonContainer, StyledListButtonsContainer } from "./profile-ui";
import { selectFilteredUserFollowing, selectFilteredUserFollowers } from "./selectors";
import { editProject, deleteProject } from "./actions";
import { markProjectPublic } from "@comp/projects/actions";
import { descend, sort, propOr } from "ramda";
import * as SS from "./styles";
import { jsx as ___EmotionJSX } from "@emotion/react";
var ProjectListItem = function ProjectListItem(_ref) {
    var isProfileOwner = _ref.isProfileOwner, project = _ref.project;
    var dispatch = useDispatch();
    var isPublic = project.isPublic, projectUid = project.projectUid, name = project.name, description = project.description, tags = project.tags;
    return ___EmotionJSX("div", {
        style: {
            position: "relative"
        }
    }, ___EmotionJSX(Link, {
        to: "/editor/" + projectUid
    }, ___EmotionJSX(ListItem, {
        alignItems: "flex-start"
    }, ___EmotionJSX(StyledListItemContainer, {
        isProfileOwner: isProfileOwner
    }, ___EmotionJSX(StyledListItemTopRowText, null, ___EmotionJSX(ListItemText, {
        primary: name,
        secondary: description
    })), ___EmotionJSX(StyledListItemChipsRow, null, Array.isArray(tags) && tags.map(function (t, index) {
        return ___EmotionJSX(StyledChip, {
            color: "primary",
            key: index,
            label: t
        });
    })))), isProfileOwner && ___EmotionJSX(StyledListButtonsContainer, null)), ___EmotionJSX(StyledListPlayButtonContainer, null, ___EmotionJSX(ListPlayButton, {
        project: project
    })), isProfileOwner && ___EmotionJSX(React.Fragment, null, ___EmotionJSX(Tooltip, {
        title: "Toggle project settings",
        followCursor: true
    }, ___EmotionJSX("div", {
        css: SS.settingsIconContainer
    }, ___EmotionJSX("div", {
        css: SS.settingsIcon,
        key: projectUid,
        onClick: function onClick(event) {
            dispatch(editProject(project));
            event.preventDefault();
            event.stopPropagation();
        }
    }, ___EmotionJSX(SettingsIcon, null)))), ___EmotionJSX(Tooltip, {
        title: "Delete ".concat(name),
        followCursor: true
    }, ___EmotionJSX("div", {
        css: SS.deleteIconContainer
    }, ___EmotionJSX("div", {
        css: SS.deleteIcon,
        onClick: function onClick(event) {
            dispatch(deleteProject(project));
            event.preventDefault();
            event.stopPropagation();
        }
    }, ___EmotionJSX(DeleteIcon, null)))), ___EmotionJSX(Tooltip, {
        title: isPublic ? "Make the project private" : "Make the project public",
        followCursor: true
    }, ___EmotionJSX("div", {
        css: SS.publicIconContainer
    }, ___EmotionJSX("div", {
        css: SS.publicIcon,
        style: {
            opacity: isPublic ? 1 : 0.6
        },
        onClick: function onClick(event) {
            dispatch(markProjectPublic(projectUid, !isPublic));
            event.preventDefault();
            event.stopPropagation();
        }
    }, isPublic ? ___EmotionJSX(VisibilityIcon, null) : ___EmotionJSX(VisibilityOffIcon, null))))));
};
var ProfileLists = function ProfileLists(_ref2) {
    var profileUid = _ref2.profileUid, selectedSection = _ref2.selectedSection, isProfileOwner = _ref2.isProfileOwner, filteredProjects = _ref2.filteredProjects;
    var filteredFollowing = useSelector(selectFilteredUserFollowing(profileUid));
    var filteredFollowers = useSelector(selectFilteredUserFollowers(profileUid));
    return ___EmotionJSX(List, null, selectedSection === 0 && Array.isArray(filteredProjects) && sort(descend(propOr(Number.NEGATIVE_INFINITY, "created")), filteredProjects).map(function (project) {
        return ___EmotionJSX(ProjectListItem, {
            key: project.projectUid,
            isProfileOwner: isProfileOwner,
            project: project
        });
    }), selectedSection === 1 && Array.isArray(filteredFollowing) && ___EmotionJSX(FollowingList, {
        filteredFollowing: filteredFollowing
    }), selectedSection === 2 && Array.isArray(filteredFollowers) && ___EmotionJSX(FollowersList, {
        filteredFollowers: filteredFollowers
    }), selectedSection === 3 && ___EmotionJSX(StarsList, {
        profileUid: profileUid
    }));
};
export default ProfileLists;
