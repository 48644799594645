import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { isIOS } from "@root/utils";
import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import { jsx as ___EmotionJSX } from "@emotion/react";
var IosWarning = function IosWarning() {
    var _useState = useState(true), _useState2 = _slicedToArray(_useState, 2), open = _useState2[0], setOpen = _useState2[1];
    if (isIOS()) {
        var alreadyShown = localStorage.getItem("ios:warning:shown") === "1";
        return ___EmotionJSX(React.Fragment, null, !alreadyShown && ___EmotionJSX(Dialog, {
            open: open,
            fullScreen: true
        }, ___EmotionJSX("div", {
            style: {
                marginTop: 20,
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignSelf: "center",
                textAlign: "center",
                width: "100%"
            }
        }, ___EmotionJSX("h1", null, "Dear iOS user"), ___EmotionJSX("img", {
            src: "/img/ios_notification_warning.png",
            alt: "iphone notification switch",
            style: {
                display: "block",
                width: 360,
                alignSelf: "center"
            }
        }), ___EmotionJSX("p", {
            style: {
                fontSize: 15,
                width: 360,
                alignSelf: "center"
            }
        }, "Please make sure that you have the notifications turned ON (Ring) while using the web-ide, otherwise you wont experience any audio trough your web-browser. Enjoy!"), ___EmotionJSX(Button, {
            style: {
                alignSelf: "center",
                color: "white",
                backgroundColor: "blue"
            },
            variant: "outlined",
            onClick: function onClick() {
                localStorage.setItem("ios:warning:shown", "1");
                setOpen(false);
            }
        }, "Close"))));
    }
    else {
        return ___EmotionJSX(React.Fragment, null);
    }
};
export default IosWarning;
