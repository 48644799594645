import { SET_CLEAR_CONSOLE_CALLBACK, SET_PRINT_TO_CONSOLE_CALLBACK } from "./types";
import { assoc } from "ramda";
var initState = {
    clearConsole: undefined,
    printToConsole: undefined
};
var Console = function Console(state, action) {
    switch (action.type) {
        case SET_CLEAR_CONSOLE_CALLBACK:
            {
                return assoc("clearConsole", action.callback, state);
            }
        case SET_PRINT_TO_CONSOLE_CALLBACK:
            {
                return assoc("printToConsole", action.callback, state);
            }
        default:
            {
                return state || initState;
            }
    }
};
export default Console;
