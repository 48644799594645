var PREFIX = "PROJECTS.";
// ACTION TYPES
export var ACTIVATE_PROJECT = PREFIX + "ACTIVATE_PROJECT";
export var ADD_PROJECT_DOCUMENTS = PREFIX + "ADD_PROJECT_DOCUMENTS";
export var CLOSE_PROJECT = PREFIX + "CLOSE_PROJECT";
export var STORE_PROJECT_LOCALLY = PREFIX + "STORE_PROJECT_LOCALLY";
export var SET_PROJECT_PUBLIC = PREFIX + "SET_PROJECT_PUBLIC";
export var STORE_PROJECT_STARS = PREFIX + "STORE_PROJECT_STARS";
export var UNSET_PROJECT = PREFIX + "UNSET_PROJECT";
export var DOCUMENT_INITIALIZE = PREFIX + "DOCUMENT_INITIALIZE";
export var DOCUMENT_RENAME_LOCALLY = PREFIX + "DOCUMENT_RENAME_LOCALLY";
export var DOCUMENT_REMOVE_LOCALLY = PREFIX + "DOCUMENT_REMOVE_LOCALLY";
export var DOCUMENT_RESET = PREFIX + "DOCUMENT_RESET";
export var DOCUMENT_SAVE = PREFIX + "DOCUMENT_SAVE";
export var DOCUMENT_UPDATE_VALUE = PREFIX + "DOCUMENT_UPDATE_VALUE";
export var DOCUMENT_UPDATE_MODIFIED_LOCALLY = PREFIX + "DOCUMENT_UPDATE_MODIFIED_LOCALLY";
