import React, { useEffect } from "react";
import { useDispatch, useSelector } from "@root/store";
import TargetDropdown from "./dropdown";
import PlayButton from "./play-button";
import { selectProjectTargets, selectSelectedTarget } from "./selectors";
import { selectIsOwner } from "@comp/project-editor/selectors";
import { setSelectedTarget } from "./actions";
import { path, pathOr, values } from "ramda";
import StopButton from "./stop-button";
import { jsx as ___EmotionJSX } from "@emotion/react";
var TargetControls = function TargetControls() {
    var dispatch = useDispatch();
    var selectedTarget = useSelector(selectSelectedTarget);
    var activeProjectUid = useSelector(function (store) {
        return path(["ProjectsReducer", "activeProjectUid"], store);
    });
    var isOwner = useSelector(selectIsOwner(activeProjectUid || ""));
    var targets = useSelector(selectProjectTargets(activeProjectUid));
    var targetsValues = targets && values(targets);
    var savedDefaultTarget = useSelector(function (store) {
        return activeProjectUid && pathOr(["ProjectsReducer", "projects", activeProjectUid, "defaultTarget"], store);
    });
    useEffect(function () {
        if (!selectedTarget) {
            if (savedDefaultTarget && savedDefaultTarget.length > 0) {
                activeProjectUid && dispatch(setSelectedTarget(activeProjectUid, savedDefaultTarget));
            }
            else if (targetsValues && targetsValues.length > 0) {
                if (targetsValues.some(function (t) {
                    return t.targetName === "project.csd";
                })) {
                    activeProjectUid && dispatch(setSelectedTarget(activeProjectUid, "project.csd"));
                }
                else {
                    activeProjectUid ? dispatch(setSelectedTarget(activeProjectUid, targetsValues[0].targetName)) : console.error("Error: missing activeProjectUid");
                }
            }
        }
    }, [activeProjectUid, dispatch, targetsValues, savedDefaultTarget, selectedTarget]);
    return activeProjectUid ? ___EmotionJSX(React.Fragment, null, ___EmotionJSX(PlayButton, {
        activeProjectUid: activeProjectUid,
        isOwner: isOwner
    }), ___EmotionJSX(StopButton, null), isOwner && ___EmotionJSX(TargetDropdown, {
        activeProjectUid: activeProjectUid
    })) : ___EmotionJSX(React.Fragment, null);
};
export default TargetControls;
