import React from "react";
import { Provider as Provider_ } from "react-redux";
import { createRoot } from "react-dom/client";
import Main from "./components/main/main";
import { store } from "@root/store";
import "./config/firestore"; // import for sideffects
import "react-perfect-scrollbar/dist/css/styles.css";
/* eslint-disable-next-line unicorn/prefer-query-selector */
import { jsx as ___EmotionJSX } from "@emotion/react";
var container = document.getElementById("root");
var root = createRoot(container);
var Provider = Provider_;
root.render(___EmotionJSX(React.Fragment, null, ___EmotionJSX(Provider, {
    store: store
}, ___EmotionJSX(Main, null))));
