import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useState } from "react";
import { useDispatch, useSelector } from "@root/store";
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, TextField, LinearProgress, Link } from "@mui/material";
import { login, closeLoginDialog, createNewUser, createUserClearError, resetPassword } from "./actions";
import { selectLoginRequesting, selectLoginFail, selectErrorCode, selectErrorMessage } from "./selectors";
import { validateEmail, isElectron } from "@root/utils";
import * as SS from "./styles";
import { assoc, isEmpty, pipe } from "ramda";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import firebase from "firebase/compat/app";
import { getAuth } from "firebase/auth";
import "firebase/compat/auth";
// Configure FirebaseUI.
import { jsx as ___EmotionJSX } from "@emotion/react";
var uiConfig = {
    signInFlow: isElectron ? "redirect" : "popup",
    signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID, firebase.auth.FacebookAuthProvider.PROVIDER_ID],
    callbacks: {
        signInSuccessWithAuthResult: function signInSuccessWithAuthResult() {
            return false;
        }
    }
};
var Login = function Login() {
    var dispatch = useDispatch();
    var errorCode = useSelector(selectErrorCode);
    var errorMessage = useSelector(selectErrorMessage);
    var fail = useSelector(selectLoginFail);
    var requesting = useSelector(selectLoginRequesting);
    var _useState = useState({
        email: "",
        newEmail: "",
        newEmailValid: false,
        password: "",
        newPassword: "",
        newPasswordConfirm: "",
        loginMode: "login"
    }), _useState2 = _slicedToArray(_useState, 2), localState = _useState2[0], setLocalState = _useState2[1];
    var switchLoginMode = function switchLoginMode(loginMode) {
        dispatch(createUserClearError());
        setLocalState(assoc("loginMode", loginMode, localState));
    };
    var errorBox = !isEmpty(errorMessage) && errorMessage && ___EmotionJSX("div", {
        css: SS.errorBox
    }, ___EmotionJSX("h5", null, "Error " + errorCode), ___EmotionJSX("p", null, errorMessage));
    var disabledBool = localState.newPasswordConfirm.length < 6 || localState.newPassword.length < 6 || localState.newPasswordConfirm !== localState.newPassword || !localState.newEmailValid;
    var loginView = function loginView() {
        return ___EmotionJSX("div", null, ___EmotionJSX(DialogTitle, null, "Login"), ___EmotionJSX(DialogContent, null, ___EmotionJSX(DialogContentText, null, "Please enter your email address and password"), ___EmotionJSX("form", null, ___EmotionJSX(TextField, {
            autoFocus: true,
            margin: "dense",
            id: "email",
            label: "Email Address",
            type: "email",
            value: localState.email,
            onChange: function onChange(event) {
                setLocalState(assoc("email", event.target.value, localState));
            },
            fullWidth: true,
            error: fail,
            autoComplete: "current-email"
        }), ___EmotionJSX(TextField, {
            margin: "dense",
            id: "password",
            label: "Password",
            type: "password",
            value: localState.password,
            onChange: function onChange(event) {
                setLocalState(assoc("password", event.target.value, localState));
            },
            fullWidth: true,
            error: fail,
            autoComplete: "current-password",
            onKeyPress: function onKeyPress(event) {
                return event.key === "Enter" && dispatch(login(localState.email, localState.password));
            }
        })), ___EmotionJSX("div", {
            style: {
                transition: "opacity .1s ease-in",
                opacity: requesting ? 1 : 0
            }
        }, ___EmotionJSX(LinearProgress, null)), ___EmotionJSX(DialogActions, null, ___EmotionJSX(Button, {
            onClick: function onClick() {
                dispatch(login(localState.email, localState.password));
            },
            color: "primary"
        }, "Login"), ___EmotionJSX(Button, {
            onClick: function onClick() {
                return switchLoginMode("create");
            },
            color: "primary"
        }, "New User"))), ___EmotionJSX(StyledFirebaseAuth, {
            uiConfig: uiConfig,
            firebaseAuth: getAuth()
        }), ___EmotionJSX("div", {
            css: SS.centerLink
        }, ___EmotionJSX(Link, {
            onClick: function onClick() {
                return switchLoginMode("reset");
            }
        }, "Forgot password?")));
    };
    var resetView = function resetView() {
        return ___EmotionJSX("div", null, ___EmotionJSX(DialogTitle, null, "Reset Password"), ___EmotionJSX(DialogContent, null, ___EmotionJSX(DialogContentText, null, "Please enter your email address"), ___EmotionJSX("form", null, ___EmotionJSX(TextField, {
            autoFocus: true,
            margin: "dense",
            id: "email",
            label: "Email Address",
            type: "email",
            value: localState.email,
            onChange: function onChange(event) {
                setLocalState(pipe(assoc("email", event.target.value), assoc("newEmailValid", validateEmail(event.target.value)))(localState));
            },
            fullWidth: true,
            error: fail,
            autoComplete: "current-email"
        })), ___EmotionJSX("div", {
            style: {
                transition: "opacity .1s ease-in",
                opacity: requesting ? 1 : 0
            }
        }, ___EmotionJSX(LinearProgress, null)), ___EmotionJSX(DialogActions, null, ___EmotionJSX(Button, {
            onClick: function onClick() {
                return switchLoginMode("login");
            },
            color: "primary"
        }, "Back"), ___EmotionJSX(Button, {
            onClick: function onClick() {
                dispatch(resetPassword(localState.email));
                switchLoginMode("login");
            },
            color: "primary",
            disabled: !localState.newEmailValid
        }, "Reset"))));
    };
    var signupView = function signupView() {
        return ___EmotionJSX("div", {
            style: {
                padding: "45px"
            }
        }, ___EmotionJSX(DialogTitle, null, "New Account"), ___EmotionJSX(DialogContentText, null, "Please provide a valid email"), ___EmotionJSX("form", null, ___EmotionJSX(TextField, {
            autoFocus: true,
            margin: "dense",
            id: "new-email",
            label: "Email Address",
            type: "email",
            value: localState.newEmail,
            onChange: function onChange(event) {
                setLocalState(pipe(assoc("newEmail", event.target.value), assoc("newEmailValid", validateEmail(event.target.value)))(localState));
            },
            fullWidth: true,
            error: !localState.newEmailValid
        }), ___EmotionJSX(DialogContentText, {
            style: {
                marginTop: "24px"
            }
        }, "Choose a good password of minimum 6 characters length"), ___EmotionJSX(TextField, {
            margin: "dense",
            id: "new-password",
            label: "New Password",
            type: "password",
            value: localState.newPassword,
            onChange: function onChange(event) {
                setLocalState(assoc("newPassword", event.target.value, localState));
            },
            fullWidth: true,
            error: localState.newPassword.length < 5,
            autoComplete: "new-password"
        }), ___EmotionJSX(TextField, {
            margin: "dense",
            id: "new-password-confirm",
            label: "Confirm New Password",
            type: "password",
            value: localState.newPasswordConfirm,
            onChange: function onChange(event) {
                setLocalState(assoc("newPasswordConfirm", event.target.value, localState));
            },
            fullWidth: true,
            error: localState.newPasswordConfirm.length < 5 || localState.newPassword.length < 5 || localState.newPasswordConfirm !== localState.newPassword,
            autoComplete: "new-password"
        })), ___EmotionJSX("div", {
            style: {
                transition: "opacity .1s ease-in",
                opacity: requesting ? 1 : 0
            }
        }, ___EmotionJSX(LinearProgress, null)), ___EmotionJSX(DialogActions, null, ___EmotionJSX(Button, {
            onClick: function onClick() {
                return switchLoginMode("login");
            },
            color: "primary"
        }, "Back"), ___EmotionJSX(Button, {
            onClick: function onClick() {
                return dispatch(createNewUser(localState.newEmail, localState.newPassword));
            },
            color: "primary",
            disabled: disabledBool
        }, "Create")));
    };
    var renderView = function renderView(loginMode) {
        switch (loginMode) {
            case "login":
                {
                    return loginView();
                }
            case "create":
                {
                    return signupView();
                }
            case "reset":
                {
                    return resetView();
                }
        }
    };
    return ___EmotionJSX(Dialog, {
        onClose: function onClose() {
            dispatch(createUserClearError());
            dispatch(closeLoginDialog());
        },
        open: true
    }, renderView(localState.loginMode), errorBox);
};
export default Login;
