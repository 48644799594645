import { CLOSE_BOTTOM_TAB, OPEN_BOTTOM_TAB, REORDER_TABS, SET_BOTTOM_TAB_INDEX } from "./types";
import { append, assoc, equals, findIndex, pipe, propOr, reject } from "ramda";
var initState = {
    index: 0,
    openTabs: ["console"]
};
var BottomTabs = function BottomTabs(state, action) {
    switch (action.type) {
        case OPEN_BOTTOM_TAB:
            {
                var safeState = state || initState;
                var tabIsAlreadyOpen = safeState.openTabs.includes(action.tab);
                return tabIsAlreadyOpen ? assoc("index", findIndex(equals(action.tab), safeState.openTabs), state) : pipe(assoc("openTabs", append(action.tab, propOr({}, "openTabs", state))), assoc("index", safeState.openTabs.length))(safeState);
            }
        case CLOSE_BOTTOM_TAB:
            {
                var _safeState = state || initState;
                var newTabs = reject(equals(action.closeTab), _safeState.openTabs || []);
                var newIndex = newTabs.length === 0 ? -1 : Math.min(_safeState.index, newTabs.length - 1);
                return pipe(assoc("openTabs", newTabs), assoc("index", newIndex))(_safeState);
            }
        case SET_BOTTOM_TAB_INDEX:
            {
                return assoc("index", action.newIndex, state || initState);
            }
        case REORDER_TABS:
            {
                return pipe(assoc("index", action.newIndex), assoc("openTabs", action.newOrder))(state || initState);
            }
        default:
            {
                return state || initState;
            }
    }
};
export default BottomTabs;
