import { assoc } from "ramda";
import { SET_CSOUND_PLAY_STATE } from "./types";
var CsoundReducer = function CsoundReducer(state, action) {
    switch (action.type) {
        case SET_CSOUND_PLAY_STATE:
            {
                return assoc("status", action.status, state);
            }
        default:
            {
                return state || {
                    status: "initialized"
                };
            }
    }
};
export default CsoundReducer;
