var PREFIX = "PROJECT_EDITOR.";
// ACTION TYPES
export var MANUAL_LOOKUP_STRING = PREFIX + "MANUAL_LOOKUP_STRING";
export var SET_MANUAL_PANEL_OPEN = PREFIX + "SET_MANUAL_PANEL_OPEN";
export var SET_FILE_TREE_PANEL_OPEN = PREFIX + "SET_FILE_TREE_PANEL_OPEN";
export var TAB_DOCK_INIT = PREFIX + "TAB_DOCK_INIT";
export var TAB_DOCK_SWITCH_TAB = PREFIX + "TAB_DOCK_SWITCH_TAB";
export var TAB_DOCK_REARRANGE_TABS = PREFIX + "TAB_DOCK_REARRANGE_TABS";
export var TAB_DOCK_OPEN_NON_CLOUD_FILE = PREFIX + "TAB_DOCK_OPEN_NON_CLOUD_FILE";
export var TAB_DOCK_OPEN_TAB_BY_DOCUMENT_UID = PREFIX + "TAB_DOCK_OPEN_TAB_BY_DOCUMENT_UID";
export var TAB_DOCK_CLOSE = PREFIX + "TAB_DOCK_CLOSE";
export var TAB_CLOSE = PREFIX + "TAB_CLOSE";
export var TOGGLE_MANUAL_PANEL = PREFIX + "TOGGLE_MANUAL_PANEL";
// DATA TYPES
