import _extends from "@babel/runtime/helpers/extends";
import { isEmpty } from "ramda";
// import CloseIcon from "@mui/icons-material/Close";
import { hr as hrCss } from "@styles/_common";
import { TargetControlsConfigDialogFooter } from "./footer";
import { TargetControlsConfigDialogSingleTarget } from "./single-target";
import { useTargetControlsDialog } from "./use-target-controls-dialog";
import * as SS from "./styles";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var TargetControlsConfigDialog = function TargetControlsConfigDialog() {
    var _useTargetControlsDia = useTargetControlsDialog(), allDocuments = _useTargetControlsDia.allDocuments, handleCloseModal = _useTargetControlsDia.handleCloseModal, handleCreateNewTarget = _useTargetControlsDia.handleCreateNewTarget, handleTargetDelete = _useTargetControlsDia.handleTargetDelete, handleTargetNameChange = _useTargetControlsDia.handleTargetNameChange, handleSelectTargetDocument = _useTargetControlsDia.handleSelectTargetDocument, handleMarkAsDefaultTarget = _useTargetControlsDia.handleMarkAsDefaultTarget, handleEnableCsound7 = _useTargetControlsDia.handleEnableCsound7, handleSave = _useTargetControlsDia.handleSave, hasModifiedTargets = _useTargetControlsDia.hasModifiedTargets, newTargets = _useTargetControlsDia.newTargets, targets = _useTargetControlsDia.targets, theme = _useTargetControlsDia.theme;
    return ___EmotionJSX("div", {
        css: SS.targetsDialog
    }, ___EmotionJSX("h2", null, "Project configuration"), (!targets || isEmpty(targets)) && ___EmotionJSX("p", {
        style: {
            color: theme.altTextColor,
            userSelect: "none"
        }
    }, 'No targets found, press "ADD TARGET" to get started. '), (newTargets || []).map(function (props, index) {
        return ___EmotionJSX(TargetControlsConfigDialogSingleTarget, _extends({
            key: index,
            targetIndex: index,
            allDocuments: allDocuments,
            handleTargetDelete: handleTargetDelete,
            handleTargetNameChange: handleTargetNameChange,
            handleSelectTargetDocument: handleSelectTargetDocument,
            handleMarkAsDefaultTarget: handleMarkAsDefaultTarget,
            handleEnableCsound7: handleEnableCsound7,
            newTargets: newTargets
        }, props));
    }), ___EmotionJSX("hr", {
        css: hrCss
    }), ___EmotionJSX(TargetControlsConfigDialogFooter, {
        handleCloseModal: handleCloseModal,
        handleCreateNewTarget: handleCreateNewTarget,
        handleSave: handleSave,
        hasModifiedTargets: hasModifiedTargets
    }));
};
