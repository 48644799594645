import { curry, keys, pathOr, propOr } from "ramda";
import { selectActiveProject } from "../projects/selectors";
export var selectActiveProjectUid = function selectActiveProjectUid(store) {
    var state = store.ProjectsReducer;
    return state.activeProjectUid;
};
export var selectProjects = function selectProjects(store) {
    var state = store.ProjectsReducer;
    return state.projects;
};
export var selectUserStarredProject = curry(function (loggedInUserUid, projectUid, store) {
    var projectStars = pathOr([], ["ProjectsReducer", "projects", projectUid, "stars"], store);
    return keys(projectStars).includes(loggedInUserUid);
});
export var selectProjectPublic = function selectProjectPublic(store) {
    var activeProject = selectActiveProject(store);
    return propOr(true, "isPublic", activeProject);
};
