import { assocPath, pipe, reduce } from "ramda";
// import { rgba } from "./utils";
// assume darkmode = primary
// (if consistent, the lightMode vs darkMode distinction becomes irrelevant)
var typographyOverride = function typographyOverride(theme) {
    return pipe(
    // (x) => (console.log(x) as any) || x,
    // assocPath(["typography", "fontFamily"], theme.font.regular),
    assocPath(["typography", "button", "fontFamily"], theme.font.monospace), function (t) {
        return reduce(function (acc, k) {
            return assocPath(["typography", k, "fontFamily"], theme.font.regular, acc);
        }, t, ["body1", "body2", "button", "caption", "h1", "h2", "h3", "h4", "h5", "subtitle1", "subtitle2"]);
    });
};
export var makeMuiTheme = function makeMuiTheme(muiTheme, theme) {
    // const primaryPalette = {
    //     light: theme.highlight.secondary,
    //     main: theme.highlight.primary,
    //     dark: theme.color.secondary,
    //     contrastText: theme.color.primary
    // };
    // const backgroundPalette = {
    //     paper: theme.background.primary,
    //     default: theme.background.primary
    // };
    return pipe(typographyOverride(theme), assocPath(["palette", "common"], {
        black: theme.textColor,
        white: theme.background
    }), assocPath(["palette", "background"], {
        paper: theme.background,
        default: theme.background
    }), 
    // assocPath(["palette", "primary"], primaryPalette),
    assocPath(["overrides", "MuiTooltip", "tooltip"], {
        color: theme.buttonTextColor,
        backgroundColor: theme.tooltipBackground,
        // textShadow: "0px 2px 2px rgba(0, 0, 0, 0.4)",
        textShadow: "unset",
        fontSize: "14px",
        padding: "12px",
        fontWeight: "500"
    }), assocPath(["overrides", "MuiDrawer", "paper"], {
        backgroundColor: theme.tooltipBackground,
        color: "".concat(theme.textColor, "!important")
    }), assocPath(["overrides", "MuiListItemText", "secondary"], {
        color: "".concat(theme.altTextColor, "!important")
    }), assocPath(["overrides", "MuiTypography", "root"], {
        color: "".concat(theme.textColor, "!important")
    }), assocPath(["overrides", "MuiButtonBase", "root"], {
        "&:hover": {
            backgroundColor: "".concat(theme.buttonBackgroundHover, "!important")
        }
    }), assocPath(["overrides", "MuiFab", "primary"], {
        backgroundColor: "".concat(theme.buttonBackground),
        color: "".concat(theme.buttonTextColor)
    }), assocPath(["overrides", "MuiTabs", "root"], {
        "& button": {
            color: "".concat(theme.buttonTextColor, "!important"),
            backgroundColor: "".concat(theme.buttonBackground)
        }
    }), assocPath(["overrides", "MuiButtonBase", "disabled"], {
        color: "".concat(theme.disabledButtonBackground, "!important")
    }), assocPath(["overrides", "MuiButton", "textPrimary"], {
        backgroundColor: "".concat(theme.buttonBackground),
        color: "".concat(theme.buttonTextColor, "!important"),
        opacity: 0.95,
        "&:hover": {
            backgroundColor: "".concat(theme.buttonBackgroundHover, "!important")
        }
    }), assocPath(["overrides", "MuiTouchRipple", "rippleVisible"], {
        color: "#005d83 !important"
    }), assocPath(["overrides", "MuiButton", "textSecondary"], {
        backgroundColor: "".concat(theme.altButtonBackground),
        color: "".concat(theme.buttonTextColor, "!important"),
        opacity: 0.95,
        "&:hover": {
            backgroundColor: "".concat(theme.textColorHover, "!important")
        }
    }), assocPath(["overrides", "MuiFormLabel", "error"], {
        color: "".concat(theme.errorText, "!important")
    }), assocPath(["overrides", "MuiInputBase", "root"], {
        color: theme.textColor
    }), assocPath(["overrides", "MuiInput", "underline"], {
        "&:before": {
            borderBottom: "1px solid ".concat(theme.line, "!important")
        },
        color: theme.textColor
    }), assocPath(["overrides", "MuiInputLabel", "animated"], {
        transition: "none",
        color: "".concat(theme.buttonTextColor, "!important")
    }), assocPath(["overrides", "MuiInputBase", "input"], {
        color: theme.textColor
    }), assocPath(["overrides", "MuiAppBar", "positionFixed"], {
        left: "0!important",
        borderRightWidth: "4px!important"
    }), assocPath(["overrides", "MuiDrawer", "paper"], {
        backgroundColor: "".concat(theme.background, "!important"),
        "& .MuiButtonBase-root": {
            backgroundColor: "".concat(theme.background, "!important"),
            "&:hover": {
                backgroundColor: "".concat(theme.buttonBackgroundHover, "!important")
            }
        }
    }), assocPath(["overrides", "MuiPaper", "root"], {
        color: theme.textColor,
        backgroundColor: theme.background
    }), assocPath(["overrides", "MuiMenu", "paper"], {
        color: theme.textColor,
        backgroundColor: theme.background,
        border: "2px solid ".concat(theme.line),
        borderRadius: "6px",
        "& ul": {
            padding: 0
        },
        "& a,li": {
            color: theme.textColor,
            fontSize: "15px",
            fontWeight: 500
        },
        "& li": {
            padding: "12px 24px!important"
        }
    }), 
    // assocPath(["overrides", "MuiListItem"], {
    //     cursor: "grabbing"
    // }),
    assocPath(["overrides", "MuiMenu", "list"], {
        color: theme.textColor
    }))(muiTheme);
};
