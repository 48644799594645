import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@emotion/react";
import DisabledByDefaultRoundedIcon from "@mui/icons-material/DisabledByDefaultRounded";
import { windowHeader as windowHeaderStyle } from "@styles/_common";
import Tooltip from "@mui/material/Tooltip";
import IframeComm from "react-iframe-comm";
import { setManualPanelOpen } from "./actions";
import * as SS from "./styles";
import { jsx as ___EmotionJSX } from "@emotion/react";
var ManualWindow = function ManualWindow(_ref) {
    var projectUid = _ref.projectUid, _ref$isDragging = _ref.isDragging, isDragging = _ref$isDragging === void 0 ? false : _ref$isDragging;
    var dispatch = useDispatch();
    var theme = useTheme();
    var manualLookupString = useSelector(function (store) {
        return store.ProjectEditorReducer.manualLookupString;
    });
    // const onManualMessage = (event_) => {
    //     console.log("ON MAN MSG", event_, theme);
    // };
    useEffect(function () {
        sessionStorage.setItem(projectUid + ":manualVisible", "true");
        return function () {
            return sessionStorage.setItem(projectUid + ":manualVisible", "false");
        };
    }, [projectUid]);
    return ___EmotionJSX("div", {
        style: {
            width: "100%",
            height: "100%",
            paddingTop: 35,
            pointerEvents: isDragging ? "none" : "auto"
        }
    }, ___EmotionJSX(IframeComm, {
        attributes: {
            src: "/manual?cache=1002",
            width: "100%",
            height: "100%"
        },
        postMessageData: manualLookupString || ""
    }), ___EmotionJSX("div", {
        css: windowHeaderStyle
    }, ___EmotionJSX("p", null, "Csound Manual", ___EmotionJSX("span", {
        css: SS.headIconsContainer
    }, ___EmotionJSX(Tooltip, {
        title: "close window"
    }, ___EmotionJSX("span", {
        onClick: function onClick() {
            return dispatch(setManualPanelOpen(false));
        }
    }, ___EmotionJSX(DisabledByDefaultRoundedIcon, {
        style: {
            fill: theme.highlightBackgroundAlt
        }
    })))))));
};
export default ManualWindow;
