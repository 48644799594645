export var selectLoginRequesting = function selectLoginRequesting(_ref) {
    var LoginReducer = _ref.LoginReducer;
    return LoginReducer.requesting;
};
export var selectErrorCode = function selectErrorCode(_ref2) {
    var LoginReducer = _ref2.LoginReducer;
    return LoginReducer.errorCode;
};
export var selectErrorMessage = function selectErrorMessage(_ref3) {
    var LoginReducer = _ref3.LoginReducer;
    return LoginReducer.errorMessaage;
};
export var selectLoginFail = function selectLoginFail(_ref4) {
    var LoginReducer = _ref4.LoginReducer;
    return LoginReducer.failed;
};
export var selectAuthenticated = function selectAuthenticated(_ref5) {
    var LoginReducer = _ref5.LoginReducer;
    return LoginReducer.authenticated;
};
export var selectLoggedInUid = function selectLoggedInUid(_ref6) {
    var LoginReducer = _ref6.LoginReducer;
    return LoginReducer.loggedInUid;
};
