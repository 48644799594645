import { Bars as BarsSpinner } from "react-loader-spinner";
import ProjectAvatar from "@elem/project-avatar";
import ListPlayButton from "@comp/profile/list-play-button";
import { ProjectCardContainer, ProjectCardContentContainer, ProjectCardContentTop, ProjectCardContentBottom, ProjectCardContentTopHeader, ProjectCardContentTopDescription, ProjectCardContentMiddle, ProjectCardContentBottomPhoto, ProjectCardContentBottomHeader, ProjectCardContentBottomDescription, Photo, ProjectCardContentBottomID } from "./home-ui";
import * as SS from "./styles";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var ProjectCardSkeleton = function ProjectCardSkeleton(_ref) {
    var theme = _ref.theme;
    return ___EmotionJSX("div", {
        css: SS.cardLoderSkeleton
    }, ___EmotionJSX("span", {
        className: "skeleton-photo"
    }), ___EmotionJSX("span", {
        className: "skeleton-name"
    }), ___EmotionJSX("span", {
        className: "skeleton-description"
    }), ___EmotionJSX(BarsSpinner, {
        color: theme.altTextColor,
        height: 100,
        width: 100
    }));
};
var ProjectCard = function ProjectCard(_ref2) {
    var projectIndex = _ref2.projectIndex, profile = _ref2.profile, project = _ref2.project;
    return ___EmotionJSX(ProjectCardContainer, {
        duration: 200,
        projectIndex: projectIndex
    }, ___EmotionJSX("div", {
        css: SS.cardBackground
    }, ___EmotionJSX(ProjectAvatar, {
        project: project
    })), ___EmotionJSX(ProjectCardContentContainer, {
        duration: 200
    }, ___EmotionJSX(ProjectCardContentTop, {
        to: "editor/".concat(project.projectUid)
    }, ___EmotionJSX(ProjectCardContentTopHeader, null, project.name), ___EmotionJSX(ProjectCardContentTopDescription, null, project.description)), ___EmotionJSX(ProjectCardContentMiddle, null, ___EmotionJSX(ListPlayButton, {
        project: project
    })), ___EmotionJSX(ProjectCardContentBottom, {
        to: "profile/".concat(profile.username)
    }, ___EmotionJSX(ProjectCardContentBottomPhoto, null, profile.photoUrl && ___EmotionJSX(Photo, {
        src: profile.photoUrl
    })), ___EmotionJSX(ProjectCardContentBottomID, null, ___EmotionJSX(ProjectCardContentBottomHeader, null, profile.displayName || profile.username), ___EmotionJSX(ProjectCardContentBottomDescription, null, profile.bio)))));
};
export default ProjectCard;
