import fadADR from "../svgs/fad-ADR.svg";
import { ReactComponent as fadADRComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-ADR.svg";
import fadADSR from "../svgs/fad-ADSR.svg";
import { ReactComponent as fadADSRComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-ADSR.svg";
import fadAHDSR from "../svgs/fad-AHDSR.svg";
import { ReactComponent as fadAHDSRComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-AHDSR.svg";
import fadAR from "../svgs/fad-AR.svg";
import { ReactComponent as fadARComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-AR.svg";
import fadarmrecording from "../svgs/fad-armrecording.svg";
import { ReactComponent as fadarmrecordingComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-armrecording.svg";
import fadarpchord from "../svgs/fad-arpchord.svg";
import { ReactComponent as fadarpchordComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-arpchord.svg";
import fadarpdown from "../svgs/fad-arpdown.svg";
import { ReactComponent as fadarpdownComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-arpdown.svg";
import fadarpdownandup from "../svgs/fad-arpdownandup.svg";
import { ReactComponent as fadarpdownandupComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-arpdownandup.svg";
import fadarpdownup from "../svgs/fad-arpdownup.svg";
import { ReactComponent as fadarpdownupComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-arpdownup.svg";
import fadarpplayorder from "../svgs/fad-arpplayorder.svg";
import { ReactComponent as fadarpplayorderComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-arpplayorder.svg";
import fadarprandom from "../svgs/fad-arprandom.svg";
import { ReactComponent as fadarprandomComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-arprandom.svg";
import fadarpup from "../svgs/fad-arpup.svg";
import { ReactComponent as fadarpupComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-arpup.svg";
import fadarpupandown from "../svgs/fad-arpupandown.svg";
import { ReactComponent as fadarpupandownComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-arpupandown.svg";
import fadarpupdown from "../svgs/fad-arpupdown.svg";
import { ReactComponent as fadarpupdownComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-arpupdown.svg";
import fadarrowshorz from "../svgs/fad-arrows-horz.svg";
import { ReactComponent as fadarrowshorzComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-arrows-horz.svg";
import fadarrowsvert from "../svgs/fad-arrows-vert.svg";
import { ReactComponent as fadarrowsvertComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-arrows-vert.svg";
import fadautomation2p from "../svgs/fad-automation-2p.svg";
import { ReactComponent as fadautomation2pComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-automation-2p.svg";
import fadautomation3p from "../svgs/fad-automation-3p.svg";
import { ReactComponent as fadautomation3pComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-automation-3p.svg";
import fadautomation4p from "../svgs/fad-automation-4p.svg";
import { ReactComponent as fadautomation4pComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-automation-4p.svg";
import fadbackward from "../svgs/fad-backward.svg";
import { ReactComponent as fadbackwardComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-backward.svg";
import fadbluetooth from "../svgs/fad-bluetooth.svg";
import { ReactComponent as fadbluetoothComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-bluetooth.svg";
import fadcaretdown from "../svgs/fad-caret-down.svg";
import { ReactComponent as fadcaretdownComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-caret-down.svg";
import fadcaretleft from "../svgs/fad-caret-left.svg";
import { ReactComponent as fadcaretleftComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-caret-left.svg";
import fadcaretright from "../svgs/fad-caret-right.svg";
import { ReactComponent as fadcaretrightComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-caret-right.svg";
import fadcaretup from "../svgs/fad-caret-up.svg";
import { ReactComponent as fadcaretupComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-caret-up.svg";
import fadclose from "../svgs/fad-close.svg";
import { ReactComponent as fadcloseComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-close.svg";
import fadcopy from "../svgs/fad-copy.svg";
import { ReactComponent as fadcopyComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-copy.svg";
import fadcpu from "../svgs/fad-cpu.svg";
import { ReactComponent as fadcpuComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-cpu.svg";
import fadcutter from "../svgs/fad-cutter.svg";
import { ReactComponent as fadcutterComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-cutter.svg";
import faddigitalcolon from "../svgs/fad-digital-colon.svg";
import { ReactComponent as faddigitalcolonComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-digital-colon.svg";
import faddigitaldot from "../svgs/fad-digital-dot.svg";
import { ReactComponent as faddigitaldotComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-digital-dot.svg";
import faddigital0 from "../svgs/fad-digital0.svg";
import { ReactComponent as faddigital0Component } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-digital0.svg";
import faddigital1 from "../svgs/fad-digital1.svg";
import { ReactComponent as faddigital1Component } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-digital1.svg";
import faddigital2 from "../svgs/fad-digital2.svg";
import { ReactComponent as faddigital2Component } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-digital2.svg";
import faddigital3 from "../svgs/fad-digital3.svg";
import { ReactComponent as faddigital3Component } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-digital3.svg";
import faddigital4 from "../svgs/fad-digital4.svg";
import { ReactComponent as faddigital4Component } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-digital4.svg";
import faddigital5 from "../svgs/fad-digital5.svg";
import { ReactComponent as faddigital5Component } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-digital5.svg";
import faddigital6 from "../svgs/fad-digital6.svg";
import { ReactComponent as faddigital6Component } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-digital6.svg";
import faddigital7 from "../svgs/fad-digital7.svg";
import { ReactComponent as faddigital7Component } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-digital7.svg";
import faddigital8 from "../svgs/fad-digital8.svg";
import { ReactComponent as faddigital8Component } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-digital8.svg";
import faddigital9 from "../svgs/fad-digital9.svg";
import { ReactComponent as faddigital9Component } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-digital9.svg";
import faddiskio from "../svgs/fad-diskio.svg";
import { ReactComponent as faddiskioComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-diskio.svg";
import faddrumpad from "../svgs/fad-drumpad.svg";
import { ReactComponent as faddrumpadComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-drumpad.svg";
import fadduplicate from "../svgs/fad-duplicate.svg";
import { ReactComponent as fadduplicateComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-duplicate.svg";
import faderaser from "../svgs/fad-eraser.svg";
import { ReactComponent as faderaserComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-eraser.svg";
import fadffwd from "../svgs/fad-ffwd.svg";
import { ReactComponent as fadffwdComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-ffwd.svg";
import fadfilterbandpass from "../svgs/fad-filter-bandpass.svg";
import { ReactComponent as fadfilterbandpassComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-filter-bandpass.svg";
import fadfilterbell from "../svgs/fad-filter-bell.svg";
import { ReactComponent as fadfilterbellComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-filter-bell.svg";
import fadfilterbypass from "../svgs/fad-filter-bypass.svg";
import { ReactComponent as fadfilterbypassComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-filter-bypass.svg";
import fadfilterhighpass from "../svgs/fad-filter-highpass.svg";
import { ReactComponent as fadfilterhighpassComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-filter-highpass.svg";
import fadfilterlowpass from "../svgs/fad-filter-lowpass.svg";
import { ReactComponent as fadfilterlowpassComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-filter-lowpass.svg";
import fadfilternotch from "../svgs/fad-filter-notch.svg";
import { ReactComponent as fadfilternotchComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-filter-notch.svg"; // imp ort fadfilterrezhighpass, { ReactComponent as  rtComponent} from "../svgs/fad-filter-ez-highpass.svg";
import fadfilterrezlowpass from "../svgs/fad-filter-rez-lowpass.svg";
import { ReactComponent as fadfilterrezlowpassComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-filter-rez-lowpass.svg";
import fadfiltershelvinghi from "../svgs/fad-filter-shelving-hi.svg";
import { ReactComponent as fadfiltershelvinghiComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-filter-shelving-hi.svg";
import fadfiltershelvinglo from "../svgs/fad-filter-shelving-lo.svg";
import { ReactComponent as fadfiltershelvingloComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-filter-shelving-lo.svg";
import fadfoldback from "../svgs/fad-foldback.svg";
import { ReactComponent as fadfoldbackComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-foldback.svg";
import fadforward from "../svgs/fad-forward.svg";
import { ReactComponent as fadforwardComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-forward.svg";
import fadhexpand from "../svgs/fad-h-expand.svg";
import { ReactComponent as fadhexpandComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-h-expand.svg";
import fadhardclip from "../svgs/fad-hardclip.svg";
import { ReactComponent as fadhardclipComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-hardclip.svg";
import fadhardclipcurve from "../svgs/fad-hardclipcurve.svg";
import { ReactComponent as fadhardclipcurveComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-hardclipcurve.svg";
import fadheadphones from "../svgs/fad-headphones.svg";
import { ReactComponent as fadheadphonesComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-headphones.svg";
import fadkeyboard from "../svgs/fad-keyboard.svg";
import { ReactComponent as fadkeyboardComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-keyboard.svg";
import fadlock from "../svgs/fad-lock.svg";
import { ReactComponent as fadlockComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-lock.svg";
import fadlogoaax from "../svgs/fad-logo-aax.svg";
import { ReactComponent as fadlogoaaxComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-logo-aax.svg";
import fadlogoabletonlink from "../svgs/fad-logo-abletonlink.svg";
import { ReactComponent as fadlogoabletonlinkComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-logo-abletonlink.svg";
import fadlogoau from "../svgs/fad-logo-au.svg";
import { ReactComponent as fadlogoauComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-logo-au.svg";
import fadlogoaudacity from "../svgs/fad-logo-audacity.svg";
import { ReactComponent as fadlogoaudacityComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-logo-audacity.svg";
import fadlogoaudiobus from "../svgs/fad-logo-audiobus.svg";
import { ReactComponent as fadlogoaudiobusComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-logo-audiobus.svg";
import fadlogocubase from "../svgs/fad-logo-cubase.svg";
import { ReactComponent as fadlogocubaseComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-logo-cubase.svg";
import fadlogofl from "../svgs/fad-logo-fl.svg";
import { ReactComponent as fadlogoflComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-logo-fl.svg";
import fadlogojuce from "../svgs/fad-logo-juce.svg";
import { ReactComponent as fadlogojuceComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-logo-juce.svg";
import fadlogoladspa from "../svgs/fad-logo-ladspa.svg";
import { ReactComponent as fadlogoladspaComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-logo-ladspa.svg";
import fadlogolive from "../svgs/fad-logo-live.svg";
import { ReactComponent as fadlogoliveComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-logo-live.svg";
import fadlogolv2 from "../svgs/fad-logo-lv2.svg";
import { ReactComponent as fadlogolv2Component } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-logo-lv2.svg";
import fadlogoprotools from "../svgs/fad-logo-protools.svg";
import { ReactComponent as fadlogoprotoolsComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-logo-protools.svg";
import fadlogorackext from "../svgs/fad-logo-rackext.svg";
import { ReactComponent as fadlogorackextComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-logo-rackext.svg";
import fadlogoreaper from "../svgs/fad-logo-reaper.svg";
import { ReactComponent as fadlogoreaperComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-logo-reaper.svg";
import fadlogoreason from "../svgs/fad-logo-reason.svg";
import { ReactComponent as fadlogoreasonComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-logo-reason.svg";
import fadlogorewire from "../svgs/fad-logo-rewire.svg";
import { ReactComponent as fadlogorewireComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-logo-rewire.svg";
import fadlogostudioone from "../svgs/fad-logo-studioone.svg";
import { ReactComponent as fadlogostudiooneComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-logo-studioone.svg";
import fadlogotracktion from "../svgs/fad-logo-tracktion.svg";
import { ReactComponent as fadlogotracktionComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-logo-tracktion.svg";
import fadlogovst from "../svgs/fad-logo-vst.svg";
import { ReactComponent as fadlogovstComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-logo-vst.svg";
import fadlogowaveform from "../svgs/fad-logo-waveform.svg";
import { ReactComponent as fadlogowaveformComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-logo-waveform.svg";
import fadloop from "../svgs/fad-loop.svg";
import { ReactComponent as fadloopComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-loop.svg";
import fadmetronome from "../svgs/fad-metronome.svg";
import { ReactComponent as fadmetronomeComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-metronome.svg";
import fadmicrophone from "../svgs/fad-microphone.svg";
import { ReactComponent as fadmicrophoneComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-microphone.svg";
import fadmidiplug from "../svgs/fad-midiplug.svg";
import { ReactComponent as fadmidiplugComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-midiplug.svg";
import fadmodrandom from "../svgs/fad-modrandom.svg";
import { ReactComponent as fadmodrandomComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-modrandom.svg";
import fadmodsawdown from "../svgs/fad-modsawdown.svg";
import { ReactComponent as fadmodsawdownComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-modsawdown.svg";
import fadmodsawup from "../svgs/fad-modsawup.svg";
import { ReactComponent as fadmodsawupComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-modsawup.svg";
import fadmodsh from "../svgs/fad-modsh.svg";
import { ReactComponent as fadmodshComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-modsh.svg";
import fadmodsine from "../svgs/fad-modsine.svg";
import { ReactComponent as fadmodsineComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-modsine.svg";
import fadmodsquare from "../svgs/fad-modsquare.svg";
import { ReactComponent as fadmodsquareComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-modsquare.svg";
import fadmodtri from "../svgs/fad-modtri.svg";
import { ReactComponent as fadmodtriComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-modtri.svg";
import fadmodularplug from "../svgs/fad-modularplug.svg";
import { ReactComponent as fadmodularplugComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-modularplug.svg";
import fadmono from "../svgs/fad-mono.svg";
import { ReactComponent as fadmonoComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-mono.svg";
import fadmute from "../svgs/fad-mute.svg";
import { ReactComponent as fadmuteComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-mute.svg";
import fadnext from "../svgs/fad-next.svg";
import { ReactComponent as fadnextComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-next.svg";
import fadopen from "../svgs/fad-open.svg";
import { ReactComponent as fadopenComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-open.svg";
import fadpaste from "../svgs/fad-paste.svg";
import { ReactComponent as fadpasteComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-paste.svg";
import fadpause from "../svgs/fad-pause.svg";
import { ReactComponent as fadpauseComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-pause.svg";
import fadpen from "../svgs/fad-pen.svg";
import { ReactComponent as fadpenComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-pen.svg";
import fadphase from "../svgs/fad-phase.svg";
import { ReactComponent as fadphaseComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-phase.svg";
import fadplay from "../svgs/fad-play.svg";
import { ReactComponent as fadplayComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-play.svg";
import fadpointer from "../svgs/fad-pointer.svg";
import { ReactComponent as fadpointerComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-pointer.svg";
import fadpowerswitch from "../svgs/fad-powerswitch.svg";
import { ReactComponent as fadpowerswitchComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-powerswitch.svg";
import fadpreseta from "../svgs/fad-preset-a.svg";
import { ReactComponent as fadpresetaComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-preset-a.svg";
import fadpresetab from "../svgs/fad-preset-ab.svg";
import { ReactComponent as fadpresetabComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-preset-ab.svg";
import fadpresetb from "../svgs/fad-preset-b.svg";
import { ReactComponent as fadpresetbComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-preset-b.svg";
import fadpresetba from "../svgs/fad-preset-ba.svg";
import { ReactComponent as fadpresetbaComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-preset-ba.svg";
import fadprev from "../svgs/fad-prev.svg";
import { ReactComponent as fadprevComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-prev.svg";
import fadpunchin from "../svgs/fad-punch-in.svg";
import { ReactComponent as fadpunchinComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-punch-in.svg";
import fadpunchout from "../svgs/fad-punch-out.svg";
import { ReactComponent as fadpunchoutComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-punch-out.svg";
import fadram from "../svgs/fad-ram.svg";
import { ReactComponent as fadramComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-ram.svg";
import fadrandom1dice from "../svgs/fad-random-1dice.svg";
import { ReactComponent as fadrandom1diceComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-random-1dice.svg";
import fadrandom2dice from "../svgs/fad-random-2dice.svg";
import { ReactComponent as fadrandom2diceComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-random-2dice.svg";
import fadrecord from "../svgs/fad-record.svg";
import { ReactComponent as fadrecordComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-record.svg";
import fadredo from "../svgs/fad-redo.svg";
import { ReactComponent as fadredoComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-redo.svg";
import fadrepeatone from "../svgs/fad-repeat-one.svg";
import { ReactComponent as fadrepeatoneComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-repeat-one.svg";
import fadrepeat from "../svgs/fad-repeat.svg";
import { ReactComponent as fadrepeatComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-repeat.svg";
import fadrew from "../svgs/fad-rew.svg";
import { ReactComponent as fadrewComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-rew.svg";
import fadroundswitchoff from "../svgs/fad-roundswitch-off.svg";
import { ReactComponent as fadroundswitchoffComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-roundswitch-off.svg";
import fadroundswitchon from "../svgs/fad-roundswitch-on.svg";
import { ReactComponent as fadroundswitchonComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-roundswitch-on.svg";
import fadsave from "../svgs/fad-save.svg";
import { ReactComponent as fadsaveComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-save.svg";
import fadsaveas from "../svgs/fad-saveas.svg";
import { ReactComponent as fadsaveasComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-saveas.svg";
import fadscissors from "../svgs/fad-scissors.svg";
import { ReactComponent as fadscissorsComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-scissors.svg";
import fadshuffle from "../svgs/fad-shuffle.svg";
import { ReactComponent as fadshuffleComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-shuffle.svg";
import fadsliderround1 from "../svgs/fad-slider-round-1.svg";
import { ReactComponent as fadsliderround1Component } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-slider-round-1.svg";
import fadsliderround2 from "../svgs/fad-slider-round-2.svg";
import { ReactComponent as fadsliderround2Component } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-slider-round-2.svg";
import fadsliderround3 from "../svgs/fad-slider-round-3.svg";
import { ReactComponent as fadsliderround3Component } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-slider-round-3.svg";
import fadsliderhandle1 from "../svgs/fad-sliderhandle-1.svg";
import { ReactComponent as fadsliderhandle1Component } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-sliderhandle-1.svg";
import fadsliderhandle2 from "../svgs/fad-sliderhandle-2.svg";
import { ReactComponent as fadsliderhandle2Component } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-sliderhandle-2.svg";
import fadsoftclip from "../svgs/fad-softclip.svg";
import { ReactComponent as fadsoftclipComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-softclip.svg";
import fadsoftclipcurve from "../svgs/fad-softclipcurve.svg";
import { ReactComponent as fadsoftclipcurveComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-softclipcurve.svg";
import fadsolo from "../svgs/fad-solo.svg";
import { ReactComponent as fadsoloComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-solo.svg";
import fadspeaker from "../svgs/fad-speaker.svg";
import { ReactComponent as fadspeakerComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-speaker.svg";
import fadsquareswitchoff from "../svgs/fad-squareswitch-off.svg";
import { ReactComponent as fadsquareswitchoffComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-squareswitch-off.svg";
import fadsquareswitchon from "../svgs/fad-squareswitch-on.svg";
import { ReactComponent as fadsquareswitchonComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-squareswitch-on.svg";
import fadstereo from "../svgs/fad-stereo.svg";
import { ReactComponent as fadstereoComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-stereo.svg";
import fadstop from "../svgs/fad-stop.svg";
import { ReactComponent as fadstopComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-stop.svg";
import fadthunderbolt from "../svgs/fad-thunderbolt.svg";
import { ReactComponent as fadthunderboltComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-thunderbolt.svg";
import fadtimeselect from "../svgs/fad-timeselect.svg";
import { ReactComponent as fadtimeselectComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-timeselect.svg";
import fadundo from "../svgs/fad-undo.svg";
import { ReactComponent as fadundoComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-undo.svg";
import fadunlock from "../svgs/fad-unlock.svg";
import { ReactComponent as fadunlockComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-unlock.svg";
import fadusb from "../svgs/fad-usb.svg";
import { ReactComponent as fadusbComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-usb.svg";
import fadvexpand from "../svgs/fad-v-expand.svg";
import { ReactComponent as fadvexpandComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-v-expand.svg";
import fadvroundswitchoff from "../svgs/fad-vroundswitch-off.svg";
import { ReactComponent as fadvroundswitchoffComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-vroundswitch-off.svg";
import fadvroundswitchon from "../svgs/fad-vroundswitch-on.svg";
import { ReactComponent as fadvroundswitchonComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-vroundswitch-on.svg";
import fadvsquareswitchoff from "../svgs/fad-vsquareswitch-off.svg";
import { ReactComponent as fadvsquareswitchoffComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-vsquareswitch-off.svg";
import fadvsquareswitchon from "../svgs/fad-vsquareswitch-on.svg";
import { ReactComponent as fadvsquareswitchonComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-vsquareswitch-on.svg";
import fadwaveform from "../svgs/fad-waveform.svg";
import { ReactComponent as fadwaveformComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-waveform.svg";
import fadxlrplug from "../svgs/fad-xlrplug.svg";
import { ReactComponent as fadxlrplugComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-xlrplug.svg";
import fadzoomin from "../svgs/fad-zoomin.svg";
import { ReactComponent as fadzoominComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-zoomin.svg";
import fadzoomout from "../svgs/fad-zoomout.svg";
import { ReactComponent as fadzoomoutComponent } from "@svgr/webpack?-svgo,+titleProp,+ref!../svgs/fad-zoomout.svg"; // eslint-disable-next-line import/no-anonymous-default-export
export default {
    fadADR: fadADR,
    fadADSR: fadADSR,
    fadAHDSR: fadAHDSR,
    fadAR: fadAR,
    fadarmrecording: fadarmrecording,
    fadarpchord: fadarpchord,
    fadarpdown: fadarpdown,
    fadarpdownandup: fadarpdownandup,
    fadarpdownup: fadarpdownup,
    fadarpplayorder: fadarpplayorder,
    fadarprandom: fadarprandom,
    fadarpup: fadarpup,
    fadarpupandown: fadarpupandown,
    fadarpupdown: fadarpupdown,
    fadarrowshorz: fadarrowshorz,
    fadarrowsvert: fadarrowsvert,
    fadautomation2p: fadautomation2p,
    fadautomation3p: fadautomation3p,
    fadautomation4p: fadautomation4p,
    fadbackward: fadbackward,
    fadbluetooth: fadbluetooth,
    fadcaretdown: fadcaretdown,
    fadcaretleft: fadcaretleft,
    fadcaretright: fadcaretright,
    fadcaretup: fadcaretup,
    fadclose: fadclose,
    fadcopy: fadcopy,
    fadcpu: fadcpu,
    fadcutter: fadcutter,
    faddigitalcolon: faddigitalcolon,
    faddigitaldot: faddigitaldot,
    faddigital0: faddigital0,
    faddigital1: faddigital1,
    faddigital2: faddigital2,
    faddigital3: faddigital3,
    faddigital4: faddigital4,
    faddigital5: faddigital5,
    faddigital6: faddigital6,
    faddigital7: faddigital7,
    faddigital8: faddigital8,
    faddigital9: faddigital9,
    faddiskio: faddiskio,
    faddrumpad: faddrumpad,
    fadduplicate: fadduplicate,
    faderaser: faderaser,
    fadffwd: fadffwd,
    fadfilterbandpass: fadfilterbandpass,
    fadfilterbell: fadfilterbell,
    fadfilterbypass: fadfilterbypass,
    fadfilterhighpass: fadfilterhighpass,
    fadfilterlowpass: fadfilterlowpass,
    fadfilternotch: fadfilternotch,
    // fadfilterrezhighpass,
    fadfilterrezlowpass: fadfilterrezlowpass,
    fadfiltershelvinghi: fadfiltershelvinghi,
    fadfiltershelvinglo: fadfiltershelvinglo,
    fadfoldback: fadfoldback,
    fadforward: fadforward,
    fadhexpand: fadhexpand,
    fadhardclip: fadhardclip,
    fadhardclipcurve: fadhardclipcurve,
    fadheadphones: fadheadphones,
    fadkeyboard: fadkeyboard,
    fadlock: fadlock,
    fadlogoaax: fadlogoaax,
    fadlogoabletonlink: fadlogoabletonlink,
    fadlogoau: fadlogoau,
    fadlogoaudacity: fadlogoaudacity,
    fadlogoaudiobus: fadlogoaudiobus,
    fadlogocubase: fadlogocubase,
    fadlogofl: fadlogofl,
    fadlogojuce: fadlogojuce,
    fadlogoladspa: fadlogoladspa,
    fadlogolive: fadlogolive,
    fadlogolv2: fadlogolv2,
    fadlogoprotools: fadlogoprotools,
    fadlogorackext: fadlogorackext,
    fadlogoreaper: fadlogoreaper,
    fadlogoreason: fadlogoreason,
    fadlogorewire: fadlogorewire,
    fadlogostudioone: fadlogostudioone,
    fadlogotracktion: fadlogotracktion,
    fadlogovst: fadlogovst,
    fadlogowaveform: fadlogowaveform,
    fadloop: fadloop,
    fadmetronome: fadmetronome,
    fadmicrophone: fadmicrophone,
    fadmidiplug: fadmidiplug,
    fadmodrandom: fadmodrandom,
    fadmodsawdown: fadmodsawdown,
    fadmodsawup: fadmodsawup,
    fadmodsh: fadmodsh,
    fadmodsine: fadmodsine,
    fadmodsquare: fadmodsquare,
    fadmodtri: fadmodtri,
    fadmodularplug: fadmodularplug,
    fadmono: fadmono,
    fadmute: fadmute,
    fadnext: fadnext,
    fadopen: fadopen,
    fadpaste: fadpaste,
    fadpause: fadpause,
    fadpen: fadpen,
    fadphase: fadphase,
    fadplay: fadplay,
    fadpointer: fadpointer,
    fadpowerswitch: fadpowerswitch,
    fadpreseta: fadpreseta,
    fadpresetab: fadpresetab,
    fadpresetb: fadpresetb,
    fadpresetba: fadpresetba,
    fadprev: fadprev,
    fadpunchin: fadpunchin,
    fadpunchout: fadpunchout,
    fadram: fadram,
    fadrandom1dice: fadrandom1dice,
    fadrandom2dice: fadrandom2dice,
    fadrecord: fadrecord,
    fadredo: fadredo,
    fadrepeatone: fadrepeatone,
    fadrepeat: fadrepeat,
    fadrew: fadrew,
    fadroundswitchoff: fadroundswitchoff,
    fadroundswitchon: fadroundswitchon,
    fadsave: fadsave,
    fadsaveas: fadsaveas,
    fadscissors: fadscissors,
    fadshuffle: fadshuffle,
    fadsliderround1: fadsliderround1,
    fadsliderround2: fadsliderround2,
    fadsliderround3: fadsliderround3,
    fadsliderhandle1: fadsliderhandle1,
    fadsliderhandle2: fadsliderhandle2,
    fadsoftclip: fadsoftclip,
    fadsoftclipcurve: fadsoftclipcurve,
    fadsolo: fadsolo,
    fadspeaker: fadspeaker,
    fadsquareswitchoff: fadsquareswitchoff,
    fadsquareswitchon: fadsquareswitchon,
    fadstereo: fadstereo,
    fadstop: fadstop,
    fadthunderbolt: fadthunderbolt,
    fadtimeselect: fadtimeselect,
    fadundo: fadundo,
    fadunlock: fadunlock,
    fadusb: fadusb,
    fadvexpand: fadvexpand,
    fadvroundswitchoff: fadvroundswitchoff,
    fadvroundswitchon: fadvroundswitchon,
    fadvsquareswitchoff: fadvsquareswitchoff,
    fadvsquareswitchon: fadvsquareswitchon,
    fadwaveform: fadwaveform,
    fadxlrplug: fadxlrplug,
    fadzoomin: fadzoomin,
    fadzoomout: fadzoomout
};
export var SVGComponents = {
    fadADRComponent: fadADRComponent,
    fadADSRComponent: fadADSRComponent,
    fadAHDSRComponent: fadAHDSRComponent,
    fadARComponent: fadARComponent,
    fadarmrecordingComponent: fadarmrecordingComponent,
    fadarpchordComponent: fadarpchordComponent,
    fadarpdownComponent: fadarpdownComponent,
    fadarpdownandupComponent: fadarpdownandupComponent,
    fadarpdownupComponent: fadarpdownupComponent,
    fadarpplayorderComponent: fadarpplayorderComponent,
    fadarprandomComponent: fadarprandomComponent,
    fadarpupComponent: fadarpupComponent,
    fadarpupandownComponent: fadarpupandownComponent,
    fadarpupdownComponent: fadarpupdownComponent,
    fadarrowshorzComponent: fadarrowshorzComponent,
    fadarrowsvertComponent: fadarrowsvertComponent,
    fadautomation2pComponent: fadautomation2pComponent,
    fadautomation3pComponent: fadautomation3pComponent,
    fadautomation4pComponent: fadautomation4pComponent,
    fadbackwardComponent: fadbackwardComponent,
    fadbluetoothComponent: fadbluetoothComponent,
    fadcaretdownComponent: fadcaretdownComponent,
    fadcaretleftComponent: fadcaretleftComponent,
    fadcaretrightComponent: fadcaretrightComponent,
    fadcaretupComponent: fadcaretupComponent,
    fadcloseComponent: fadcloseComponent,
    fadcopyComponent: fadcopyComponent,
    fadcpuComponent: fadcpuComponent,
    fadcutterComponent: fadcutterComponent,
    faddigitalcolonComponent: faddigitalcolonComponent,
    faddigitaldotComponent: faddigitaldotComponent,
    faddigital0Component: faddigital0Component,
    faddigital1Component: faddigital1Component,
    faddigital2Component: faddigital2Component,
    faddigital3Component: faddigital3Component,
    faddigital4Component: faddigital4Component,
    faddigital5Component: faddigital5Component,
    faddigital6Component: faddigital6Component,
    faddigital7Component: faddigital7Component,
    faddigital8Component: faddigital8Component,
    faddigital9Component: faddigital9Component,
    faddiskioComponent: faddiskioComponent,
    faddrumpadComponent: faddrumpadComponent,
    fadduplicateComponent: fadduplicateComponent,
    faderaserComponent: faderaserComponent,
    fadffwdComponent: fadffwdComponent,
    fadfilterbandpassComponent: fadfilterbandpassComponent,
    fadfilterbellComponent: fadfilterbellComponent,
    fadfilterbypassComponent: fadfilterbypassComponent,
    fadfilterhighpassComponent: fadfilterhighpassComponent,
    fadfilterlowpassComponent: fadfilterlowpassComponent,
    fadfilternotchComponent: fadfilternotchComponent,
    // fadfilterrezhighpassComponent,
    fadfilterrezlowpassComponent: fadfilterrezlowpassComponent,
    fadfiltershelvinghiComponent: fadfiltershelvinghiComponent,
    fadfiltershelvingloComponent: fadfiltershelvingloComponent,
    fadfoldbackComponent: fadfoldbackComponent,
    fadforwardComponent: fadforwardComponent,
    fadhexpandComponent: fadhexpandComponent,
    fadhardclipComponent: fadhardclipComponent,
    fadhardclipcurveComponent: fadhardclipcurveComponent,
    fadheadphonesComponent: fadheadphonesComponent,
    fadkeyboardComponent: fadkeyboardComponent,
    fadlockComponent: fadlockComponent,
    fadlogoaaxComponent: fadlogoaaxComponent,
    fadlogoabletonlinkComponent: fadlogoabletonlinkComponent,
    fadlogoauComponent: fadlogoauComponent,
    fadlogoaudacityComponent: fadlogoaudacityComponent,
    fadlogoaudiobusComponent: fadlogoaudiobusComponent,
    fadlogocubaseComponent: fadlogocubaseComponent,
    fadlogoflComponent: fadlogoflComponent,
    fadlogojuceComponent: fadlogojuceComponent,
    fadlogoladspaComponent: fadlogoladspaComponent,
    fadlogoliveComponent: fadlogoliveComponent,
    fadlogolv2Component: fadlogolv2Component,
    fadlogoprotoolsComponent: fadlogoprotoolsComponent,
    fadlogorackextComponent: fadlogorackextComponent,
    fadlogoreaperComponent: fadlogoreaperComponent,
    fadlogoreasonComponent: fadlogoreasonComponent,
    fadlogorewireComponent: fadlogorewireComponent,
    fadlogostudiooneComponent: fadlogostudiooneComponent,
    fadlogotracktionComponent: fadlogotracktionComponent,
    fadlogovstComponent: fadlogovstComponent,
    fadlogowaveformComponent: fadlogowaveformComponent,
    fadloopComponent: fadloopComponent,
    fadmetronomeComponent: fadmetronomeComponent,
    fadmicrophoneComponent: fadmicrophoneComponent,
    fadmidiplugComponent: fadmidiplugComponent,
    fadmodrandomComponent: fadmodrandomComponent,
    fadmodsawdownComponent: fadmodsawdownComponent,
    fadmodsawupComponent: fadmodsawupComponent,
    fadmodshComponent: fadmodshComponent,
    fadmodsineComponent: fadmodsineComponent,
    fadmodsquareComponent: fadmodsquareComponent,
    fadmodtriComponent: fadmodtriComponent,
    fadmodularplugComponent: fadmodularplugComponent,
    fadmonoComponent: fadmonoComponent,
    fadmuteComponent: fadmuteComponent,
    fadnextComponent: fadnextComponent,
    fadopenComponent: fadopenComponent,
    fadpasteComponent: fadpasteComponent,
    fadpauseComponent: fadpauseComponent,
    fadpenComponent: fadpenComponent,
    fadphaseComponent: fadphaseComponent,
    fadplayComponent: fadplayComponent,
    fadpointerComponent: fadpointerComponent,
    fadpowerswitchComponent: fadpowerswitchComponent,
    fadpresetaComponent: fadpresetaComponent,
    fadpresetabComponent: fadpresetabComponent,
    fadpresetbComponent: fadpresetbComponent,
    fadpresetbaComponent: fadpresetbaComponent,
    fadprevComponent: fadprevComponent,
    fadpunchinComponent: fadpunchinComponent,
    fadpunchoutComponent: fadpunchoutComponent,
    fadramComponent: fadramComponent,
    fadrandom1diceComponent: fadrandom1diceComponent,
    fadrandom2diceComponent: fadrandom2diceComponent,
    fadrecordComponent: fadrecordComponent,
    fadredoComponent: fadredoComponent,
    fadrepeatoneComponent: fadrepeatoneComponent,
    fadrepeatComponent: fadrepeatComponent,
    fadrewComponent: fadrewComponent,
    fadroundswitchoffComponent: fadroundswitchoffComponent,
    fadroundswitchonComponent: fadroundswitchonComponent,
    fadsaveComponent: fadsaveComponent,
    fadsaveasComponent: fadsaveasComponent,
    fadscissorsComponent: fadscissorsComponent,
    fadshuffleComponent: fadshuffleComponent,
    fadsliderround1Component: fadsliderround1Component,
    fadsliderround2Component: fadsliderround2Component,
    fadsliderround3Component: fadsliderround3Component,
    fadsliderhandle1Component: fadsliderhandle1Component,
    fadsliderhandle2Component: fadsliderhandle2Component,
    fadsoftclipComponent: fadsoftclipComponent,
    fadsoftclipcurveComponent: fadsoftclipcurveComponent,
    fadsoloComponent: fadsoloComponent,
    fadspeakerComponent: fadspeakerComponent,
    fadsquareswitchoffComponent: fadsquareswitchoffComponent,
    fadsquareswitchonComponent: fadsquareswitchonComponent,
    fadstereoComponent: fadstereoComponent,
    fadstopComponent: fadstopComponent,
    fadthunderboltComponent: fadthunderboltComponent,
    fadtimeselectComponent: fadtimeselectComponent,
    fadundoComponent: fadundoComponent,
    fadunlockComponent: fadunlockComponent,
    fadusbComponent: fadusbComponent,
    fadvexpandComponent: fadvexpandComponent,
    fadvroundswitchoffComponent: fadvroundswitchoffComponent,
    fadvroundswitchonComponent: fadvroundswitchonComponent,
    fadvsquareswitchoffComponent: fadvsquareswitchoffComponent,
    fadvsquareswitchonComponent: fadvsquareswitchonComponent,
    fadwaveformComponent: fadwaveformComponent,
    fadxlrplugComponent: fadxlrplugComponent,
    fadzoominComponent: fadzoominComponent,
    fadzoomoutComponent: fadzoomoutComponent
};
