import { createSelector } from "reselect";
import { path, pathOr, pickBy, propEq, values } from "ramda";
import Fuse from "fuse.js";
export var selectUserFollowing = function selectUserFollowing(profileUid) {
    return function (store) {
        if (profileUid) {
            var state = store.ProfileReducer;
            return pathOr([], ["profiles", profileUid, "following"], state);
        }
        else {
            return [];
        }
    };
};
export var selectUserProjects = function selectUserProjects(profileUid) {
    return function (store) {
        if (profileUid) {
            var state = store.ProjectsReducer.projects;
            return values(pickBy(propEq("userUid", profileUid), state));
        }
        else {
            return [];
        }
    };
};
export var selectProjectFilterString = function selectProjectFilterString(store) {
    var state = store.ProfileReducer;
    return state.projectFilterString;
};
export var selectFollowingFilterString = function selectFollowingFilterString(store) {
    var state = store.ProfileReducer;
    return state.followingFilterString;
};
export var selectUserProfile = function selectUserProfile(profileUid) {
    return function (store) {
        if (profileUid) {
            var state = store.ProfileReducer;
            return path(["profiles", profileUid], state);
        }
        else {
            return;
        }
    };
};
export var selectUserName = function selectUserName(profileUid) {
    return function (store) {
        if (profileUid) {
            var state = store.ProfileReducer;
            return path(["profiles", profileUid, "username"], state);
        }
        else {
            return;
        }
    };
};
export var selectLoggedInUserStars = function selectLoggedInUserStars(store) {
    var loggedInUid = store.LoginReducer.loggedInUid;
    if (loggedInUid) {
        var state = store.ProfileReducer;
        return pathOr([], ["profiles", loggedInUid, "starred"], state);
    }
    else {
        return [];
    }
};
export var selectFilteredUserProjects = function selectFilteredUserProjects(profileUid) {
    return createSelector([selectUserProjects(profileUid), selectProjectFilterString], function (userProjects, projectFilterString) {
        var result = [];
        if (projectFilterString === undefined || projectFilterString === "") {
            result = userProjects;
        }
        else {
            var options = {
                shouldSort: true,
                keys: ["description", "name", "tags"]
            };
            var fuse = new Fuse(userProjects, options);
            result = fuse.search(projectFilterString).map(function (x) {
                return x.item;
            });
        }
        return result;
    });
};
export var selectFilteredUserFollowing = function selectFilteredUserFollowing(profileUid) {
    return function (store) {
        return createSelector([selectUserFollowing(profileUid), selectFollowingFilterString], function (userFollowing, followingFilterString) {
            var followingProfiles = userFollowing.map(function (profileUid) {
                return selectUserProfile(profileUid)(store) || {};
            });
            if (followingFilterString === undefined || followingFilterString === "") {
                return followingProfiles;
            }
            var options = {
                shouldSort: true,
                threshold: 0.4,
                location: 0,
                distance: 100,
                maxPatternLength: 32,
                minMatchCharLength: 1,
                keys: ["username", "bio", "displayName"]
            };
            var fuse = new Fuse(followingProfiles, options);
            var result = fuse.search(followingFilterString);
            return result;
        })(store);
    };
};
export var selectFilteredUserFollowers = function selectFilteredUserFollowers(profileUid) {
    return function (store) {
        var state = store.ProfileReducer;
        var followerUids = pathOr([], ["profiles", profileUid, "followers"], state);
        return (followerUids || []).map(function (followerUid) {
            return path(["profiles", followerUid], state);
        });
    };
};
export var selectUserImageURL = function selectUserImageURL(profileUid) {
    return function (store) {
        if (profileUid) {
            var state = store.ProfileReducer;
            return pathOr("", ["profiles", profileUid, "photoUrl"], state);
        }
        else {
            return;
        }
    };
};
export var selectCurrentlyPlayingProject = function selectCurrentlyPlayingProject(store) {
    var state = store.ProfileReducer;
    return state.currentlyPlayingProject;
};
export var selectCurrentTagText = function selectCurrentTagText(store) {
    var state = store.ProfileReducer;
    return state.currentTagText;
};
export var selectTags = function selectTags(projectUid) {
    return function (store) {
        return pathOr([], ["ProjectsReducer", "projects", projectUid, "tags"], store);
    };
};
export var selectProfileStars = function selectProfileStars(profileUid) {
    return function (store) {
        return pathOr([], ["ProfileReducer", "profiles", profileUid, "stars"], store);
    };
};
export var selectAllUserProjectUids = function selectAllUserProjectUids(profileUid) {
    return function (store) {
        if (profileUid) {
            var allUserProjects = values(pathOr({}, ["ProjectsReducer", "projects"], store)).filter(function (p) {
                return p.userUid === profileUid;
            });
            return allUserProjects.map(function (p) {
                return p.projectUid;
            });
        }
        else {
            return [];
        }
    };
};
export var selectAllTagsFromUser = function selectAllTagsFromUser(profileUid) {
    return function (store) {
        return pathOr([], ["ProfileReducer", "profiles", profileUid, "allTags"], store);
    };
};
export var selectProfileProjectsCount = function selectProfileProjectsCount(profileUid) {
    return function (store) {
        return pathOr({
            all: 0,
            default: 0
        }, ["ProfileReducer", "profiles", profileUid, "projectsCount"], store);
    };
};
// export const selectProjectIconStyle = (
//     projectUid: string
// ): ((store: IStore) => any) => (store) => {
//     const proj = pathOr({}, ["ProjectsReducer", "projects", projectUid], store);
//     return {
//         iconBackgroundColor: prop("iconBackgroundColor", proj),
//         iconForegroundColor: prop("iconForegroundColor", proj),
//         iconName: prop("iconName", proj)
//     };
// };
