/* eslint-disable unicorn/prefer-spread,unicorn/prefer-module */
import { configureStore } from "@reduxjs/toolkit";
import { useDispatch as useDispatchOriginal, useSelector as useSelectorOriginal } from "react-redux";
import RootReducer from "./root-reducer";
import { createReduxHistoryContext } from "redux-first-history";
import { createBrowserHistory } from "history";
import "symbol-observable";
// the manual as a dock is an iframe
// just to make the dev little faster
// we don't include the devtools
var insideIframe = !!window.frameElement;
var _createReduxHistoryCo = createReduxHistoryContext({
    history: createBrowserHistory()
}), createReduxHistory = _createReduxHistoryCo.createReduxHistory, routerMiddleware = _createReduxHistoryCo.routerMiddleware, routerReducer = _createReduxHistoryCo.routerReducer;
var rootReducer = RootReducer({
    routerReducer: routerReducer
});
export var store = configureStore({
    reducer: rootReducer,
    devTools: !insideIframe,
    middleware: function middleware(getDefaultMiddleware) {
        return getDefaultMiddleware().concat(routerMiddleware);
    }
});
export var history = createReduxHistory(store);
export { createAsyncThunk } from "@reduxjs/toolkit";
export var useDispatch = useDispatchOriginal;
export var useSelector = useSelectorOriginal;
