import { SIGNIN_SUCCESS, LOG_OUT, UPDATE_USER_PROFILE } from "../components/login/types";
import { mergeAll } from "ramda";
var IdReducer = function IdReducer() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var action = arguments.length > 1 ? arguments[1] : undefined;
    switch (action.type) {
        case SIGNIN_SUCCESS:
            {
                return {
                    name: action.user.displayName,
                    email: action.user.email,
                    photoUrl: action.user.photoURL
                };
            }
        case UPDATE_USER_PROFILE:
            {
                return action.profile ? mergeAll([state, action.profile]) : state;
            }
        case LOG_OUT:
            {
                return {};
            }
        default:
            {
                return state;
            }
    }
};
export default IdReducer;
