function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
export var opcodeContainer = process.env.NODE_ENV === "production" ? {
    name: "2c04xh",
    styles: "display:inline-block;padding:0;margin:0 12px;height:100%"
} : {
    name: "18uloxs-opcodeContainer",
    styles: "display:inline-block;padding:0;margin:0 12px;height:100%;label:opcodeContainer;",
    map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9ob21lL3J1bm5lci93b3JrL3dlYi1pZGUvd2ViLWlkZS9zcmMvY29tcG9uZW50cy9tYW51YWwvc3R5bGVzLnRzeCJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFFa0MiLCJmaWxlIjoiL2hvbWUvcnVubmVyL3dvcmsvd2ViLWlkZS93ZWItaWRlL3NyYy9jb21wb25lbnRzL21hbnVhbC9zdHlsZXMudHN4Iiwic291cmNlc0NvbnRlbnQiOlsiaW1wb3J0IHsgY3NzIH0gZnJvbSBcIkBlbW90aW9uL3JlYWN0XCI7XG5cbmV4cG9ydCBjb25zdCBvcGNvZGVDb250YWluZXIgPSBjc3NgXG4gICAgZGlzcGxheTogaW5saW5lLWJsb2NrO1xuICAgIHBhZGRpbmc6IDA7XG4gICAgbWFyZ2luOiAwIDEycHg7XG4gICAgaGVpZ2h0OiAxMDAlO1xuYDtcblxuZXhwb3J0IGNvbnN0IGVudHJ5ID0gY3NzYFxuICAgIGRpc3BsYXk6IGJsb2NrO1xuICAgIGZvbnQtc2l6ZTogMTRweDtcbiAgICB0ZXh0LWRlY29yYXRpb246IG5vbmU7XG4gICAgbWFyZ2luOiAzcHggMDtcbiAgICB3aGl0ZS1zcGFjZTogbm93cmFwO1xuICAgIHRleHQtb3ZlcmZsb3c6IGVsbGlwc2lzO1xuICAgIG92ZXJmbG93OiBoaWRkZW47XG4gICAgY29sb3I6IHdoaXRlO1xuICAgICY6aG92ZXIge1xuICAgICAgICBiYWNrZ3JvdW5kLWNvbG9yOiByZ2JhKDI1NSwgMjU1LCAyNTUsIDAuNSk7XG4gICAgfVxuXG4gICAgc3BhbiB7XG4gICAgICAgIGZvbnQtd2VpZ2h0OiA1MDA7XG4gICAgICAgIGRpc3BsYXk6IGlubGluZS1ibG9jaztcbiAgICB9XG5cbiAgICBwIHtcbiAgICAgICAgcGFkZGluZzogMDtcbiAgICAgICAgbWFyZ2luOiAwO1xuICAgICAgICBkaXNwbGF5OiBpbml0aWFsO1xuICAgIH1cbmA7XG4iXX0= */",
    toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
export var entry = process.env.NODE_ENV === "production" ? {
    name: "19jnn8t",
    styles: "display:block;font-size:14px;text-decoration:none;margin:3px 0;white-space:nowrap;text-overflow:ellipsis;overflow:hidden;color:white;&:hover{background-color:rgba(255, 255, 255, 0.5);}span{font-weight:500;display:inline-block;}p{padding:0;margin:0;display:initial;}"
} : {
    name: "x43yf0-entry",
    styles: "display:block;font-size:14px;text-decoration:none;margin:3px 0;white-space:nowrap;text-overflow:ellipsis;overflow:hidden;color:white;&:hover{background-color:rgba(255, 255, 255, 0.5);}span{font-weight:500;display:inline-block;}p{padding:0;margin:0;display:initial;};label:entry;",
    map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9ob21lL3J1bm5lci93b3JrL3dlYi1pZGUvd2ViLWlkZS9zcmMvY29tcG9uZW50cy9tYW51YWwvc3R5bGVzLnRzeCJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFTd0IiLCJmaWxlIjoiL2hvbWUvcnVubmVyL3dvcmsvd2ViLWlkZS93ZWItaWRlL3NyYy9jb21wb25lbnRzL21hbnVhbC9zdHlsZXMudHN4Iiwic291cmNlc0NvbnRlbnQiOlsiaW1wb3J0IHsgY3NzIH0gZnJvbSBcIkBlbW90aW9uL3JlYWN0XCI7XG5cbmV4cG9ydCBjb25zdCBvcGNvZGVDb250YWluZXIgPSBjc3NgXG4gICAgZGlzcGxheTogaW5saW5lLWJsb2NrO1xuICAgIHBhZGRpbmc6IDA7XG4gICAgbWFyZ2luOiAwIDEycHg7XG4gICAgaGVpZ2h0OiAxMDAlO1xuYDtcblxuZXhwb3J0IGNvbnN0IGVudHJ5ID0gY3NzYFxuICAgIGRpc3BsYXk6IGJsb2NrO1xuICAgIGZvbnQtc2l6ZTogMTRweDtcbiAgICB0ZXh0LWRlY29yYXRpb246IG5vbmU7XG4gICAgbWFyZ2luOiAzcHggMDtcbiAgICB3aGl0ZS1zcGFjZTogbm93cmFwO1xuICAgIHRleHQtb3ZlcmZsb3c6IGVsbGlwc2lzO1xuICAgIG92ZXJmbG93OiBoaWRkZW47XG4gICAgY29sb3I6IHdoaXRlO1xuICAgICY6aG92ZXIge1xuICAgICAgICBiYWNrZ3JvdW5kLWNvbG9yOiByZ2JhKDI1NSwgMjU1LCAyNTUsIDAuNSk7XG4gICAgfVxuXG4gICAgc3BhbiB7XG4gICAgICAgIGZvbnQtd2VpZ2h0OiA1MDA7XG4gICAgICAgIGRpc3BsYXk6IGlubGluZS1ibG9jaztcbiAgICB9XG5cbiAgICBwIHtcbiAgICAgICAgcGFkZGluZzogMDtcbiAgICAgICAgbWFyZ2luOiAwO1xuICAgICAgICBkaXNwbGF5OiBpbml0aWFsO1xuICAgIH1cbmA7XG4iXX0= */",
    toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
