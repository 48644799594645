import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useCallback, useEffect, useState } from "react";
import { closeModal } from "@comp/modal/actions";
import { useTheme } from "@emotion/react";
import { useDispatch, useSelector } from "@root/store";
import { append, ascend, assoc, assocPath, equals, find, keys, map, pathOr, pipe, prop, propEq, reduce, reject, sort, values } from "ramda";
import { saveChangesToTarget } from "../actions";
import { selectDefaultTargetName, selectProjectDocuments, selectProjectTargets } from "../selectors";
import { firestoreNewTargets, validateTargetName } from "./utils";
export var useTargetControlsDialog = function useTargetControlsDialog() {
    var dispatch = useDispatch();
    var theme = useTheme();
    var activeProjectUid = useSelector(function (store) {
        return pathOr("", ["ProjectsReducer", "activeProjectUid"], store);
    });
    var defaultTargetName = useSelector(selectDefaultTargetName(activeProjectUid));
    var documentsMap = useSelector(selectProjectDocuments(activeProjectUid));
    var allDocuments = documentsMap && values(documentsMap);
    var targets = useSelector(selectProjectTargets(activeProjectUid));
    var targetsToLocalState = useCallback(function () {
        return targets ? sort(ascend(prop("targetName")), reduce(function (accumulator, k) {
            return pipe(assoc("isNameValid", true), assoc("isTypeValid", true), assoc("isOtherwiseValid", true), assoc("isDefaultTarget", defaultTargetName === targets[k].targetName), assoc("oldTargetName", targets[k].targetName), function (x) {
                return append(x, accumulator);
            })(targets[k]);
        }, [], keys(targets))) : [];
    }, [defaultTargetName, targets]);
    var _useState = useState(targetsToLocalState()), _useState2 = _slicedToArray(_useState, 2), storedTargets = _useState2[0], setStoredTargets = _useState2[1];
    var _useState3 = useState(storedTargets), _useState4 = _slicedToArray(_useState3, 2), newTargets = _useState4[0], setNewTargets = _useState4[1];
    var hasModifiedTargets = !equals(storedTargets, newTargets);
    useEffect(function () {
        setStoredTargets(targetsToLocalState());
        return function () {
            return setStoredTargets(targetsToLocalState());
        };
    }, [targetsToLocalState]);
    var someErrorPresent = newTargets.some(propEq("isNameValid", false)) || newTargets.some(propEq("isTypeValid", false)) || newTargets.some(propEq("isOtherwiseValid", false));
    var someChangesMade = !equals(storedTargets, newTargets);
    var shouldDisallowSave = someErrorPresent || !someChangesMade;
    var handleCreateNewTarget = useCallback(function () {
        setNewTargets(append({
            csoundOptions: {},
            isNameValid: false,
            isTypeValid: false,
            isOtherwiseValid: false,
            isDefaultTarget: false,
            useCsound7: false,
            targetDocumentUid: "",
            targetName: "",
            targetType: ""
        }, newTargets));
    }, [setNewTargets, newTargets]);
    var handleCloseModal = useCallback(function () {
        dispatch(closeModal());
    }, [dispatch]);
    var handleSave = useCallback(function () {
        var maybeDefaultTarget = find(prop("isDefaultTarget"), newTargets);
        dispatch(saveChangesToTarget(activeProjectUid, firestoreNewTargets(newTargets), maybeDefaultTarget && maybeDefaultTarget.targetName, function () {
            return setStoredTargets(newTargets);
        }));
    }, [activeProjectUid, dispatch, newTargets, setStoredTargets]);
    var handleTargetDelete = useCallback(function (targetName) {
        setNewTargets(reject(propEq("targetName", targetName), newTargets));
    }, [setNewTargets, newTargets]);
    var handleTargetNameChange = useCallback(function (_ref) {
        var nextValue = _ref.nextValue, oldTargetName = _ref.oldTargetName, targetIndex = _ref.targetIndex;
        var isNameValid = validateTargetName({
            targetName: nextValue,
            oldTargetName: oldTargetName,
            newTargets: newTargets
        });
        pipe(assocPath([targetIndex, "targetName"], nextValue), assocPath([targetIndex, "isNameValid"], isNameValid), setNewTargets)(newTargets);
    }, [setNewTargets, newTargets]);
    var handleSelectTargetDocument = useCallback(function (_ref2) {
        var nextTargetDocumentUid = _ref2.nextTargetDocumentUid, targetIndex = _ref2.targetIndex;
        setNewTargets(assocPath([targetIndex, "targetDocumentUid"], nextTargetDocumentUid, newTargets));
    }, [setNewTargets, newTargets]);
    var handleMarkAsDefaultTarget = useCallback(function (nextDefaultTargetName) {
        setNewTargets(map(function (target) {
            return console.log(target.targetName, nextDefaultTargetName) || assoc("isDefaultTarget", target.targetName === nextDefaultTargetName, target);
        }, newTargets));
    }, [setNewTargets, newTargets]);
    var handleEnableCsound7 = useCallback(function (_ref3) {
        var enableCsound7 = _ref3.enableCsound7, targetIndex = _ref3.targetIndex;
        setNewTargets(assocPath([targetIndex, "useCsound7"], enableCsound7, newTargets));
    }, [setNewTargets, newTargets]);
    return {
        allDocuments: allDocuments,
        newTargets: newTargets,
        setNewTargets: setNewTargets,
        targets: targets,
        theme: theme,
        hasModifiedTargets: hasModifiedTargets,
        handleCloseModal: handleCloseModal,
        handleCreateNewTarget: handleCreateNewTarget,
        handleMarkAsDefaultTarget: handleMarkAsDefaultTarget,
        handleTargetNameChange: handleTargetNameChange,
        handleSelectTargetDocument: handleSelectTargetDocument,
        handleTargetDelete: handleTargetDelete,
        handleEnableCsound7: handleEnableCsound7,
        handleSave: handleSave,
        shouldDisallowSave: shouldDisallowSave
    };
};
