import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useState } from "react";
import { useDispatch } from "@root/store";
import { closeModal } from "@comp/modal/actions";
import { TextField, Button } from "@mui/material";
import { deleteUserProject } from "./actions";
import { jsx as ___EmotionJSX } from "@emotion/react";
export function DeleteProjectModal(_ref) {
    var projectUid = _ref.projectUid, projectName = _ref.projectName;
    var _useState = useState(""), _useState2 = _slicedToArray(_useState, 2), name = _useState2[0], setName = _useState2[1];
    var dispatch = useDispatch();
    return ___EmotionJSX("div", null, ___EmotionJSX("h2", null, "Confirm Project Delete"), ___EmotionJSX(TextField, {
        label: "Project Name",
        value: name,
        onChange: function onChange(event) {
            setName(event.target.value);
        }
    }), ___EmotionJSX(Button, {
        variant: "outlined",
        color: "secondary",
        onClick: function onClick() {
            dispatch(deleteUserProject(projectUid));
            dispatch(closeModal());
        },
        style: {
            marginTop: 11,
            marginLeft: 12
        },
        disabled: name !== projectName
    }, "Delete"));
}
