import { UPDATE_PROJECT_LAST_MODIFIED_LOCALLY } from "./types";
import { assocPath } from "ramda";
var ProjectLastModifiedReducer = function ProjectLastModifiedReducer(state, action) {
    switch (action.type) {
        case UPDATE_PROJECT_LAST_MODIFIED_LOCALLY:
            {
                return assocPath([action.projectUid, "timestamp"], action.timestamp, state);
            }
        default:
            {
                return state || {};
            }
    }
};
export default ProjectLastModifiedReducer;
