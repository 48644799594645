import { assoc, pipe } from "ramda";
var initialModalState = {
    isOpen: false,
    properties: undefined
};
var ModalReducer = function ModalReducer(state, action) {
    switch (action.type) {
        case "MODAL_CLOSE":
            {
                return {
                    isOpen: false,
                    properties: undefined
                };
            }
        case "MODAL_SET_ON_CLOSE":
            {
                return assoc("onClose", action.onClose, state);
            }
        case "MODAL_OPEN_SIMPLE":
            {
                return pipe(assoc("isOpen", true), assoc("properties", action.properties), assoc("modalComponentName", action.modalComponentName))(state);
            }
        default:
            {
                return state || initialModalState;
            }
    }
};
export default ModalReducer;
