import MonokaiTheme from "@styles/_theme-monokai";
import GitHubTheme from "@styles/_theme-github";
import { THEMES_CHANGE_THEME } from "./types";
function getInitialTheme() {
    var storedThemeName = localStorage.getItem("theme");
    if (typeof storedThemeName === "string" && ["monokai", "github"].includes(storedThemeName)) {
        switch (storedThemeName) {
            case "monokai":
                {
                    return {
                        selectedTheme: MonokaiTheme,
                        selectedThemeName: "monokai"
                    };
                }
            case "github":
                {
                    return {
                        selectedTheme: GitHubTheme,
                        selectedThemeName: "github"
                    };
                }
            default:
                {
                    return {
                        selectedTheme: MonokaiTheme,
                        selectedThemeName: "monokai"
                    };
                }
        }
    }
    else {
        return {
            selectedTheme: MonokaiTheme,
            selectedThemeName: "monokai"
        };
    }
}
var initialState = getInitialTheme();
var ThemeReducer = function ThemeReducer(state, action) {
    switch (action.type) {
        case THEMES_CHANGE_THEME:
            {
                localStorage.setItem("theme", action.newTheme || "");
                switch (action.newTheme) {
                    case "monokai":
                        {
                            return {
                                selectedTheme: MonokaiTheme,
                                selectedThemeName: "monokai"
                            };
                        }
                    case "github":
                        {
                            return {
                                selectedTheme: GitHubTheme,
                                selectedThemeName: "github"
                            };
                        }
                    default:
                        {
                            return {
                                selectedTheme: MonokaiTheme,
                                selectedThemeName: "monokai"
                            };
                        }
                }
            }
        default:
            {
                return state || initialState;
            }
    }
};
export default ThemeReducer;
