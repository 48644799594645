import React, { useEffect } from "react";
import { useDispatch, history, store } from "@root/store";
import { Provider } from "react-redux";
import Home from "../home/home";
import CsoundManual from "@comp/manual/manual";
import { Profile } from "../profile/profile";
import { Page404 } from "../page-404/page-404";
import ProjectContext from "../projects/project-context";
import { closeTabDock } from "@comp/project-editor/actions";
import { closeProject } from "@comp/projects/actions";
import { HistoryRouter as Router } from "redux-first-history/rr6";
import { Route, Routes } from "react-router-dom";
import { stopCsound } from "../csound/actions";
import { SiteDocuments } from "../site-documents/site-documents";
import { ConsoleProvider } from "@comp/console/context";
import { jsx as ___EmotionJSX } from "@emotion/react";
var EditorLayout = function EditorLayout(properties) {
    var dispatch = useDispatch();
    useEffect(function () {
        return function () {
            dispatch(stopCsound());
            dispatch(closeProject());
            dispatch(closeTabDock());
        };
    }, [dispatch]);
    return ___EmotionJSX(Provider, {
        store: store
    }, ___EmotionJSX(ConsoleProvider, null, ___EmotionJSX(ProjectContext, properties)));
};
// const CsoundManualWithStyleOverrides = ({
//     theme,
//     ...routerProperties
// }: any) => {
//     const [isMounted, setIsMounted] = useState(false);
//     const [{ fetched, Csound }, setFetchState]: [
//         { fetched: boolean; Csound: any },
//         any
//     ] = useState({ fetched: false, Csound: undefined });
//     useEffect(() => {
//         if (!isMounted) {
//             setIsMounted(true);
//             import("@csound/browser").then(({ Csound }) => {
//                 setFetchState({ fetched: true, Csound });
//             });
//         }
//     }, [isMounted, setIsMounted, fetched, Csound, setFetchState]);
//     return !fetched ? (
//         <></>
//     ) : (
//         <CsoundManual
//             {...routerProperties}
//             theme={theme}
//             codeMirrorPainter={CodeMirrorPainter}
//             Csound={Csound}
//         />
//     );
// };
var RouterComponent = function RouterComponent() {
    return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(Router, {
        history: history
    }, ___EmotionJSX(Routes, null, ___EmotionJSX(Route, {
        index: true,
        element: ___EmotionJSX(Home, null)
    }), ___EmotionJSX(Route, {
        path: "profile/:username",
        element: ___EmotionJSX(Profile, null)
    }), ___EmotionJSX(Route, {
        path: "profile/:username/*",
        element: ___EmotionJSX(Profile, null)
    }), ___EmotionJSX(Route, {
        path: "editor",
        element: ___EmotionJSX(EditorLayout, null)
    }, ___EmotionJSX(Route, {
        path: ":id",
        element: ___EmotionJSX(EditorLayout, null)
    })), ___EmotionJSX(Route, {
        path: "manual",
        element: ___EmotionJSX(CsoundManual, null)
    }, ___EmotionJSX(Route, {
        path: ":id",
        element: ___EmotionJSX(CsoundManual, null)
    })), ___EmotionJSX(Route, {
        path: "documentation",
        element: ___EmotionJSX(SiteDocuments, null)
    }), ___EmotionJSX(Route, {
        path: "404",
        element: ___EmotionJSX(Page404, null)
    }), ___EmotionJSX(Route, {
        path: "*",
        element: ___EmotionJSX(Page404, null)
    }))));
};
export default RouterComponent;
