import React from "react";
import { useDispatch, useSelector } from "@root/store";
import { ThreeDots } from "react-loader-spinner";
import { useTheme } from "@emotion/react";
import { isEmpty, path } from "ramda";
import { searchProjects } from "./actions";
import { selectSearchResult } from "./selectors";
import TextField from "@mui/material/TextField";
import { debounce } from "throttle-debounce";
import LeftIcon from "@mui/icons-material/ArrowBack";
import RightIcon from "@mui/icons-material/ArrowForward";
import IconButton from "@mui/material/IconButton";
import ProjectCard from "./project-card";
import * as SS from "./styles";
import { jsx as ___EmotionJSX } from "@emotion/react";
var doSearch = debounce(100, function (query, offset, dispatch) {
    dispatch(searchProjects(query, offset));
});
var Search = function Search() {
    var dispatch = useDispatch();
    var theme = useTheme();
    var searchResult = useSelector(selectSearchResult);
    var profiles = useSelector(function (store) {
        return path(["HomeReducer", "profiles"], store);
    });
    var searchQuery = useSelector(function (store) {
        return path(["HomeReducer", "searchQuery"], store);
    });
    var searchProjectsRequest = useSelector(function (store) {
        return path(["HomeReducer", "searchProjectsRequest"], store);
    });
    var searchPaginationOffset = useSelector(function (store) {
        return path(["HomeReducer", "searchPaginationOffset"], store);
    });
    var searchResultTotalRecords = useSelector(function (store) {
        return path(["HomeReducer", "searchResultTotalRecords"], store);
    });
    var onChange = React.useCallback(function (event) {
        doSearch(event.target.value, 0, dispatch);
    }, [dispatch]);
    React.useEffect(function () {
        return doSearch.cancel;
    }, []);
    return ___EmotionJSX(React.Fragment, null, ___EmotionJSX("div", {
        css: SS.homeHeading
    }, searchQuery && searchQuery.length > 0 && ___EmotionJSX(React.Fragment, null, ___EmotionJSX(IconButton, {
        "aria-label": "left",
        "data-tip": "Previous results",
        css: SS.paginationButton(true),
        style: {
            right: 48
        },
        onClick: function onClick() {
            return doSearch(searchQuery, Math.max(searchPaginationOffset, 0) + 8, dispatch);
        },
        disabled: searchPaginationOffset < 1 || searchResultTotalRecords < 1 || isEmpty(searchQuery)
    }, ___EmotionJSX(LeftIcon, null)), ___EmotionJSX(IconButton, {
        "aria-label": "right",
        "data-tip": "Next results",
        css: SS.paginationButton(true),
        onClick: function onClick() {
            return doSearch(searchQuery, Math.max(searchPaginationOffset - 8, 0), dispatch);
        },
        disabled: searchPaginationOffset < 0 || searchResultTotalRecords < 0 || isEmpty(searchQuery) || !(searchPaginationOffset < searchResultTotalRecords)
    }, ___EmotionJSX(RightIcon, null))), ___EmotionJSX("h1", {
        css: SS.homePageHeading
    }, "Search Projects"), ___EmotionJSX("hr", {
        css: SS.homePageHeadingBreak
    })), ___EmotionJSX(TextField, {
        onChange: onChange,
        css: SS.searchField,
        name: "search-field",
        label: "Search field",
        type: "search",
        variant: "outlined"
    }), ___EmotionJSX("div", {
        css: SS.searchLoaderSpinner
    }, ___EmotionJSX(ThreeDots, {
        visible: searchProjectsRequest,
        color: theme.buttonIcon,
        height: 200,
        width: 200
    })), !searchProjectsRequest && searchQuery.length > 0 && ___EmotionJSX("div", {
        css: SS.doubleGridContainer,
        style: {
            marginTop: 0,
            marginBottom: 12
        }
    }, searchResult.map(function (project, index) {
        return profiles[project.userUid] ? ___EmotionJSX(ProjectCard, {
            key: "p".concat(index),
            projectIndex: index,
            project: project,
            profile: profiles[project.userUid]
        }) : ___EmotionJSX("div", {
            key: index
        });
    })));
};
export default Search;
