import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "@root/store";
import { csoundMode } from "@hlolli/codemirror-lang-csound";
import { EditorView } from "codemirror";
import { crosshairCursor, keymap, lineNumbers, highlightActiveLineGutter, highlightSpecialChars, drawSelection, dropCursor } from "@codemirror/view";
import { autocompletion, closeBrackets } from "@codemirror/autocomplete";
import { defaultKeymap, history, historyField, historyKeymap } from "@codemirror/commands";
import { bracketMatching, foldGutter, indentOnInput } from "@codemirror/language";
import { EditorState, StateField } from "@codemirror/state";
import { filenameToCsoundType } from "@comp/csound/utils";
import { evalBlinkExtension } from "./utils";
import { reject, pathOr, propOr } from "ramda";
import * as projectActions from "../projects/actions";
import { editorStyle } from "@styles/code-mirror-painter";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var openEditors = new Map();
var stateFields = {};
var scrollPos = {};
var histories = {};
var getInitialState = function getInitialState(documentUid) {
    var hasHistory = Boolean(stateFields[documentUid + ":serialized"]);
    stateFields[documentUid] = stateFields[documentUid] || {};
    stateFields[documentUid].history = stateFields[documentUid].history || historyField;
    return hasHistory ? {
        json: JSON.parse(stateFields[documentUid + ":serialized"]),
        fields: stateFields[documentUid]
    } : undefined;
};
var getInitialScrollPosition = function getInitialScrollPosition(documentUid) {
    return scrollPos[documentUid] || 0;
};
var getHistory = function getHistory(documentUid) {
    var previousHistory = histories[documentUid];
    if (previousHistory) {
        return previousHistory;
    }
    else {
        histories[documentUid] = history();
        return histories[documentUid];
    }
};
var CodeEditor = function CodeEditor(_ref) {
    var documentUid = _ref.documentUid, projectUid = _ref.projectUid;
    var editorReference = useRef(null);
    var _useState = useState(false), _useState2 = _slicedToArray(_useState, 2), isMounted = _useState2[0], setIsMounted = _useState2[1];
    var dispatch = useDispatch();
    var project = useSelector(pathOr({}, ["ProjectsReducer", "projects", projectUid]));
    var document = pathOr({}, ["documents", documentUid], project);
    var csoundFileType = filenameToCsoundType(document.filename || "");
    var _useState3 = useState(undefined), _useState4 = _slicedToArray(_useState3, 2), csoundDocumentStateField = _useState4[0], setCsoundDocumentStateField = _useState4[1];
    var currentDocumentValue = propOr("", "currentValue", document);
    var onChange = useCallback(function (event) {
        var _event$state;
        if (event !== null && event !== void 0 && (_event$state = event.state) !== null && _event$state !== void 0 && _event$state.doc) {
            dispatch(projectActions.updateDocumentValue(event.state.doc.toString(), projectUid, documentUid));
        }
    }, [dispatch, projectUid, documentUid]);
    var onScroll = useCallback(function (event) {
        scrollPos[documentUid] = event.target.scrollTop;
    }, [documentUid]);
    useEffect(function () {
        if (!isMounted) {
            setIsMounted(true);
        }
        return function () {
            if (isMounted && openEditors.has(documentUid)) {
                var editorStateInstance = openEditors.get(documentUid);
                editorStateInstance.scrollDOM.removeEventListener("scroll", onScroll);
                editorStateInstance.destroy();
                openEditors.delete(documentUid);
            }
        };
    }, [isMounted, setIsMounted, documentUid, onScroll]);
    useEffect(function () {
        if (editorReference.current && !openEditors.has(documentUid)) {
            var initialState = getInitialState(documentUid);
            // console.log({ initialState });
            var config = {
                extensions: [lineNumbers(), highlightActiveLineGutter(), highlightSpecialChars(), foldGutter(), drawSelection(), dropCursor(), EditorState.allowMultipleSelections.of(true), indentOnInput(), csoundMode({
                        fileType: ["sco", "orc", "csd"].includes(csoundFileType || "") ? csoundFileType : "orc"
                    }), keymap.of([].concat(_toConsumableArray(reject(function (keyb) {
                        return ["Mod-Enter", "Comd-Enter", "Ctrl-Enter"].includes(keyb.key);
                    }, defaultKeymap)), _toConsumableArray(historyKeymap))), evalBlinkExtension, bracketMatching(), closeBrackets(), autocompletion(), getHistory(documentUid), EditorView.updateListener.of(onChange), crosshairCursor()]
            };
            var newEditor = initialState ? new EditorView({
                state: EditorState.fromJSON((initialState === null || initialState === void 0 ? void 0 : initialState.json) || {}, config, (initialState === null || initialState === void 0 ? void 0 : initialState.fields) || {}),
                parent: editorReference.current
            }) : new EditorView({
                extensions: config.extensions,
                parent: editorReference.current
            });
            newEditor.scrollDOM.addEventListener("scroll", onScroll);
            openEditors.set(documentUid, newEditor);
            newEditor.dispatch({
                changes: {
                    from: 0,
                    to: newEditor.state.doc.length,
                    insert: currentDocumentValue
                }
            });
            var initialScrollPosition = getInitialScrollPosition(documentUid);
            if (initialScrollPosition > 0) {
                newEditor.scrollDOM.scrollTop = initialScrollPosition;
                for (var _i = 0, _arr = [1, 10, 100]; _i < _arr.length; _i++) {
                    var timeout = _arr[_i];
                    setTimeout(function () {
                        try {
                            newEditor.scrollDOM.scrollTop = initialScrollPosition;
                        }
                        catch (_unused) { }
                    }, timeout);
                }
            }
        }
    }, [editorReference, currentDocumentValue, csoundFileType, documentUid, onChange, onScroll]);
    useEffect(function () {
        if (isMounted && documentUid && !csoundDocumentStateField) {
            setCsoundDocumentStateField(StateField.define({
                create: function create() {
                    return {
                        documentUid: documentUid,
                        documentType: csoundFileType || ""
                    };
                },
                update: function update() {
                    return {
                        documentUid: documentUid,
                        documentType: csoundFileType || ""
                    };
                }
            }));
        }
    }, [isMounted, documentUid, csoundFileType, csoundDocumentStateField, setCsoundDocumentStateField]);
    return ___EmotionJSX("div", {
        ref: editorReference,
        css: editorStyle
    });
};
export default CodeEditor;
