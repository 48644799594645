import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useEffect, useState } from "react";
import { getDownloadURL } from "firebase/storage";
import { storageReference } from "../../config/firestore";
import { rootStyle } from "./styles";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var AudioEditor = function AudioEditor(_ref) {
    var audioFileUrl = _ref.audioFileUrl;
    var _useState = useState(""), _useState2 = _slicedToArray(_useState, 2), data = _useState2[0], setData = _useState2[1];
    useEffect(function () {
        if (!data && typeof audioFileUrl === "string" && !audioFileUrl.startsWith("blob")) {
            storageReference(audioFileUrl).then(function (storage) {
                getDownloadURL(storage).then(function (fileUrl) {
                    setData(fileUrl);
                });
            });
        }
        else if (typeof audioFileUrl === "string" && audioFileUrl.startsWith("blob")) {
            setData(audioFileUrl);
        }
    }, [data, setData, audioFileUrl]);
    return data ? ___EmotionJSX("div", {
        css: rootStyle
    }, ___EmotionJSX("audio", {
        controls: true
    }, ___EmotionJSX("source", {
        src: data
    }))) : ___EmotionJSX("div", null, ___EmotionJSX("p", null, "Looking up audio file URL..."));
};
