function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
export var rootStyle = process.env.NODE_ENV === "production" ? {
    name: "yc4i1i",
    styles: "width:100%;height:100%;display:flex;& audio{border-right-width:30px;margin:auto;}"
} : {
    name: "1rdfx7c-rootStyle",
    styles: "width:100%;height:100%;display:flex;& audio{border-right-width:30px;margin:auto;};label:rootStyle;",
    map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9ob21lL3J1bm5lci93b3JrL3dlYi1pZGUvd2ViLWlkZS9zcmMvY29tcG9uZW50cy9hdWRpby1lZGl0b3Ivc3R5bGVzLnRzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUV5QiIsImZpbGUiOiIvaG9tZS9ydW5uZXIvd29yay93ZWItaWRlL3dlYi1pZGUvc3JjL2NvbXBvbmVudHMvYXVkaW8tZWRpdG9yL3N0eWxlcy50cyIsInNvdXJjZXNDb250ZW50IjpbImltcG9ydCB7IGNzcyB9IGZyb20gXCJAZW1vdGlvbi9yZWFjdFwiO1xuXG5leHBvcnQgY29uc3Qgcm9vdFN0eWxlID0gY3NzKHtcbiAgICB3aWR0aDogXCIxMDAlXCIsXG4gICAgaGVpZ2h0OiBcIjEwMCVcIixcbiAgICBkaXNwbGF5OiBcImZsZXhcIixcbiAgICBcIiYgYXVkaW9cIjoge1xuICAgICAgICBib3JkZXJSaWdodFdpZHRoOiAzMCxcbiAgICAgICAgbWFyZ2luOiBcImF1dG9cIlxuICAgIH1cbn0pO1xuIl19 */",
    toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
