import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
var _excluded = ["className", "message", "onClose", "variant"];
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";
import SnackbarContent from "@mui/material/SnackbarContent";
import WarningIcon from "@mui/icons-material/Warning";
import { useTheme } from "@emotion/react";
import { closeSnackbar } from "./actions";
import { useSelector, useDispatch } from "react-redux";
import { selectSnackbarType, selectSnackbarOpen, selectSnackbarText, selectSnackbarTimeout } from "./selectors";
import { jsx as ___EmotionJSX } from "@emotion/react";
var variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon
};
// const styles = (theme: Theme) => ({
//     success: {
//         backgroundColor: green[600],
//         color: theme.palette.common.white
//     },
//     error: {
//         backgroundColor: theme.palette.error.dark,
//         color: theme.palette.common.white
//     },
//     info: {
//         backgroundColor: theme.palette.primary.main,
//         color: theme.palette.common.white
//     },
//     warning: {
//         backgroundColor: amber[700],
//         color: theme.palette.common.white
//     },
//     icon: {
//         fontSize: 20
//     },
//     iconVariant: {
//         opacity: 0.9,
//         marginRight: theme.spacing(1)
//     },
//     message: {
//         display: "flex",
//         alignItems: "center",
//         color: theme.palette.common.black
//     }
// });
var SnackbarContentWrapper = function SnackbarContentWrapper(properties) {
    var theme = useTheme();
    var className = properties.className, message = properties.message, onClose = properties.onClose, variant = properties.variant, rest = _objectWithoutProperties(properties, _excluded);
    var Icon;
    var cssClass;
    // switch (variant) {
    //     case SnackbarType.Info: {
    //         Icon = variantIcon["info"];
    //         cssClass = classes["info"];
    //         break;
    //     }
    //     case SnackbarType.Warning: {
    //         Icon = variantIcon["warning"];
    //         cssClass = classes["warning"];
    //         break;
    //     }
    //     case SnackbarType.Success: {
    //         Icon = variantIcon["success"];
    //         cssClass = classes["success"];
    //         break;
    //     }
    //     case SnackbarType.Error: {
    //         Icon = variantIcon["error"];
    //         cssClass = classes["error"];
    //         break;
    //     }
    //     default: {
    //         Icon = variantIcon["error"];
    //         cssClass = classes["error"];
    //         break;
    //     }
    // }
    return ___EmotionJSX(SnackbarContent, _extends({
        "aria-describedby": "client-snackbar",
        message: ___EmotionJSX("span", {
            id: "client-snackbar"
        }, ___EmotionJSX(Icon, null), message),
        action: [___EmotionJSX(IconButton, {
                key: "close",
                "aria-label": "close",
                color: "inherit",
                onClick: onClose
            }, ___EmotionJSX(CloseIcon, null))]
    }, rest));
};
var CustomSnackbar = function CustomSnackbar() {
    var type = useSelector(selectSnackbarType);
    var text = useSelector(selectSnackbarText);
    var open = useSelector(selectSnackbarOpen);
    var timeout = useSelector(selectSnackbarTimeout);
    var dispatch = useDispatch();
    var handleClose = function handleClose() {
        dispatch(closeSnackbar());
    };
    return ___EmotionJSX(Snackbar, {
        anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
        },
        open: open,
        autoHideDuration: timeout,
        message: text,
        onClose: handleClose
    });
};
export default CustomSnackbar;
