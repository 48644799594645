import themeMonokai from "./_theme-monokai";
import { deepMerge } from "@root/utils";
import { rgba } from "./utils";
var border = "#d0d0d0";
var comment = "#6a737d";
var constant = "#005cc5";
// const diffAdded = "#e6ffed";
var diffAddedHighlight = "#acf2bd";
// const diffChanged = "#ffe1b9";
var diffChangedHighlight = "#ffc86f";
// const diffRemoved = "#ffeef0";
var diffRemovedHighlight = "#fdb8c0";
var fn = "#6f42c1";
var headerBg2 = "#d73a49";
// const headerFg2 = "#ffffff";
var highlight = "#fffbdd";
var headerBg1 = "#24292e";
var headerFg1 = "#bcbdc0";
var htmlTag = "#22863a";
// const keyword = "#d73a49";
var selection = "#6199ff2f";
var string = "#032f62";
var text = "#24292e";
var white = "#ffffff";
var theme = {
    // Backgrounds
    background: white,
    headerBackground: headerBg1,
    fileTreeBackground: white,
    tooltipBackground: highlight,
    dropdownBackground: highlight,
    buttonBackground: border,
    altButtonBackground: diffRemovedHighlight,
    disabledButtonBackground: headerFg1,
    highlightBackground: headerFg1,
    highlightBackgroundAlt: headerFg1,
    textFieldBackground: selection,
    // Text colors
    headerTextColor: white,
    textColor: text,
    selectedTextColor: selection,
    errorText: headerBg2,
    buttonTextColor: text,
    altTextColor: headerBg1,
    disabledTextColor: headerFg1,
    unfocusedTextColor: "rgba(".concat(rgba(text, 0.4), ")"),
    // hr/dragger/underline
    line: comment,
    lineHover: headerFg1,
    // Hover colors
    // - background Hover
    buttonBackgroundHover: "rgba(".concat(rgba(border, 0.1), ")"),
    buttonTextColorHover: "rgba(".concat(rgba(text, 0.1), ")"),
    dropdownBackgroundHover: "rgba(".concat(rgba(highlight, 0.1), ")"),
    // - text Hover
    textColorHover: "rgba(".concat(rgba(text, 0.1), ")"),
    tabHighlight: string,
    tabHighlightActive: selection,
    allowed: htmlTag,
    button: border,
    // Other
    starActive: diffChangedHighlight,
    starInactive: border,
    socialIcon: border,
    publicIcon: border,
    playIcon: border,
    settingsIcon: diffChangedHighlight,
    profilePlayButton: diffAddedHighlight,
    profilePlayButtonActive: diffChangedHighlight,
    scrollbar: highlight,
    scrollbarHover: "rgba(".concat(rgba(border, 0.1), ")"),
    console: text,
    cursor: text,
    opcode: fn,
    operator: constant,
    attribute: fn,
    keyword: fn,
    string: string,
    number: text,
    bracket: htmlTag,
    aRateVar: constant,
    iRateVar: constant,
    kRateVar: constant,
    fRateVar: constant,
    pField: diffChangedHighlight,
    flash: highlight,
    flashFade: headerFg1,
    gutterBackground: white,
    gutterMarker: headerBg2,
    gutterMarkerSubtle: headerBg2,
    lineNumber: text,
    caretColor: text
    /*
    comment: monokaiComments,
    commentAttribute: henn1nk,
    commentDef: monokaiOrange,
    commentTag: diffRemovedHighlight,
    commentType: monokaiCyan
    */
};
export default deepMerge(themeMonokai, theme);
