import { rgba } from "./utils";
// Monokai
// https://www.colourlovers.com/palette/1718713/Monokai
// https://github.com/oneKelvinSmith/monokai-emacs/blob/master/monokai-theme.el
var henn1nk = "#a6e22e";
var monokaiGray = "#64645E";
var monokaiLightGray = "#CCCCCC";
var monokaiCyan = "#A1EFE4";
var monokaiLightBlue = "#66d9ef";
var monokaiOrange = "#fd971f";
var monokaiRed = "#F92672";
var monokaiYellow = "#E6DB74";
var monokaiMagenta = "#FD5FF0";
var monokaiGreen = "#A6E22E";
var orchid = "#f92672";
var sundriedClay = "#272822";
var monokaiViolet = "#AE81FF";
var monokaiForeground = "#F8F8F2";
var monokaiBackground = "#272822";
var monokaiComments = "#75715E";
var monokaiEmphasis = "#F8F8F0";
var monokaiLineNumber = "#8F908A";
var monokaiHighlight = "#49483E";
var monokaiHighlightAlt = "#3E3D31";
var monokaiHighlightLine = "#3C3D37";
// const monokaiBlueDark = "#40CAE4";
var monokaiLightBlue2 = "#92E7F7";
var monokaiBlueHighContrast = "#1DB4D0";
var theme = {
    // Backgrounds
    background: monokaiBackground,
    headerBackground: monokaiBackground,
    fileTreeBackground: monokaiBackground,
    tooltipBackground: monokaiHighlight,
    dropdownBackground: monokaiHighlight,
    buttonBackground: monokaiHighlight,
    altButtonBackground: monokaiMagenta,
    disabledButtonBackground: monokaiHighlightAlt,
    highlightBackground: monokaiHighlight,
    highlightBackgroundAlt: monokaiHighlightAlt,
    textFieldBackground: sundriedClay,
    gutterBackground: monokaiHighlightAlt,
    // Text colors
    headerTextColor: monokaiForeground,
    textColor: monokaiForeground,
    selectedTextColor: monokaiHighlightLine,
    errorText: monokaiRed,
    buttonTextColor: monokaiForeground,
    altTextColor: monokaiLineNumber,
    unfocusedTextColor: "rgba(".concat(rgba(monokaiForeground, 0.4), ")"),
    disabledTextColor: monokaiGray,
    // hr/dragger/underline
    line: monokaiHighlightLine,
    lineHover: "rgba(".concat(rgba(monokaiHighlightAlt, 0.1), ")"),
    // Hover colors
    // - background Hover
    buttonBackgroundHover: "rgba(".concat(rgba(monokaiHighlightAlt, 0.5), ")"),
    buttonTextColorHover: "rgba(".concat(rgba(monokaiForeground, 0.1), ")"),
    dropdownBackgroundHover: "rgba(".concat(rgba(monokaiHighlight, 0.1), ")"),
    // - text Hover
    textColorHover: "rgba(".concat(rgba(monokaiForeground, 0.1), ")"),
    tabHighlight: monokaiLightBlue2,
    tabHighlightActive: monokaiMagenta,
    allowed: monokaiGreen,
    button: monokaiBlueHighContrast,
    // Other
    starActive: monokaiYellow,
    buttonIcon: monokaiGray,
    settingsIcon: monokaiViolet,
    profilePlayButton: henn1nk,
    profilePlayButtonActive: monokaiOrange,
    scrollbar: monokaiGray,
    scrollbarHover: monokaiLightGray,
    // don't keep using this one
    console: monokaiLightGray,
    macro: monokaiOrange,
    cursor: monokaiForeground,
    opcode: monokaiMagenta,
    operator: monokaiEmphasis,
    controlFlow: monokaiBlueHighContrast,
    attribute: monokaiBlueHighContrast,
    keyword: orchid,
    string: monokaiYellow,
    number: monokaiEmphasis,
    bracket: monokaiBlueHighContrast,
    aRateVar: monokaiViolet,
    iRateVar: monokaiCyan,
    kRateVar: monokaiLightBlue,
    fRateVar: monokaiMagenta,
    pField: monokaiYellow,
    flash: monokaiHighlight,
    flashFade: monokaiHighlightAlt,
    gutterMarker: monokaiForeground,
    gutterMarkerSubtle: monokaiForeground,
    lineNumber: monokaiLineNumber,
    comment: monokaiComments,
    commentAttribute: henn1nk,
    commentDef: monokaiOrange,
    commentTag: monokaiMagenta,
    commentType: monokaiCyan,
    caretColor: monokaiCyan
};
export default theme;
