import { assoc, isEmpty, reduce, reject, propEq } from "ramda";
export var firestoreNewTargets = function firestoreNewTargets(newTargets) {
    return reduce(function (accumulator, _ref) {
        var targetName = _ref.targetName, targetType = _ref.targetType, targetDocumentUid = _ref.targetDocumentUid, csoundOptions = _ref.csoundOptions, useCsound7 = _ref.useCsound7;
        var firebaseTarget = {
            targetName: targetName,
            targetType: targetType,
            targetDocumentUid: targetDocumentUid,
            csoundOptions: csoundOptions,
            useCsound7: useCsound7 || false
        };
        return assoc(targetName, firebaseTarget, accumulator);
    }, {}, newTargets);
};
export var validateTargetName = function validateTargetName(_ref2) {
    var targetName = _ref2.targetName, oldTargetName = _ref2.oldTargetName, newTargets = _ref2.newTargets;
    return !isEmpty(targetName) && (oldTargetName === targetName ? true : !reject(propEq("oldTargetName", oldTargetName), newTargets).some(propEq("targetName", targetName)));
};
