import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import * as SS from "./styles";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var TargetControlsConfigDialogFooter = function TargetControlsConfigDialogFooter(_ref) {
    var handleCreateNewTarget = _ref.handleCreateNewTarget, handleCloseModal = _ref.handleCloseModal, handleSave = _ref.handleSave, hasModifiedTargets = _ref.hasModifiedTargets;
    return ___EmotionJSX("div", {
        css: SS.targetsDialogFooter
    }, ___EmotionJSX(Fab, {
        color: "primary",
        variant: "extended",
        "aria-label": "Add",
        size: "small",
        onClick: function onClick() {
            handleCreateNewTarget();
        }
    }, "Add Target", ___EmotionJSX(AddIcon, null)), ___EmotionJSX(Fab, {
        color: "primary",
        variant: "extended",
        "aria-label": "Save",
        size: "small",
        disabled: !hasModifiedTargets,
        onClick: function onClick() {
            return handleSave();
        },
        style: {
            marginLeft: 12
        }
    }, "Save changes", ___EmotionJSX(SaveIcon, null)), ___EmotionJSX(Fab, {
        color: "primary",
        variant: "extended",
        "aria-label": "Close",
        size: "small",
        style: {
            marginLeft: 12
        },
        onClick: function onClick() {
            return handleCloseModal();
        }
    }, "Close"));
};
