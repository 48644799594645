import React from "react";
import { StyledListItemAvatar, StyledListItemTopRowText, StyledUserListItemContainer } from "../profile-ui";
import { ListItem, Avatar, ListItemText } from "@mui/material";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { jsx as ___EmotionJSX } from "@emotion/react";
var FollowingList = function FollowingList(_ref) {
    var filteredFollowing = _ref.filteredFollowing;
    var dispatch = useDispatch();
    return ___EmotionJSX(React.Fragment, null, filteredFollowing.map(function (p, index) {
        return ___EmotionJSX(ListItem, {
            button: true,
            alignItems: "flex-start",
            key: index,
            onClick: function onClick() {
                dispatch(push("/profile/".concat(p.username)));
            }
        }, ___EmotionJSX(StyledUserListItemContainer, null, ___EmotionJSX(StyledListItemAvatar, null, ___EmotionJSX(Avatar, {
            src: p.photoUrl
        })), ___EmotionJSX(StyledListItemTopRowText, null, ___EmotionJSX(ListItemText, {
            primary: p.displayName,
            secondary: p.bio
        }))));
    }));
};
export default FollowingList;
