import { append, find, propEq, reduce } from "ramda";
export var sortByStoredTabOrder = function sortByStoredTabOrder(tabOrder, allDocuments) {
    return reduce(function (accumulator, documentUid) {
        var maybeDocument = find(propEq("documentUid", documentUid), allDocuments);
        return maybeDocument ? append({
            editorInstance: undefined,
            uid: maybeDocument.documentUid
        }, accumulator) : accumulator;
    }, [], tabOrder);
};
