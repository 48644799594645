import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { useState } from "react";
import { useDispatch, useSelector } from "@root/store";
import onClickOutside from "react-onclickoutside";
import { useLocalStorage } from "react-use-storage";
import SelectedIcon from "@mui/icons-material/DoneSharp";
import NestedMenuIcon from "@mui/icons-material/ArrowRightSharp";
import * as SS from "./styles";
import { hr as hrCss } from "@styles/_common";
import { useSetConsole } from "@comp/console/context";
import { invokeHotKeyCallback } from "@comp/hot-keys/actions";
import { humanizeKeySequence } from "@comp/hot-keys/utils";
import { showTargetsConfigDialog } from "@comp/target-controls/actions";
import { exportProject } from "@comp/projects/actions";
import { toggleManualPanel, setFileTreePanelOpen } from "@comp/project-editor/actions";
import { renderToDisk } from "@comp/csound/actions";
import { selectCsoundStatus } from "@comp/csound/selectors";
import { selectIsOwner } from "@comp/project-editor/selectors";
import { changeTheme } from "@comp/themes/action";
import { append, equals, isEmpty, path, pathOr, propOr, reduce, slice } from "ramda";
import { showKeyboardShortcuts } from "@comp/site-documents/actions";
import { openBottomTab } from "@comp/bottom-tabs/actions";
import { jsx as ___EmotionJSX } from "@emotion/react";
function MenuBar() {
    var setConsole = useSetConsole();
    var activeProjectUid = useSelector(pathOr("", ["ProjectsReducer", "activeProjectUid"]));
    var dispatch = useDispatch();
    var isOwner = useSelector(selectIsOwner(activeProjectUid));
    var csoundStatus = useSelector(selectCsoundStatus);
    var keyBindings = useSelector(path(["HotKeysReducer", "bindings"]));
    var selectedThemeName = useSelector(path(["ThemeReducer", "selectedThemeName"]));
    var isManualOpen = useSelector(function (store) {
        return path(["ProjectEditorReducer", "manualVisible"], store);
    });
    var isConsoleVisible = useSelector(function (store) {
        return (store.BottomTabsReducer.openTabs || []).includes("console");
    });
    var isFileTreeVisible = useSelector(function (store) {
        return store.ProjectEditorReducer.fileTreeVisible;
    });
    var isSpectralAnalyzerVisible = useSelector(function (store) {
        return store.BottomTabsReducer.openTabs.includes("spectralAnalyzer");
    });
    var isMidiPianoVisible = useSelector(function (store) {
        return store.BottomTabsReducer.openTabs.includes("piano");
    });
    var _useLocalStorage = useLocalStorage("sab", "false"), _useLocalStorage2 = _slicedToArray(_useLocalStorage, 2), isSabEnabled = _useLocalStorage2[0], setIsSabEnabled = _useLocalStorage2[1];
    var menuBarItems = [{
            label: "File",
            submenu: [{
                    label: "New File…",
                    hotKey: "new_document",
                    disabled: !isOwner
                }, {
                    label: "Add File…",
                    hotKey: "add_file",
                    disabled: !isOwner
                }, {
                    label: "Save Document",
                    hotKey: "save_document",
                    disabled: !isOwner
                }, {
                    label: "Save All",
                    hotKey: "save_all_documents",
                    disabled: !isOwner
                }, {
                    seperator: true
                }, {
                    label: "Render to Disk",
                    callback: function callback() {
                        return dispatch(renderToDisk(setConsole));
                    }
                }, {
                    label: "Export Project (.zip)",
                    callback: function callback() {
                        return dispatch(exportProject());
                    }
                }, {
                    seperator: true
                }, {
                    label: isOwner ? "Save and Close" : "Close",
                    hotKey: "save_and_close"
                }]
        }, {
            label: "Edit",
            submenu: [{
                    label: "Undo",
                    hotKey: "undo"
                }, {
                    label: "Redo",
                    hotKey: "redo"
                }, {
                    label: "Search",
                    hotKey: "find_simple"
                }, {
                    label: "Theme",
                    submenu: [{
                            label: "Monokai",
                            callback: function callback() {
                                return dispatch(changeTheme("monokai"));
                            },
                            checked: selectedThemeName === "monokai"
                        }, {
                            label: "Github",
                            callback: function callback() {
                                return dispatch(changeTheme("github"));
                            },
                            checked: selectedThemeName === "github"
                        }
                        // {
                        //     label: "BluePunk",
                        //     callback: () => dispatch(changeTheme("bluepunk")),
                        //     checked: selectedThemeName === "bluepunk"
                        // }
                    ]
                }]
        }, {
            label: "Project",
            submenu: [{
                    label: csoundStatus === "paused" ? "Resume" : "Run/Play",
                    hotKey: "run_project",
                    disabled: csoundStatus === "playing"
                }, {
                    label: "Stop",
                    hotKey: "stop_playback",
                    disabled: csoundStatus !== "playing" && csoundStatus !== "paused"
                }, {
                    label: "Pause",
                    hotKey: "pause_playback",
                    disabled: csoundStatus !== "playing"
                }, {
                    seperator: true
                }, {
                    label: "Configure Targets",
                    callback: function callback() {
                        return dispatch(showTargetsConfigDialog());
                    },
                    disabled: !isOwner
                }]
        }, {
            label: "View",
            submenu: [{
                    label: "Csound Manual",
                    callback: function callback() {
                        return dispatch(toggleManualPanel());
                    },
                    checked: isManualOpen
                }, {
                    label: "File Tree",
                    callback: function callback() {
                        return dispatch(setFileTreePanelOpen(!isFileTreeVisible));
                    },
                    checked: isFileTreeVisible
                }, {
                    label: "Console",
                    callback: function callback() {
                        return dispatch(openBottomTab("console"));
                    },
                    checked: isConsoleVisible
                }, {
                    label: "Spectral Analyzer",
                    callback: function callback() {
                        return dispatch(openBottomTab("spectralAnalyzer"));
                    },
                    checked: isSpectralAnalyzerVisible
                }, {
                    label: "Virtual Midi Keyboard",
                    callback: function callback() {
                        return dispatch(openBottomTab("piano"));
                    },
                    checked: isMidiPianoVisible
                }]
        }, {
            label: "I/O",
            submenu: [
                // {
                //     label: "Refresh MIDI Input",
                //     callback: () => {
                //         dispatch(enableMidiInput());
                //     }
                // },
                // {
                //     label: "Refresh Audio Input",
                //     callback: () => {
                //         dispatch(enableAudioInput());
                //     }
                // },
                // {
                //     seperator: true
                // },
                {
                    label: "Enable SharedArrayBuffer",
                    checked: isSabEnabled === "true",
                    callback: function callback() {
                        setIsSabEnabled(isSabEnabled === "true" ? "false" : "true");
                    }
                }
            ]
        }, {
            label: "Help",
            submenu: [{
                    label: "Csound Manual (External)",
                    callback: function callback() {
                        window.open("https://csound.com/docs/manual", "_blank");
                    }
                }, {
                    label: "Csound FLOSS Manual",
                    callback: function callback() {
                        window.open("https://flossmanual.csound.com/", "_blank");
                    }
                }, {
                    seperator: true
                }, {
                    label: "Web-IDE Documentation",
                    callback: function callback() {
                        window.open("/documentation", "_blank");
                    }
                }, {
                    seperator: true
                }, {
                    label: "Show Keyboard Shortcuts",
                    callback: function callback() {
                        return dispatch(showKeyboardShortcuts());
                    }
                }]
        }];
    MenuBar.handleClickOutside = function () {
        setOpenPath([]);
    };
    var _useState = useState([]), _useState2 = _slicedToArray(_useState, 2), openPath = _useState2[0], setOpenPath = _useState2[1];
    var reduceRow = function reduceRow(items, openPath, rowNesting) {
        return reduce(function (accumulator, item) {
            var index = accumulator.length;
            var thisRowNesting = append(index, rowNesting);
            var hasChild = item.submenu !== undefined;
            if (item.seperator) {
                accumulator.push(___EmotionJSX("hr", {
                    key: index,
                    css: hrCss
                }));
            }
            else {
                accumulator.push(___EmotionJSX("div", {
                    key: index,
                    onClick: function onClick(event) {
                        if (item.hotKey) {
                            invokeHotKeyCallback(item.hotKey);
                        }
                        else {
                            item.callback && !item.disabled && item.callback();
                            event.preventDefault();
                        }
                    },
                    css: hasChild && SS.nestedWrapper,
                    onMouseOver: function onMouseOver() {
                        setOpenPath(thisRowNesting);
                    }
                }, hasChild && equals(thisRowNesting, slice(0, thisRowNesting.length, openPath)) && ___EmotionJSX("ul", {
                    css: SS.dropdownListNested,
                    style: {
                        zIndex: thisRowNesting.length
                    },
                    onMouseOver: function onMouseOver(event) {
                        thisRowNesting.length > openPath.length && setOpenPath(append(0, slice(0, thisRowNesting.length, openPath)));
                        event.stopPropagation();
                    }
                }, reduceRow(item.submenu, openPath, thisRowNesting)), ___EmotionJSX("li", {
                    css: item.disabled ? SS.listItemDisabled : SS.listItem
                }, item.checked && ___EmotionJSX(SelectedIcon, {
                    css: SS.selectedIcon
                }), ___EmotionJSX("p", {
                    css: SS.paraLabel
                }, item.label), item.hotKey && keyBindings && keyBindings[item.hotKey] && ___EmotionJSX("i", {
                    css: SS.paraLabel
                }, humanizeKeySequence(propOr("", item.hotKey, keyBindings))), hasChild && ___EmotionJSX(NestedMenuIcon, {
                    css: SS.nestedMenuIcon
                }))));
            }
            return accumulator;
        }, [], items);
    };
    var columns = function columns(openPath) {
        return reduce(function (accumulator, item) {
            var index = accumulator.length;
            // const openPath = openPath;
            var anyColIsOpen = !isEmpty(openPath);
            var thisColIsOpen = !isEmpty(openPath) && equals(openPath[0], index);
            var row = ___EmotionJSX("ul", {
                style: {
                    display: thisColIsOpen ? "inline" : "none"
                },
                css: SS.dropdownList
            }, !isEmpty(openPath) && !isEmpty(item.submenu) && reduceRow(item.submenu, openPath, [index]));
            accumulator.push(___EmotionJSX("div", {
                css: SS.dropdownButtonWrapper,
                key: accumulator.length,
                onClick: function onClick() {
                    thisColIsOpen ? setOpenPath([]) : setOpenPath([index]);
                }
            }, ___EmotionJSX("div", {
                css: SS.dropdownButton,
                onMouseOver: function onMouseOver() {
                    return anyColIsOpen && setOpenPath([index]);
                }
            }, ___EmotionJSX("span", null, item.label)), row));
            return accumulator;
        }, [], menuBarItems);
    };
    return ___EmotionJSX(React.Fragment, null, ___EmotionJSX("ul", {
        css: SS.root
    }, columns(openPath)));
}
var clickOutsideConfig = {
    excludeScrollbar: true,
    handleClickOutside: function handleClickOutside() {
        return MenuBar.handleClickOutside;
    }
};
export default onClickOutside(MenuBar, clickOutsideConfig);
