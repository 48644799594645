var PREFIX = "LOGIN.";
export var SET_REQUESTING_STATUS = PREFIX + "SET_REQUESTING_STATUS";
export var SIGNIN_FAIL = PREFIX + "SIGNIN_FAIL";
export var SIGNIN_SUCCESS = PREFIX + "SIGNIN_SUCCESS";
export var SIGNIN_REQUEST = PREFIX + "SIGNIN_REQUEST";
export var UPDATE_USER_PROFILE = PREFIX + "UPDATE_USER_PROFILE";
export var OPEN_DIALOG = PREFIX + "OPEN_DIALOG";
export var CLOSE_DIALOG = PREFIX + "CLOSE_DIALOG";
export var LOG_OUT = PREFIX + "LOG_OUT";
export var CREATE_USER_FAIL = PREFIX + "CREATE_USER_FAIL";
export var CREATE_USER_SUCCESS = PREFIX + "CREATE_USER_SUCCESS";
export var CREATE_CLEAR_ERROR = PREFIX + "CREATE_USER_CLEAR_ERROR";
