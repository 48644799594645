import { useDispatch } from "@root/store";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import DisabledByDefaultRoundedIcon from "@mui/icons-material/DisabledByDefaultRounded";
import Tooltip from "@mui/material/Tooltip";
import { useTheme } from "@emotion/react";
import { windowHeader as windowHeaderStyle } from "@styles/_common";
import { setFileTreePanelOpen } from "@comp/project-editor/actions";
import { newFolder } from "@comp/projects/actions";
import * as SS from "./styles";
import { jsx as ___EmotionJSX } from "@emotion/react";
var FileTreeHeader = function FileTreeHeader(_ref) {
    var project = _ref.project, isOwner = _ref.isOwner;
    var theme = useTheme();
    var dispatch = useDispatch();
    return ___EmotionJSX("div", {
        id: "web-ide-file-tree-header",
        css: windowHeaderStyle,
        style: {
            zIndex: 2
        }
    }, ___EmotionJSX("p", null, project ? project.name : "", ___EmotionJSX("span", {
        css: SS.headIconsContainer
    }, isOwner && ___EmotionJSX(Tooltip, {
        title: "create new directory"
    }, ___EmotionJSX("span", {
        css: SS.newFolderIcon,
        onClick: function onClick() {
            return dispatch(newFolder(project.projectUid));
        }
    }, ___EmotionJSX(CreateNewFolderIcon, {
        style: {
            fill: theme.textColorAlt
        }
    }))), ___EmotionJSX(Tooltip, {
        title: "close window"
    }, ___EmotionJSX("span", {
        onClick: function onClick() {
            return dispatch(setFileTreePanelOpen(false));
        }
    }, ___EmotionJSX(DisabledByDefaultRoundedIcon, {
        style: {
            fill: theme.textColorAlt
        }
    }))))));
};
export default FileTreeHeader;
